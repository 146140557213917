<template>
    <section id="view-customers-add-update">
        <div class="container-view-content">
            <template v-if="isModeEdit && requestingCustomer">
                <ComponentLoading></ComponentLoading>
            </template>
            <template v-if="!isModeEdit || (isModeEdit && !requestingCustomer && customer)">
                <ComponentFormFill :companyId="companyId" :customer="customer" :mode="viewMode" :title="title" @customerCreated="componentFormFillCustomerCreated"></ComponentFormFill>
            </template>
        </div>
    </section>
</template>

<script>
    import MixinView from '@/mixins/MixinView.js'

    import ComponentFormFill from "@/components/FormFill/ComponentFormFill"
    import ComponentLoading from "@/components/ui/ComponentLoading"

    export default {
        name: 'CustomersAddUpdate',
        props: [],
        mixins: [MixinView],
        components: {
            ComponentFormFill,
            ComponentLoading
        },//components
        data() {
            return {

                customer: null,

                requestingCustomer: false,

                viewMode: null

            }
        },//data
        computed: {

            companyId() {
                return this.$store.getters['App/companyId']
            },

            title() {

                let ret = `Novo ${this.$store.getters['Companies/customerTreatmentSingular'].toLowerCase()}`

                if(this.viewMode === 'edit') {
                    ret = `Editar ${this.$store.getters['Companies/customerTreatmentSingular'].toLowerCase()}`
                }

                return ret

            },

            isModeEdit() {
                return this.viewMode === 'edit'
            }

        },//computed
        methods: {

            loadCustomer(customerId) {

                return new Promise(
                    (resolve, reject) => {

                        this.requestingCustomer = true

                        this.$store.dispatch('Companies/loadCustomerFields')
                            .then(
                                responseLoadCustomerFields => {

                                    this.company = responseLoadCustomerFields.responseCompany.data
                                    this.customerFields = responseLoadCustomerFields.responseCustomerFields.data

                                    this.$store.dispatch('Customers/loadCustomerById', {customerId})
                                        .then(
                                            responseLoadCustomerById => {

                                                const data = responseLoadCustomerById.data

                                                this.customer = data

                                                resolve(responseLoadCustomerById)

                                            }
                                        )
                                        .catch(
                                            error => {
                                                reject(error)
                                            }
                                        )
                                        .finally(
                                            () => {
                                                this.requestingCustomer = false
                                            }
                                        )

                                }
                            )
                            .catch(
                                error => {
                                    reject(error)
                                }
                            )

                    }
                )

            },

            componentFormFillCustomerCreated(customer) {
                this.$router.replace({ path: `/customers/details/${customer.id}` }, () => {}, () => {})
            }

        },//methods
        watch: {},//watch
        created() {},//created
        mounted() {

            const route = this.$route
            const path = route.path

            if(path.match(/edit\/.*/)) {

                this.viewMode = 'edit'

                const customerId = route.params.id

                this.loadCustomer(customerId)

            } else {
                this.viewMode = 'add'
            }

        },//mounted
        updated() {},//updated
        destroyed() {},//destroyed
        beforeRouteEnter(to, from, next) {
            next(
                vm => {
                }
            )
        },//beforeRouteEnter
        beforeRouteUpdate(to, from, next) {
            next()
        },//beforeRouteUpdate
        beforeRouteLeave(to, from, next) {
            next()
        }//beforeRouteLeave

    }
</script>

<style scoped>
    #view-customers-add-update
    {

    }

    .container-view-content
    {
        background-color: #fff;
        border-radius: 5px;
        margin: 0 auto 2em;
        padding: 1.5em;
    }

    @media (max-width: 767px) {

    }

    @media (min-width: 768px) and (max-width: 1199px) {

    }

    @media (max-width: 1199px) {

    }

    @media (min-width: 1200px) {

    }
</style>
