<template>
    <section id="view-note-templates-add-update">

        <ComponentViewTabs class="component-view-tabs" :tabs="this.viewTabs"></ComponentViewTabs>

        <template v-if="requesting">
            <ComponentLoading></ComponentLoading>
        </template>
        <template v-else>

            <div class="container-view-header">
                <div class="col-left">
                    <h1>{{ title }}</h1>
                </div>
            </div>

            <div class="container-view-content">
                <fieldset :disabled="requesting" ref="containerForm">
                    <div class="container-group-input container-group-input-name">
                        <label>Nome do modelo<span class="required-mark">*</span></label>
                        <ComponentInputText placeholder="nome" ref="name" type="text" v-model="model.name"></ComponentInputText>
                    </div>
                    <div class="container-group-input">
                        <label>Conteúdo do modelo<span class="required-mark">*</span></label>
                        <ComponentEditor :disabled="requesting" v-model="model.body"/>
                    </div>
                </fieldset>
                <hr>
                <div class="container-buttons">
                    <button class="btn-default btn-primary btn-save" :disabled="requesting" @click="buttonSaveClick">
                        <template v-if="requesting">
                            <img class="icon-circle-segment-animated" src="~@/assets/img/ico/ico_circle_segment_white.svg" alt="">
                            <span>Aguarde</span>
                        </template>
                        <span v-else>Salvar</span>
                    </button>
                    <router-link class="btn-default btn-terciary" :disabled="requesting" tag="button" :to="{ name: 'NoteTemplates' }">
                        <span>Cancelar</span>
                    </router-link>
                </div>
            </div>

        </template>
    </section>
</template>

<script>

    import MixinView from '@/mixins/MixinView.js'
    import MixinTemplates from '@/mixins/Templates/MixinTemplates'

    import ComponentEditor from "@/components/Editor/ComponentEditor"
    import ComponentInputText from "@/components/ui/ComponentInputText"
    import ComponentLoading from "@/components/ui/ComponentLoading"

    export default {
        name: 'NoteTemplatesAddUpdate',
        props: [],
        mixins: [MixinView, MixinTemplates],
        components: {
            ComponentEditor,
            ComponentInputText,
            ComponentLoading
        },//components
        data() {
            return {

                model: {
                    body: null,
                    name: null
                },

                requesting: false,

                noteTemplate: null,

                noteTemplateId: null,

                noteTemplates: null,

                viewMode: null

            }
        },//data
        computed: {

            title() {

                let ret = 'Novo modelo'

                if(this.viewMode === 'edit') {
                    ret = 'Editar modelo'
                }

                return ret

            }

        },//computed
        methods: {

            loadNoteTemplate(id) {

                this.noteTemplate = null

                this.requesting = true

                this.$fetch.get(`/api/v1/note_templates/${id}`)
                    .then(
                        response => {

                            const data = response.data

                            this.noteTemplate = data

                            this.model.name = data.name
                            this.model.body = data.body

                        }
                    )
                    .catch(
                        error => {

                            this.$eventbus.$emit(
                                'ComponentToast/add',
                                {
                                    autoHide: true,
                                    content: 'Erro ao carregar o modelo',
                                    icon: null,
                                    showCloseButton: false,
                                    type: 'error'
                                }
                            )

                            this.$router.replace( { name: 'NoteTemplates' }, () => {},  () => {} )

                        }
                    )
                    .finally(
                        () => {
                            this.requesting = false
                        }
                    )

            },

            loadNoteTemplates() {

                this.noteTemplates = null

                this.requesting = true

                this.$fetch.get(`/api/v1/note_templates`)
                    .then(
                        response => {

                            const data = response.data

                            this.noteTemplates = data

                        }
                    )
                    .catch(
                        error => {

                            this.$eventbus.$emit(
                                'ComponentToast/add',
                                {
                                    autoHide: true,
                                    content: 'Erro ao carregar os modelos',
                                    icon: null,
                                    showCloseButton: false,
                                    type: 'error'
                                }
                            )

                        }
                    )
                    .finally(
                        () => {
                            this.requesting = false
                        }
                    )

            },

            save() {

                let requestConfig = {}

                requestConfig.data = {
                    'note_template[name]': this.model.name,
                    'note_template[body]': this.model.body,
                }

                if(this.viewMode === 'add') {

                    requestConfig.method = 'post'
                    requestConfig.url = `api/v1/note_templates`

                } else {

                    const noteTemplateId = this.noteTemplateId

                    requestConfig.method = 'patch'
                    requestConfig.url = `api/v1/note_templates/${noteTemplateId}`

                }

                const arrNoteTemplatesNames = this.noteTemplates
                    .map( item => item.name.toLowerCase() )
                    .filter(
                        (item, index, array) => {

                            if(this.viewMode === 'edit') {

                                if(item !== this.noteTemplate.name.toLowerCase()) {
                                    return item
                                }

                            } else {
                                return item
                            }

                        }
                    )

                if(arrNoteTemplatesNames.indexOf(this.model.name.toLowerCase()) >= 0) {

                    this.$eventbus.$emit(
                        'ComponentToast/add',
                        {
                            autoHide: true,
                            content: 'Já existe um modelo com o nome informado, escolha outro nome',
                            icon: null,
                            showCloseButton: false,
                            type: 'error'
                        }
                    )

                    return false

                }

                if(this.model.name && this.model.body) {

                    this.axios
                        .request(requestConfig)
                        .then(
                            response => {

                                this.$eventbus.$emit(
                                    'ComponentToast/add',
                                    {
                                        autoHide: true,
                                        content: `Modelo criado/alterado com sucesso`,
                                        icon: null,
                                        showCloseButton: false,
                                        type: 'success'
                                    }
                                )

                                this.$router.replace({ name: 'NoteTemplates' }, () => {}, () => {})

                            }
                        )
                        .catch(
                            error => {

                                this.$eventbus.$emit(
                                    'ComponentToast/add',
                                    {
                                        autoHide: true,
                                        content: 'Erro ao criar/atualizar o modelo',
                                        icon: null,
                                        showCloseButton: false,
                                        type: 'error'
                                    }
                                )

                            }
                        )
                        .finally(
                            () => {
                                this.$emit('requestingDone')
                            }
                        )

                } else {

                    this.$eventbus.$emit(
                        'ComponentToast/add',
                        {
                            autoHide: true,
                            content: 'Preencha corretamente todos os campos para prosseguir (verifique os obrigatórios)',
                            icon: null,
                            showCloseButton: false,
                            type: 'error'
                        }
                    )

                }

            },

            //Events

            buttonSaveClick(e) {
                this.save()
            },

        },//methods
        watch: {},//watch
        created() {},//created
        mounted() {

            const route = this.$route
            const path = route.path

            this.loadNoteTemplates()

            if(path.match(/edit\/.*/)) {

                this.viewMode = 'edit'

                this.noteTemplateId = route.params.id

                this.loadNoteTemplate(this.noteTemplateId)

            } else {
                this.viewMode = 'add'
            }

        },//mounted
        updated() {},//updated
        destroyed() {},//destroyed
        beforeRouteEnter(to, from, next) {
            next(
                vm => {
                }
            )
        },//beforeRouteEnter
        beforeRouteUpdate(to, from, next) {
            next()
        },//beforeRouteUpdate
        beforeRouteLeave(to, from, next) {
            next()
        }//beforeRouteLeave

    }
</script>
<style scoped>
    #view-note-templates-add-update
    {

    }

    .component-view-tabs
    {
        margin-bottom: 1.5em;
    }

    .container-view-header
    {
        margin-bottom: 1em;
    }

    .container-view-content
    {
        background-color: #fff;
        border-radius: 5px;
        margin-bottom: 2em;
        padding: 1.5em;
    }

    .container-group-input-name
    {
        margin-bottom: 1em;
    }

    @media (max-width: 767px) {

    }

    @media (min-width: 768px) and (max-width: 1199px) {

    }

    @media (max-width: 1199px) {

        .container-group-input-name .component-input-text
        {
            width: 100%;
        }

    }

    @media (min-width: 1200px) {

        .container-group-input-name .component-input-text
        {
            width: 60%;
        }

    }
</style>
