<template>
    <span class="component-dropdown">
        <button type="button" ref="buttonDropdown" @click="buttonDropdownClick" v-if="selectedOption">
            <span class="label">
                <img :src="selectedOption.icon" alt="" v-if="selectedOption.icon">
                <span>{{ selectedOption.label }}</span>
            </span>
            <span class="icon-arrow-down">
                 <img src="~@/assets/img/ico/ico_arrow_down.svg" alt="">
            </span>
        </button>
        <div class="container-dropdown" ref="containerDropdown" :style="{ 'max-width': buttonDropdownWidth, 'min-width': buttonDropdownWidth, 'top': dropdown.style.top }" v-click-outside="containerDropdownClickOutside" v-if="dropdown.show">
            <button type="button" :key="index" v-for="(item, index) in items" @click="buttonItemClick($event, item)">
                <template v-if="typeof(item) === 'object'">
                    <img :src="item.icon" alt="" v-if="item.icon">
                    <span>{{ item.label }}</span>
                </template>
                <template v-else-if="typeof(item) === 'string'">
                    <span>{{ item }}</span>
                </template>
            </button>
        </div>
    </span>
</template>

<script>
    export default {
        name: 'ComponentDropdown',
        props: {
            items: {
              required: true,
              type: Array,
            },
            selectedIndex: {
              default: 0,
              required: false,
              type: Number,
            },
        },
        mixins: [],
        components: {},//components
        data() {
            return {
                dropdown: {
                    show: false,
                    style: {
                        top: 0
                    }
                },
                selectedOption: null
            }
        },//data
        computed: {

            buttonDropdownWidth() {

                const elButtonDropdown = this.$refs.buttonDropdown

                return elButtonDropdown.offsetWidth + 'px'

            }

        },//computed
        methods: {

            setSelectedOption(item) {

                if(typeof(item) === 'object') {

                    this.selectedOption = item

                } else {

                    this.selectedOption = {
                        icon: null,
                        label: item,
                        value: item
                    }

                }

            },

            setDropdownTop() {

                const elButtonDropdown = this.$refs.buttonDropdown

                this.dropdown.style.top = elButtonDropdown.getBoundingClientRect().height + 2 + 'px'

            },

            buttonDropdownClick(e) {
                this.dropdown.show = !this.dropdown.show
            },

            buttonItemClick(e, item) {

                this.setSelectedOption(item)

                this.dropdown.show = false

            },

            containerDropdownClickOutside() {

                if(this.dropdown.show) {
                    //TODO: Caio
                    // this.dropdown.show = false
                }

            }

        },//methods
        watch: {

            selectedIndex(value, oldValue) {
                this.setSelectedOption(this.items[value])
            },

            selectedOption(value, oldValue) {
                this.$emit('change', value)
            },

            'dropdown.show': function(value, oldValue) {
                if(value) {
                    this.setDropdownTop()
                }
            },

        },//watch
        created() {},//created
        mounted() {

            if(this.selectedIndex !== undefined && this.items) {
                this.setSelectedOption(this.items[this.selectedIndex])
            }

        },//mounted
        updated() {},//updated
        destroyed() {},//destroyed
    }
</script>

<style scoped>
    .component-dropdown
    {
        display: inline-block;
        position: relative;
    }

    .component-dropdown > button
    {
        display: inline-flex;
        align-items: center;

        background-color: #fff;
        border: 1px solid #d0d0d0;
        border-radius: 4px;
        margin: 0;
        padding: 0;
        width: 100%;
    }

    .component-dropdown > button:focus
    {
        box-shadow: 0 0 2px 2px rgba(41, 177, 204, 0.4);
    }

    .component-dropdown > button .label
    {
        flex: 1;
        display: inline-flex;
        align-items: center;

        overflow: hidden;
        padding: 0 0.7rem;
    }

    .component-dropdown > button .label img
    {
        margin-right: 0.35em;
        max-height: 1em;
        max-width: 1em;
    }

    .component-dropdown > button .label span
    {
        display: inline-block;
        font-size: 0.9em;
        font-weight: 300;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .component-dropdown > button .icon-arrow-down
    {
        display: inline-flex;
        align-items: center;
        justify-content: center;

        background-color: #d0d0d0;
        padding: 0.7rem 0.5em;
    }

    .component-dropdown > button .icon-arrow-down img
    {
        height: 0.4em;
    }

    .container-dropdown
    {
        background-color: #fff;
        border-radius: 4px;
        box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.15);
        box-sizing: border-box;
        max-height: 16em;
        overflow: auto;
        padding: 0;
        position: absolute;
        z-index: 1000;
    }

    .container-dropdown button
    {
        display: flex;
        align-items: center;

        background-color: transparent;
        border: none;
        margin: 0;
        padding: 0.5em;
        width: 100%;
    }

    .container-dropdown button:hover
    {
        background-color: #cdf1f4;
    }

    .container-dropdown button img
    {
        margin-right: 0.35em;
        max-height: 1.2em;
        max-width: 1.2em;
    }

    .container-dropdown button span
    {
        font-size: 0.9em;
        font-weight: 300;
        text-align: left;
    }

    @media (max-width: 767px) {

    }

    @media (min-width: 768px) {

    }

    @media (min-width: 992px) {

    }

    @media (max-width: 1199px) {

    }

    @media (min-width: 1200px) {

    }
</style>
