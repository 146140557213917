<template>
    <section id="view-forgot-password">
        <div class="container-fields">
            <div class="container-logo">
                <img src="~@/assets/img/logo_ordexa.svg" alt="">
            </div>
            <div class="container-text-forgot-password">Esqueci a senha</div>
            <p class="container-text-fill-the-field">Preencha o campo abaixo com seu e-mail de cadastro no Ordexa. Você receberá um e-mail para você continuar a recuperação da senha.</p>
            <div class="container-group-input">
                <label>E-mail</label>
                <ComponentInputText :icon="require('@/assets/img/ico/ico_envelope.svg')" placeholder="seu e-mail" type="text" ref="componentInputTextEmail" :validation="{ regex: /^[^@]+@[^\.]+\..+$/, required: true }" v-model="email"></ComponentInputText>
            </div>
            <div class="container-button">
                <button type="button" class="btn-default btn-primary" :disabled="requestingSend || !isValid" @click="btnSendClick">
                    <template v-if="requestingSend">
                        <img class="icon-circle-segment-animated" src="~@/assets/img/ico/ico_circle_segment_white.svg" alt="">
                        <span>Aguarde</span>
                    </template>
                    <span v-else>Recuperar</span>
                </button>
            </div>
            <div class="container-back-to-login">
                <router-link :to="{ name: 'Login' }">&larr; Voltar ao login</router-link>
            </div>
        </div>
    </section>
</template>

<script>
    import MixinView from '@/mixins/MixinView.js'

    import ComponentInputText from "@/components/ui/ComponentInputText"

    export default {
        name: 'ForgotPassword',
        props: [],
        mixins: [MixinView],
        components: {
            ComponentInputText
        },//components
        data() {
            return {

                email: '',

                isValid: false,

                requestingSend: false

            }
        },//data
        computed: {},//computed
        methods: {

            btnSendClick(e) {

            }

        },//methods
        watch: {

            email(value, oldValue) {
                this.isValid = this.$helpers.validateSingleField(this.email, this.$refs.componentInputTextEmail.validation)
            }

        },//watch
        created() {},//created
        mounted() {},//mounted
        updated() {},//updated
        destroyed() {},//destroyed
        beforeRouteEnter(to, from, next) {
            next(
                vm => {
                }
            )
        },//beforeRouteEnter
        beforeRouteUpdate(to, from, next) {
            next()
        },//beforeRouteUpdate
        beforeRouteLeave(to, from, next) {
            next()
        }//beforeRouteLeave

    }
</script>

<style scoped>
    #view-forgot-password
    {

    }

    .container-text-forgot-password
    {
        font-size: 1.05em;
        font-weight: 600;
    }

    .container-text-fill-the-field
    {
        font-size: 0.85em;
        margin-bottom: 2em;
    }

    .container-group-input
    {
        font-size: 0.9em;
        margin: 0 auto 0.8em;
        width: 100%;
    }

    .container-group-input .component-input-text
    {
        width: 100%;
    }

    .container-button
    {
        margin-bottom: 2em;
    }

    .container-button
    {
        margin-bottom: 1.5em;
    }

    .container-button button
    {
        box-shadow: 0 3px 12px 0 rgba(41, 177, 204, 0.5);
        font-size: 0.9em;
        padding-left: 3em;
        padding-right: 3em;
    }

    .container-back-to-login
    {
        font-size: 0.9em;
    }

    @media (max-width: 767px) {

    }

    @media (min-width: 768px) and (max-width: 1199px) {

    }

    @media (max-width: 1199px) {

        .container-logo
        {
            margin-bottom: 1.5em;
        }

        .container-text-forgot-password
        {
            margin-bottom: 1.5em;
        }

        .container-button
        {
            margin-top: 2em;
        }

        .container-button button
        {
            width: 100%;
        }

        .container-back-to-login
        {
            text-align: center;
        }
    }

    @media (min-width: 1200px) {

        #view-forgot-password
        {
            max-width: 350px;
        }

        .container-logo
        {
            margin-bottom: 2em;
        }

        .container-text-forgot-password
        {
            margin-bottom: 1.5em;
        }

        .container-back-to-login
        {
            margin-top: 3em;
        }

    }
</style>
