<template>
    <section id="view-subscription-suspended">
        <div>
            <h1>OPS! ASSINATURA PENDENTE</h1>
            <p>Identificamos que sua assinatura está suspensa.</p>
            <div class="container-image">
                <img src="~@/assets/img/plans.svg" alt="">
            </div>
            <p>Solicite a regularização da conta pelo email suporte@ordexa.com.br</p>
        </div>
    </section>
</template>
<script>
    import MixinView from '@/mixins/MixinView.js'

    export default {
        name: 'SubscriptionSuspended',
        computed: {

            planAndMembershipLinkUrl() {
                return process.env.VUE_APP_API_BASE_URL + '/billing/plan'
            },

            accountTypeParam() {
                return this.$route.query.a;
            },

            isSystemAdmin() {
                //MD5 - system_admin = 68ed1c7decda36085c86df62823cde17
                //MD5 - standard = c00f0c4675b91fb8b918e4079a0b1bac
                //MD5 - limited = d63c7ede8cb1e1c8db5e51c63fd47cff
                return this.accountTypeParam === '68ed1c7decda36085c86df62823cde17' || false;
            },

        },
    }
</script>
<style scoped>
    #view-subscription-suspended
    {
        align-self: center;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        padding: 1em;
    }

    #view-subscription-suspended > div
    {
        text-align: center;
    }

    h1
    {
        font-weight: 400;
        margin-bottom: 0;
    }

    p
    {
        margin: 1.5em auto;
        max-width: 450px;
    }

    .container-image
    {
        display: flex;
        align-items: center;
        justify-content: center;

        background-color: #A2C9D0;
        border-radius: 200px;
        margin: 1.5em auto;
        height: 180px;
        width: 180px;
    }

    .container-image img
    {
        width: 150px;
    }

    .btn-default {
        text-decoration: none;
    }
</style>
