//State
const state = {}

//Getters
const getters = {}

//Actions
const actions = {

    loadCustomerById(context, obj) {

        const id = obj.customerId

        return new Promise(
            (resolve, reject) => {

                this._vm.$fetch.get(`/api/v1/customers/${id}`)
                    .then(
                        response => {

                            const data = response.data

                            resolve(response)

                        }
                    )
                    .catch(
                        error => {

                            reject(error)

                            this._vm.$eventbus.$emit(
                                'ComponentToast/add',
                                {
                                    autoHide: true,
                                    content: `Erro ao carregar o ${this._vm.$store.getters['Companies/customerTreatmentSingular'].toLowerCase()}`,
                                    icon: null,
                                    showCloseButton: false,
                                    type: 'error'
                                }
                            )

                        }
                    )

            }

        )

    },

}

//Mutations
const mutations = {}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
