<template>
    <section id="view-not-found">
        <img src="~@/assets/img/not_found.svg" alt="">
        <h1>{{text}}</h1>
    </section>
</template>

<script>
    import MixinView from '@/mixins/MixinView.js'

    export default {
        name: 'NotFound',
        props: [],
        mixins: [MixinView],
        components: {},//components
        data() {
            return {}
        },//data
        computed: {

          text() {

            const route = this.$route

            let ret = ''

            if(route.name === 'AccessDenied') {
              ret = 'Acesso negado'
            } else {
              ret = 'Página não encontrada'
            }

            return ret

          }

        },//computed
        methods: {},//methods
        watch: {},//watch
        created() {},//created
        mounted() {},//mounted
        updated() {},//updated
        destroyed() {},//destroyed
        beforeRouteEnter(to, from, next) {
            next(
                vm => {
                }
            )
        },//beforeRouteEnter
        beforeRouteUpdate(to, from, next) {
            next()
        },//beforeRouteUpdate
        beforeRouteLeave(to, from, next) {
            next()
        }//beforeRouteLeave

    }
</script>

<style scoped>
    #view-not-found
    {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
    }

    img
    {
        height: 300px;
    }

    h1
    {
        font-size: 1.75em;
        font-weight: 100;
        letter-spacing: -0.025em;
        opacity: 0.5;
    }

    @media (max-width: 767px) {

    }

    @media (min-width: 768px) and (max-width: 1199px) {

    }

    @media (max-width: 1199px) {

    }

    @media (min-width: 1200px) {

    }
</style>
