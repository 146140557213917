<template>
    <button type="button" class="component-checkbox" @click="click">
        <span :class="['checkbox', { checked: checked_ }]">
            <img src="~@/assets/img/ico/ico_checkbox-white.svg" alt="">
        </span>
        <span class="label">{{ label }}</span>
    </button>
</template>

<script>
    export default {
        name: 'ComponentCheckbox',
        props: [
            'checked',
            'label'
        ],
        mixins: [],
        components: {},//components
        data() {
            return {
                checked_: null
            }
        },//data
        computed: {},//computed
        methods: {

            click(e) {

                this.checked_ = !this.checked_

                this.$emit('input', this.checked_)

            }

        },//methods
        watch: {

            checked(value, oldValue) {
                this.checked_ = (value === 'true' || value === true) ? true : false
            }

        },//watch
        created() {},//created
        mounted() {

            if(this.checked === 'true' || this.checked === true) {
                this.checked_ = true
            }

        },//mounted
        updated() {},//updated
        destroyed() {},//destroyed
    }
</script>

<style scoped>
    .component-checkbox
    {
        background-color: transparent;
        border: none;
        margin: 0;
        padding: 0;
    }

    .checkbox
    {
        display: inline-flex;
        align-items: center;
        justify-content: center;

        background-color: #fff;
        border: 1px solid #d0d0d0;
        border-radius: 3px;
        height: 14px;
        margin-right: 0.35em;
        vertical-align: middle;
        width: 14px;
    }

    .component-checkbox:focus .checkbox
    {
        box-shadow: 0 0 2px 2px rgba(41, 177, 204, 0.4);
    }

    .checkbox.checked
    {
        background-color: #29B1CC;
        border-color: #29B1CC;
    }

    .label
    {
        font-weight: 300;
        vertical-align: middle;
    }

    @media (max-width: 767px) {

    }

    @media (min-width: 768px) {

    }

    @media (min-width: 992px) {

    }

    @media (max-width: 1199px) {

    }

    @media (min-width: 1200px) {

    }
</style>
