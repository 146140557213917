import Vue from 'vue'
import VueRouter from 'vue-router'

import LayoutDefault from '@/layouts/LayoutDefault'
import LayoutLogin from '@/layouts/LayoutLogin'
import LayoutOnboarding from '@/layouts/LayoutOnboarding'

import BusinessForm from '@/views/Business/BusinessForm'
import BusinessLog from '@/views/Business/BusinessLog'
import BusinessMain from '@/views/Business/BusinessMain'
import BusinessUsers from '@/views/Business/BusinessUsers/BusinessUsers'
import BusinessUsersAddUpdate from '@/views/Business/BusinessUsers/BusinessUsersAddUpdate'

import CustomersAddUpdate from '@/views/Customers/CustomersAddUpdate'
import CustomersDetails from '@/views/Customers/CustomersDetails'
import Customers from '@/views/Customers/Customers'

import ForgotPassword from '@/views/ForgotPassword'

import Login from '@/views/Login'

import Logout from '@/views/Logout'

import NoteTemplatesAddUpdate from '@/views/NoteTemplates/NoteTemplatesAddUpdate'
import NoteTemplates from '@/views/NoteTemplates/NoteTemplates'

import NotFound from '@/views/NotFound'

import OnboardingWelcome from '@/views/Onboarding/OnboardingWelcome'
import OnboardingPersonalProfile from '@/views/Onboarding/OnboardingPersonalProfile'
import OnboardingPasswordChange from '@/views/Onboarding/OnboardingPasswordChange'
import OnboardingYourBusiness from '@/views/Onboarding/OnboardingYourBusiness'
import OnboardingReady from '@/views/Onboarding/OnboardingReady'

import PasswordChange from '@/views/PersonalProfile/PasswordChange'
import PersonalProfile from '@/views/PersonalProfile/PersonalProfile'

import PrintLayout from '@/views/PrintLayout'

import Signup from '@/views/Signup'

import SubscriptionSuspended from '@/views/SubscriptionSuspended'

import Helpers from '@/helpers'

Vue.use(VueRouter)

const helpers = Helpers

const moment = require('moment')

const routes = [{
        name: 'Logout',
        path: '/logout',
        component: Logout
    },
    {
        name: 'Signup',
        path: '/signup',
        component: Signup
    },
    {
        name: 'SubscriptionSuspended',
        path: '/subscription-suspended',
        component: SubscriptionSuspended
    },
    {
        path: '/',
        component: LayoutLogin,
        children: [
            { path: '/', redirect: '/login', meta: { requiresAuth: true } },
            { path: '/login', name: 'Login', component: Login, meta: { requiresAuth: false } },
            { path: '/forgot-password', name: 'ForgotPassword', component: ForgotPassword, meta: { requiresAuth: false } },
        ]
    },
    {
        path: '/',
        component: LayoutOnboarding,
        children: [
            { path: '/onboarding', redirect: '/onboarding/welcome', meta: { requiresAuth: true } },
            { path: '/onboarding/welcome', name: 'OnboardingWelcome', component: OnboardingWelcome, meta: { requiresAuth: true } },
            { path: '/onboarding/personal-profile', name: 'OnboardingPersonalProfile', component: OnboardingPersonalProfile, meta: { requiresAuth: true } },
            { path: '/onboarding/password-change', name: 'OnboardingPasswordChange', component: OnboardingPasswordChange, meta: { requiresAuth: true } },
            { path: '/onboarding/your-business', name: 'OnboardingYourBusiness', component: OnboardingYourBusiness, meta: { requiresAuth: true } },
            { path: '/onboarding/ready', name: 'OnboardingReady', component: OnboardingReady, meta: { requiresAuth: true } },
        ]
    },
    {
        path: '/',
        component: LayoutDefault,
        children: [

            { path: '/', redirect: '/customers/list', meta: { requiresAuth: true } },

            { path: '/business', redirect: '/business/main', meta: { requiresAuth: true, rules: { subject: 'Company' } } },
            { path: '/business/form', name: 'BusinessForm', component: BusinessForm, meta: { requiresAuth: true, rules: { subject: 'CustomerField' } } },
            { path: '/business/log', name: 'BusinessLog', component: BusinessLog, meta: { requiresAuth: true, rules: { subject: 'Company' } } },
            { path: '/business/main', name: 'BusinessMain', component: BusinessMain, meta: { requiresAuth: true, rules: { subject: 'Company' } } },
            { path: '/business/users', name: 'BusinessUsers', component: BusinessUsers, meta: { requiresAuth: true, rules: { subject: 'User' } } },
            { path: '/business/users/add', name: 'BusinessUsersAdd', component: BusinessUsersAddUpdate, meta: { requiresAuth: true, rules: { subject: 'User' } } },
            { path: '/business/users/edit/:id', name: 'BusinessUsersEdit', component: BusinessUsersAddUpdate, meta: { requiresAuth: true, rules: { requiredActions: ['edit', 'manage'], subject: 'User' } } },

            { path: '/customers', name: 'Customers', component: Customers, meta: { requiresAuth: true, rules: { subject: 'Customer' } } },
            { path: '/customers/add', name: 'CustomersAdd', component: CustomersAddUpdate, meta: { requiresAuth: true, rules: { subject: 'Customer' } } },
            { path: '/customers/edit/:id', name: 'CustomersEdit', component: CustomersAddUpdate, meta: { requiresAuth: true, rules: { subject: 'Customer' } } },
            { path: '/customers/details/:id', name: 'CustomersDetails', component: CustomersDetails, meta: { requiresAuth: true, rules: { subject: 'Customer' } } },

            { path: '/note-templates', name: 'NoteTemplates', component: NoteTemplates, meta: { requiresAuth: true, rules: { subject: 'NoteTemplate' } } },
            { path: '/note-templates/add', name: 'NoteTemplatesAdd', component: NoteTemplatesAddUpdate, meta: { requiresAuth: true, rules: { subject: 'NoteTemplate' } } },
            { path: '/note-templates/edit/:id', name: 'NoteTemplatesEdit', component: NoteTemplatesAddUpdate, meta: { requiresAuth: true, rules: { subject: 'NoteTemplate' } } },

            { path: '/personal-profile', redirect: '/personal-profile/personal-data', meta: { requiresAuth: true } },
            { path: '/personal-profile/personal-data', name: 'PersonalProfile', component: PersonalProfile, meta: { requiresAuth: true } },
            { path: '/personal-profile/password', name: 'PasswordChange', component: PasswordChange, meta: { requiresAuth: true } },

            { path: '/print-layout', name: 'PrintLayout', component: PrintLayout, meta: { requiresAuth: true } },

            { path: '/access-denied', name: 'AccessDenied', component: NotFound, meta: { requiresAuth: true } },

            { path: '/not-found', name: 'NotFound', component: NotFound, meta: { requiresAuth: true } },

            { path: '/*', name: 'NotFound', component: NotFound, meta: { requiresAuth: true } },

        ]
    },
]

const router = new VueRouter({
    mode: 'history',
    routes,
})

router.beforeEach(
    (to, from, next) => {

        if (to.redirectedFrom && to.redirectedFrom.substring(0, 2) === '/#') {
            const path = to.redirectedFrom.substring(2);
            next({ path, replace: true });
        }

        if (to.matched.some(record => record.meta.requiresAuth)) {

            const accessToken = localStorage.getItem('ordexa/headers/access-token')
            const client = localStorage.getItem('ordexa/headers/client')
            const contentType = localStorage.getItem('ordexa/headers/content-type')
            const expiry = localStorage.getItem('ordexa/headers/expiry')
            const tokenType = localStorage.getItem('ordexa/headers/token-type')
            const uid = localStorage.getItem('ordexa/headers/uid')

            const sessionAuthTime = sessionStorage.getItem('ordexa/session/auth-time')
            const sessionExpireAt = sessionStorage.getItem('ordexa/session/expire-at')

            helpers.checkFrontendSessionExpiry({ route: to, router, next })

            if (accessToken && client && contentType && expiry && tokenType && uid) {
                next()
            } else {

                next({
                    path: '/login'
                })

            }

        } else {
            next()
        }

    }
)

export default router