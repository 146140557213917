<template>
    <div class="component-personal-profile-password-change">
        <div class="subtitle-change-your-password">Defina sua senha</div>
        <div class="container-group-input">
            <label ref="labelPassword">Senha<span class="required-mark">*</span></label>
            <ComponentInputText placeholder="6 ou mais caracteres (letras e números)" :validation="{ regex: /[A-Za-z0-9]{6,}/ }" type="password" v-model="model.password"></ComponentInputText>
        </div>
        <ComponentInfoIcon :target="labelPassword" text="Preencha com pelo menos 6 caracteres (letras e números)" />
        <div class="container-group-input">
            <label ref="labelPasswordConfirmation">Confirme a senha<span class="required-mark">*</span></label>
            <ComponentInputText placeholder="6 ou mais caracteres (letras e números)" :validation="{ regex: /[A-Za-z0-9]{6,}/ }" type="password" v-model="model.passwordConfirmation"></ComponentInputText>
        </div>
        <ComponentInfoIcon :target="labelPasswordConfirmation" text="Preencha com pelo menos 6 caracteres (letras e números)" />
    </div>
</template>

<script>

    import ComponentInfoIcon from "@/components/ui/ComponentInfoIcon"
    import ComponentInputText from "@/components/ui/ComponentInputText"

    export default {
        name: 'ComponentPersonalProfilePasswordChange',
        props: [
            'user'
        ],
        mixins: [],
        components: {
            ComponentInfoIcon,
            ComponentInputText
        },//components
        data() {
            return {

                isMounted: false,

                model: {
                    password: null,
                    passwordConfirmation: null,
                },

            }
        },//data
        computed: {

            labelPassword() {
                if(this.isMounted) {
                    return this.$refs.labelPassword
                }
            },

            labelPasswordConfirmation() {
                if(this.isMounted) {
                    return this.$refs.labelPasswordConfirmation
                }
            },

        },//computed
        methods: {

            updatePassword() {

                const companyId = this.user.company_id
                const userId = this.user.id

                let data = {
                    'user[name]': this.user.name,
                    'user[phone]': this.user.phone,
                    'user[password]': this.model.password,
                    'user[password_confirmation]': this.model.passwordConfirmation,
                }

                this.$emit('requestingUpdate')

                this.axios
                    .patch(`api/v1/companies/${companyId}/users/${userId}`, data)
                    .then(
                        response => {

                            const user = response.data

                            this.$eventbus.$emit(
                                'ComponentToast/add',
                                {
                                    autoHide: true,
                                    content: `Senha alterada com sucesso`,
                                    icon: null,
                                    showCloseButton: false,
                                    type: 'success'
                                }
                            )

                            this.model = {
                                password: null,
                                passwordConfirmation: null,
                            }

                            this.$emit('updated')

                        }
                    )
                    .catch(
                        error => {

                            this.$eventbus.$emit(
                                'ComponentToast/add',
                                {
                                    autoHide: true,
                                    content: 'Erro ao alterar a senha',
                                    icon: null,
                                    showCloseButton: false,
                                    type: 'error'
                                }
                            )

                        }
                    )
                    .finally(
                        () => {
                            this.$emit('requestingDone')
                        }
                    )

            },

        },//methods
        watch: {},//watch
        created() {},//created
        mounted() {

            this.isMounted = true

        },//mounted
        updated() {},//updated
        destroyed() {},//destroyed
    }
</script>

<style scoped>
    .component-personal-profile-password-change
    {
        text-align: center;
    }

    .subtitle-change-your-password
    {
        font-weight: bold;
        margin-bottom: 1em;
        text-align: left;
    }

    .container-group-input
    {
        font-size: 0.9em;
        margin: 0 auto 0.5em;
    }

    .container-group-input .component-input-text
    {
        width: 100%;
    }

    @media (max-width: 767px) {

    }

    @media (min-width: 768px) {

    }

    @media (min-width: 992px) {

    }

    @media (max-width: 1199px) {

        .container-group-input
        {
            width: 100%;
        }

    }

    @media (min-width: 1200px) {

        .container-group-input
        {
            width: 100%;
        }

    }
</style>
