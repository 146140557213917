<template>
    <section id="layout-onboarding">
        <div class="container-logo">
            <img src="~@/assets/img/logo_ordexa.svg" alt="">
        </div>
        <div class="container-router-view">
            <transition name="fade" mode="out-in">
                <router-view></router-view>
            </transition>
        </div>
        <div class="container-marker-buttons">
            <span :class="{ active: $route.fullPath === '/onboarding/welcome' }"></span>
            <span :class="{ active: $route.fullPath === '/onboarding/your-business' }"></span>
            <span :class="{ active: $route.fullPath === '/onboarding/personal-profile' }"></span>
            <span :class="{ active: $route.fullPath === '/onboarding/password-change' }"></span>
            <span :class="{ active: $route.fullPath === '/onboarding/ready' }"></span>
        </div>
    </section>
</template>

<script>
    import MixinLayout from '@/mixins/MixinLayout.js'

    export default {
        name: 'LayoutOnboarding',
        props: [],
        mixins: [MixinLayout],
        components: {},//components
        data() {
            return {}
        },//data
        computed: {},//computed
        methods: {

          //Events

        },//methods
        watch: {},//watch
        created() {},//created
        mounted() {},//mounted
        updated() {},//updated
        destroyed() {},//destroyed
        beforeRouteEnter(to, from, next) {
            next(
                vm => {
                }
            )
        },//beforeRouteEnter
        beforeRouteUpdate(to, from, next) {
            next()
        },//beforeRouteUpdate
        beforeRouteLeave(to, from, next) {
            next()
        }//beforeRouteLeave

    }
</script>

<style scoped>
    #layout-onboarding
    {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .container-marker-buttons
    {
        padding: 1.5em 0;
        text-align: center;
    }

    .container-marker-buttons span
    {
        background-color: #29B1CC;
        border: none;
        border-radius: 16px;
        display: inline-block;
        height: 16px;
        margin: 0 0.5em;
        opacity: 0.25;
        padding: 0;
        width: 16px;
    }

    .container-marker-buttons span.active
    {
        opacity: 1;
    }

    @media (max-width: 767px) {

    }

    @media (min-width: 768px) and (max-width: 1199px) {

    }

    @media (max-width: 1199px) {

        #layout-onboarding
        {
            padding: 1.5em;
        }

        .container-logo img
        {
            height: 1.2rem;
        }

    }

    @media (min-width: 1200px) {

        #layout-onboarding
        {
            padding: 2.2em;
        }

        .container-logo img
        {
            height: 1.75rem;
        }

    }
</style>
