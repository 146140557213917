<template>
    <div :class="['component-loading', { inline: inlineMode }]">
        <img class="icon-circle-segment-animated" src="~@/assets/img/ico/ico_circle_segment_black.svg" alt="">
        <span>{{ label_ }}</span>
    </div>
</template>

<script>
    import ComponentCheckbox from "@/components/ui/ComponentCheckbox"

    export default {
        name: 'ComponentLoading',
        props: [
            'label',
            'mode', //inline or block
        ],
        mixins: [],
        components: {
            ComponentCheckbox
        },//components
        data() {
            return {}
        },//data
        computed: {

            label_() {

                let ret = 'Carregando'

                if(this.label) {
                    ret = this.label
                }

                return ret

            },

            inlineMode() {
                return this.mode === 'inline'
            }

        },//computed
        methods: {},//methods
        watch: {},//watch
        created() {},//created
        mounted() {},//mounted
        updated() {},//updated
        destroyed() {},//destroyed
    }
</script>

<style scoped>
    .component-loading
    {
        display: flex;
        align-items: center;
        justify-content: center;

        font-size: 1.5em;
        line-height: 110%;
        opacity: 0.5;
        padding: 2em 1em;
    }

    .component-loading.inline
    {
        display: inline-flex;
    }

    img
    {
        height: 1.25em;
        margin-right: 0.5em;
    }

    @media (max-width: 767px) {

    }

    @media (min-width: 768px) {

    }

    @media (min-width: 992px) {

    }

    @media (max-width: 1199px) {

    }

    @media (min-width: 1200px) {

    }
</style>
