<template>
    <div class="component-business-profile">
<!--<div class="container-business-logo">-->
<!--    <transition name="fade">-->
<!--        <div class="container-message-image-rotation" v-if="hasSelectedImageForUpload">-->
<!--            Caso a imagem esteja rotacionada, ela será corrigida após você salvar.-->
<!--        </div>-->
<!--    </transition>-->
<!--    <ComponentImageUpload :icon="require('@/assets/img/ico/ico_picture.svg')" label="+ Adicionar logo" :src="pictureFileUrl" @change="componentImageUploadChange"></ComponentImageUpload>-->
<!--    <button type="button" class="button-delete" @click="buttonDeleteLogo" v-if="model.pictureFile || pictureFileUrl">-->
<!--        <img src="~@/assets/img/ico/ico_circle_delete_color.svg" alt="">-->
<!--    </button>-->
<!--</div>-->
        <div class="container-group-input">
            <label>Como você identifica sua empresa ou negócio?<span class="required-mark">*</span></label>
            <ComponentInputText
                :datalistOptions="companyTreatmentDatalistOptions"
                placeholder="clínica, consultório, empresa etc."
                type="datalist"
                :validation="{ required: true }"
                v-model="model.companyTreatment" />
        </div>
        <div class="container-group-input">
            <label>Como você identifica o público atendido pela sua empresa ou negócio?<span class="required-mark">*</span></label>
            <ComponentInputText
                :datalistOptions="customerTreatmentDatalistOptions"
                placeholder="cliente, paciente, aluno etc."
                type="datalist"
                :validation="{ required: true }"
                v-model="model.customerTreatment" />
        </div>
        <ComponentInfoBox content="<div>Na área de saúde, por exemplo, o público é identificado como “paciente”, na área comercial como “cliente”, na área de educação como “aluno” ou “professor”.</div><div>Como é a denominação para o público que sua empresa atende?</div>" v-if="$mq.match(/lg/)"></ComponentInfoBox>
    </div>
</template>

<script>
    import ComponentImageUpload from "@/components/ui/ComponentImageUpload"
    import ComponentInfoBox from "@/components/ui/ComponentInfoBox"
    import ComponentInputText from "@/components/ui/ComponentInputText"

    export default {
        name: 'ComponentBusinessProfile',
        props: [
            'company',
            'companyTreatment',
            'customerTreatment',
            'pictureFileUrl'
        ],
        mixins: [],
        components: {
            ComponentImageUpload,
            ComponentInfoBox,
            ComponentInputText
        },//components
        data() {
            return {

                companyTreatmentDatalistOptions: [
                    { label: 'Academia', value: 'Academia' },
                    { label: 'Chácara', value: 'Chácara' },
                    { label: 'Clínica', value: 'Clínica' },
                    { label: 'Consultório', value: 'Consultório' },
                    { label: 'Empresa', value: 'Empresa' },
                    { label: 'Escritório', value: 'Escritório' },
                    { label: 'Instituto', value: 'Instituto' },
                ],

                customerTreatmentDatalistOptions: [
                    { label: 'Aluno', value: 'Aluno' },
                    { label: 'Cliente', value: 'Cliente' },
                    { label: 'Coachee', value: 'Coachee' },
                    { label: 'Consumidor', value: 'Consumidor' },
                    { label: 'Lead', value: 'Lead' },
                    { label: 'Oportunidade', value: 'Oportunidade' },
                    { label: 'Paciente', value: 'Paciente' },
                ],

                hasSelectedImageForUpload: false,

                model: {
                    pictureFile: null,
                    companyTreatment: null,
                    customerTreatment: null
                }

            }
        },//data
        computed: {},//computed
        methods: {

            updateCompany(mode) {

                const companyId = this.company.id

                let data = {
                    'company[company_treatment]': this.model.companyTreatment,
                    'company[customer_treatment]': this.model.customerTreatment
                }

                if(this.model.pictureFile) {
                    data['company[attachment_attributes][attachment]'] = this.model.pictureFile
                }

                if(this.company.image_id) {
                    data['company[attachment_attributes][id]'] = this.company.image_id
                }

                if(mode === 'deleteImage') {
                    data['company[attachment_attributes][_destroy]'] = true
                }

                this.$emit('requestingUpdate')

                this.axios
                    .patch(`api/v1/companies/${companyId}`, data)
                    .then(
                        response => {

                            this.$eventbus.$emit(
                                'ComponentToast/add',
                                {
                                    autoHide: true,
                                    content: `${this.$store.getters['Companies/companyTreatmentSingular']} alterada com sucesso`,
                                    icon: null,
                                    showCloseButton: false,
                                    type: 'success'
                                }
                            )

                            this.$emit('updated')

                        }
                    )
                    .catch(
                        error => {

                            this.$eventbus.$emit(
                                'ComponentToast/add',
                                {
                                    autoHide: true,
                                    content: `Erro ao atualizar a ${this.$store.getters['Companies/companyTreatmentSingular'].toLowerCase()}`,
                                    icon: null,
                                    showCloseButton: false,
                                    type: 'error'
                                }
                            )

                        }
                    )
                    .finally(
                        () => {
                            this.$emit('requestingDone')
                        }
                    )

            },

            //Events

            buttonDeleteLogo(e) {
                this.updateCompany('deleteImage')
            },

            componentImageUploadChange(obj) {

                this.model.pictureFile = obj.fileBase64

                this.hasSelectedImageForUpload = true

            }

        },//methods
        watch: {},//watch
        created() {},//created
        mounted() {

            if(this.companyTreatment) {
                this.model.companyTreatment = this.companyTreatment
            }

            if(this.customerTreatment) {
                this.model.customerTreatment = this.customerTreatment
            }

        },//mounted
        updated() {},//updated
        destroyed() {},//destroyed
    }
</script>

<style scoped>
    .component-business-profile
    {
        text-align: center;
    }

    .container-business-logo
    {
        padding: 0.5em 0;
    }

    .container-business-logo .button-delete
    {
        background-color: transparent;
        border: none;
        margin: 0;
        margin-left: 0.25em;
        padding: 0;
        vertical-align: top;
    }

    .container-group-input
    {
        font-size: 0.9em;
        margin: 0 auto 0.8em;
        max-width: 350px;
    }

    .container-group-input .component-input-text
    {
        width: 100%;
    }

    .component-info-box
    {
        margin: 0 auto 0.8em;
        max-width: 350px;
    }

    .component-info-box >>> .container-content div
    {
        margin-bottom: 0.75em;
    }

    .container-message-image-rotation
    {
        background-color: #f5f5f5;
        border-radius: 4px;
        font-size: 0.8rem;
        margin-bottom: 1em;
        padding: 1em;
    }

    @media (max-width: 767px) {

    }

    @media (min-width: 768px) {

    }

    @media (min-width: 992px) {

    }

    @media (max-width: 1199px) {

    }

    @media (min-width: 1200px) {

    }
</style>
