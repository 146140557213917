<template>
    <section id="view-onboarding-welcome">
        <h1>BEM-VINDO!</h1>
        <p>Vamos te guiar agora nesse começo para criar o seu perfil pessoal e configurar alguns dados da sua empresa ou negócio.</p>
        <div class="container-image">
            <img src="~@/assets/img/computer.svg" alt="">
        </div>
        <p>Esta parte aqui você configura uma única vez. Ao concluir estes passos, a partir do próximo acesso você já entrará diretamente no sistema.</p>
        <div class="container-buttons">
            <router-link class="btn-default btn-primary" tag="button" :to="{ name: 'OnboardingYourBusiness' }">COMEÇAR</router-link>
        </div>
    </section>
</template>

<script>
    import MixinView from '@/mixins/MixinView.js'

    export default {
        name: 'OnboardingWelcome',
        props: [],
        mixins: [MixinView],
        components: {},//components
        data() {
            return {}
        },//data
        computed: {},//computed
        methods: {},//methods
        watch: {},//watch
        created() {},//created
        mounted() {},//mounted
        updated() {},//updated
        destroyed() {},//destroyed
        beforeRouteEnter(to, from, next) {
            next(
                vm => {
                }
            )
        },//beforeRouteEnter
        beforeRouteUpdate(to, from, next) {
            next()
        },//beforeRouteUpdate
        beforeRouteLeave(to, from, next) {
            next()
        }//beforeRouteLeave

    }
</script>

<style scoped>
    #view-onboarding-welcome
    {
        text-align: center;
    }

    h1
    {
        font-weight: 400;
        margin-bottom: 0;
    }

    p
    {
        margin: 1.5em auto;
        max-width: 560px;
    }

    .container-image
    {
        margin-bottom: 1.5em;
        margin-top: 1.5em;
    }

    .container-image img
    {

    }

    .container-buttons button
    {
        font-size: 0.9em;
    }

    @media (max-width: 767px) {

    }

    @media (min-width: 768px) and (max-width: 1199px) {

    }

    @media (max-width: 1199px) {

        h1
        {
            font-size: 1.5em;
        }

        p
        {
            font-size: 0.82em;
        }

        .container-image img
        {
            height: 10rem;
        }

    }

    @media (min-width: 1200px) {

        h1
        {
            font-size: 2em;
        }

        p
        {

        }

        .container-image img
        {
            max-height: 180px;
        }

    }
</style>
