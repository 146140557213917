<template>
    <section id="view-print-layout">

        <ComponentViewTabs class="component-view-tabs" :tabs="viewTabs"></ComponentViewTabs>

        <template v-if="requesting">
            <ComponentLoading></ComponentLoading>
        </template>
        <template v-else>

            <div class="container-view-header">
                <div class="col-left">
                    <h1>Layout de impressão</h1>
                </div>
                <div class="col-right">
                    <button type="button" class="button-print-preview" :disabled="isEditing" title="Visualizar impressão" @click="buttonPrintPreviewClick">
                        <img src="~@/assets/img/ico/ico_print.svg" alt="">
                        <span v-if="$mq.match(/md|lg/)">Visualizar impressão</span>
                    </button>
                    <button type="button" class="btn-default btn-primary" :disabled="isEditing" @click="buttonEditClick">
                        <img src="~@/assets/img/ico/ico_edit_white.svg" alt="" v-if="$mq.match(/xs|sm/)">
                        <span v-else>Editar</span>
                    </button>
                </div>
            </div>

            <div class="container-view-content">

                <template v-if="isEditing">

                    <div class="container-header">
                        <h2>Cabeçalho padrão</h2>
                        <ComponentEditor v-model="header.model"/>
                    </div>

                    <div class="container-footer">
                        <h2>Rodapé padrão</h2>
                        <ComponentEditor v-model="footer.model"/>
                    </div>

                    <div class="container-buttons-bottom">
                        <button class="btn-default btn-primary btn-save" :disabled="requesting" @click="buttonSaveClick">
                            <template v-if="requesting">
                                <img class="icon-circle-segment-animated" src="~@/assets/img/ico/ico_circle_segment_white.svg" alt="">
                                <span>Aguarde</span>
                            </template>
                            <span v-else>Salvar</span>
                        </button>
                        <button type="button" class="btn-default btn-terciary" @click="buttonCancelClick">Cancelar</button>
                    </div>

                </template>
                <template v-else>

                    <div class="container-header">
                        <h2>Cabeçalho padrão</h2>
                        <div class="container-header-view" v-html="header.model"></div>
                    </div>

                    <div class="container-footer">
                        <h2>Rodapé padrão</h2>
                        <div class="container-footer-view" v-html="footer.model"></div>
                    </div>

                </template>

            </div>

        </template>

    </section>
</template>

<script>

    import MixinView from '@/mixins/MixinView.js'
    import MixinTemplates from '@/mixins/Templates/MixinTemplates'

    import ComponentEditor from "@/components/Editor/ComponentEditor"
    import ComponentLoading from "@/components/ui/ComponentLoading"

    export default {
        name: 'PrintLayout',
        props: [],
        mixins: [MixinView, MixinTemplates],
        components: {
            ComponentEditor,
            ComponentLoading
        },//components
        data() {
            return {

                company: null,

                footer: {
                    model: null,
                    original: null,
                    pictureFileUrl: null
                },

                header: {
                    model: null,
                    original: null,
                    pictureFileUrl: null
                },

                isEditing: false,

                requesting: false

            }
        },//data
        computed: {},//computed
        methods: {

            loadCompany() {

                this.requesting = true

                this.$store.dispatch('Companies/loadCompany')
                    .then(
                        responseCompany => {

                            this.company = responseCompany.data

                            this.footer.model = this.company.print_footer
                            this.header.model = this.company.print_header

                        }
                    )
                    .catch(
                        error => {}
                    )
                    .finally(
                        () => {
                            this.requesting = false
                        }
                    )

            },

            //Events

            buttonEditClick(e) {

                this.footer.original = this.footer.model

                this.header.original = this.header.model

                this.isEditing = true

            },

            buttonPrintPreviewClick(e) {

                this.$eventbus.$emit(
                    'ComponentPrint/open',
                    {
                        baseUrl: `${process.env.VUE_APP_API_BASE_URL}/companies/{companyHash}/print_preview.pdf?page_size={pageSize}`,
                        companyHash: this.company.hashid,
                        noteId: null
                    }
                )

            },

            buttonCancelClick(e) {

                this.footer.model = this.footer.original

                this.header.model = this.header.original

                this.isEditing = false

            },

            buttonSaveClick(e) {

                const companyId = this.company.id

                this.axios
                    .patch(
                        `api/v1/companies/${companyId}`,
                        {
                            'company[print_footer]': this.footer.model,
                            'company[print_header]': this.header.model
                        }
                    )
                    .then(
                        response => {

                            this.$eventbus.$emit(
                                'ComponentToast/add',
                                {
                                    autoHide: true,
                                    content: `Layout de impressão salvo com sucesso`,
                                    icon: null,
                                    showCloseButton: false,
                                    type: 'success'
                                }
                            )

                            this.loadCompany()

                            this.isEditing = false

                        }
                    )
                    .catch(
                        error => {

                            this.$eventbus.$emit(
                                'ComponentToast/add',
                                {
                                    autoHide: true,
                                    content: 'Erro ao salvar o layout de impressão',
                                    icon: null,
                                    showCloseButton: false,
                                    type: 'error'
                                }
                            )

                        }
                    )
                    .finally(
                        () => {
                            this.$emit('requesting')
                        }
                    )

            },

        },//methods
        watch: {},//watch
        created() {},//created
        mounted() {

            this.loadCompany()

        },//mounted
        updated() {},//updated
        destroyed() {},//destroyed
        beforeRouteEnter(to, from, next) {
            next(
                vm => {
                }
            )
        },//beforeRouteEnter
        beforeRouteUpdate(to, from, next) {
            next()
        },//beforeRouteUpdate
        beforeRouteLeave(to, from, next) {
            next()
        }//beforeRouteLeave

    }
</script>

<style lang="scss" scoped>
    /* TinyMCE5 styles */
    .container-header-view, .container-footer-view {

        font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
        line-height: 1.4;
        margin: 1rem;

        ::v-deep table {
            border-collapse: collapse;
        }
        ::v-deep table th,
        ::v-deep table td {
            border: 1px solid #ccc;
            padding: 0.4rem;
        }
        ::v-deep figure {
            display: table;
            margin: 1rem auto;
        }
        ::v-deep figure figcaption {
            color: #999;
            display: block;
            margin-top: 0.25rem;
            text-align: center;
        }
        ::v-deep hr {
            border-color: #ccc;
            border-style: solid;
            border-width: 1px 0 0 0;
        }
        ::v-deep code {
            background-color: #e8e8e8;
            border-radius: 3px;
            padding: 0.1rem 0.2rem;
        }
        ::v-deep .mce-content-body:not([dir=rtl]) blockquote {
            border-left: 2px solid #ccc;
            margin-left: 1.5rem;
            padding-left: 1rem;
        }
        ::v-deep .mce-content-body[dir=rtl] blockquote {
            border-right: 2px solid #ccc;
            margin-right: 1.5rem;
            padding-right: 1rem;
        }

    }
</style>

<style scoped>
    #view-print-layout
    {

    }

    h2
    {
        color: #2794af;
        font-size: 1.3em;
        font-weight: 300;
        margin: 0 0 1em;
    }

    .component-view-tabs
    {
        margin-bottom: 1.5em;
    }

    .container-view-header
    {
        margin-bottom: 1em;
    }

    .container-view-content
    {
        padding-bottom: 2em;
    }

    .button-print-preview
    {
        display: inline-flex;

        background-color: #fff;
        border: none;
        border-radius: 100px;
        margin-right: 1em;
        padding: 0.75em 1.5em;
        vertical-align: middle;
    }

    .button-print-preview img
    {
        background-color: transparent;
        border: none;
        margin-right: 0.5em;
        padding: 0;
    }

    .container-header
    {
        background-color: #fff;
        border-radius: 5px;
        margin-bottom: 1em;
        padding: 1.2em;
    }

    .container-footer
    {
        background-color: #fff;
        border-radius: 5px;
        margin-bottom: 1em;
        padding: 1.2em;
    }

    @media (max-width: 767px) {

        .container-view-header
        {
            display: block;
        }

        .container-view-header .col-right
        {
            margin-top: 1em;
        }

        .container-view-header .col-right button img
        {
            margin-right: 0;
        }

    }

    @media (min-width: 768px) and (max-width: 1199px) {

    }

    @media (max-width: 1199px) {

        .container-view-content .container-buttons-top
        {
            margin-bottom: 1em;
        }

    }

    @media (min-width: 1200px) {

        .container-view-content .container-buttons-top
        {
            text-align: right;
        }

    }
</style>
