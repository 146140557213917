<template>
    <section id="view-onboarding-ready">
        <h1>Falta só mais um passo</h1>
        <div>Veja o vídeo de introdução do programa:</div>
        <div class="container-thumbnail-video">
            <img src="@/assets/img/thumb-onboarding.jpg" alt="" @click="thumbOnboardingClick">
        </div>
        <div>E em seguida:</div>
        <p>Você já pode começar a cadastrar seus {{ $store.getters['Companies/customerTreatmentPlural'].toLowerCase() }}.</p><p>Por enquanto seu formulário só tem o campo <b>NOME</b>, <b>DATA DE NASCIMENTO</b> e <b>SEXO</b>.<p>Selecione uma das opções abaixo para seguir:</p>
        <div class="container-buttons">
            <button :disabled="requestingUpdateCompany" @click="buttonGoToBusinessFormClick">
                <div>Incluir mais campos no formulário de {{ $store.getters['Companies/customerTreatmentSingular'].toLowerCase() }}</div>
                <div>
                    <div>Exemplos: endereço, CPF, RG etc.</div>
                </div>
            </button>
            <button :disabled="requestingUpdateCompany" @click="buttonGoToCustomersClick">
                <div>Pular esta parte ir direto para o programa</div>
                <div>Dica: você pode incluir e alterar os campos do formulário de cadastro a qualquer momento.</div>
            </button>
        </div>

        <ComponentModal class="component-modal-onboarding-video" ref="modalOnboardingVideo">
            <template v-slot:content>
                <iframe id="main-video" style="border-radius: 8px;" width="742" height="418" src="https://www.youtube.com/embed/cKhhsiYjUKU?showinfo=0&rel=0&modestbranding=1" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </template>
            <template v-slot:footer>
                <button type="button" class="btn-default btn-terciary" @click="modalOnboardingVideoButtonCancel">Fechar</button>
            </template>
        </ComponentModal>

    </section>
</template>

<script>
    import MixinView from '@/mixins/MixinView.js'

    import ComponentModal from "@/components/ui/ComponentModal"

    export default {
        name: 'OnboardingReady',
        props: [],
        mixins: [MixinView],
        components: {
            ComponentModal
        },//components
        data() {
            return {

                requestingUpdateCompany: false

            }
        },//data
        computed: {

            companyId() {
                return this.$store.getters['App/companyId']
            },

        },//computed
        methods: {

            finishOnboarding(destinyRouteName) {

                const companyId = this.companyId

                let data = {
                    'company[onboarding_completed_at]': this.$moment(Date.now()).format('YYYY-MM-DD HH:mm:ss')
                }

                this.requestingUpdateCompany = true

                this.axios.patch(`api/v1/companies/${companyId}`, data)
                    .then(
                        response => {
                            this.$router.replace({ name: destinyRouteName }, () => {}, () => {})
                        }
                    )
                    .catch(
                        error => {

                            const data = error.response.data

                            if(data.errors) {

                                data.errors.forEach(
                                    (item, index, array) => {

                                        this.$eventbus.$emit(
                                            'ComponentToast/add',
                                            {
                                                autoHide: true,
                                                content: 'Erro ao finalizar o onboarding',
                                                icon: null,
                                                showCloseButton: false,
                                                type: 'error'
                                            }
                                        )

                                    }
                                )

                            } else {

                                this.$eventbus.$emit(
                                    'ComponentToast/add',
                                    {
                                        autoHide: true,
                                        content: 'Falha de comunicação com o servidor',
                                        icon: null,
                                        showCloseButton: false,
                                        type: 'error'
                                    }
                                )

                            }

                        }
                    )
                    .finally(
                        () => {
                            this.requestingUpdateCompany = false
                        }
                    )

            },

            //Events

            buttonGoToBusinessFormClick(e) {
                this.finishOnboarding('BusinessForm')
            },

            buttonGoToCustomersClick(e) {
                this.finishOnboarding('Customers')
            },

            thumbOnboardingClick(e) {
                this.$refs.modalOnboardingVideo.show = true
            },

            modalOnboardingVideoButtonCancel(e) {
                this.$refs.modalOnboardingVideo.show = false
            },

        },//methods
        watch: {},//watch
        created() {},//created
        mounted() {},//mounted
        updated() {},//updated
        destroyed() {},//destroyed
        beforeRouteEnter(to, from, next) {
            next(
                vm => {
                }
            )
        },//beforeRouteEnter
        beforeRouteUpdate(to, from, next) {
            next()
        },//beforeRouteUpdate
        beforeRouteLeave(to, from, next) {
            next()
        }//beforeRouteLeave

    }
</script>

<style scoped>
    #view-onboarding-ready
    {
        text-align: center;
    }

    h1
    {
        font-size: 2.5em;
        font-weight: 400;
    }

    p
    {
        font-size: 1.15em;
        margin: 0.5em auto 1em;
    }

    .container-thumbnail-video
    {
        margin: 1em;
    }

    .container-thumbnail-video img
    {
        cursor: pointer;
        width: 320px;
    }

    .container-buttons
    {

    }

    .container-buttons button
    {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        background-color: transparent;
        border: 2px solid #29B1CC;
        border-radius: 5px;
        box-shadow: 0 0 0 0 transparent;
        box-sizing: border-box;
        margin: 0;
        padding: 1em;
        text-align: left;

        transition: all 0.15s ease;
    }

    .container-buttons button:active,
    .container-buttons button:hover
    {
        background-color: #29B1CC;
        box-shadow: 0 4px 0 0 #0a99b2;
        color: #fff;
        transform: translateY(-4px);
    }

    .container-buttons button > div:first-child
    {
        color: #29B1CC;
        text-transform: uppercase;
    }

    .container-buttons button:hover > div:first-child
    {
        color: #fff;
    }

    .container-buttons button > div:last-child
    {

    }

    @media (max-width: 767px) {

        h1
        {
            font-size: 1.5em;
        }

        p
        {
            font-size: 0.82em;
        }

        .container-thumbnail-video img
        {
            max-width: 100%;
        }

        .container-image img
        {
            height: 10rem;
        }

        .container-buttons
        {

        }

        .container-buttons button
        {
            margin-top: 1.5em;
        }

        .container-buttons button > div:first-child
        {
            margin-bottom: 0.5em;
        }

        .container-buttons button > div:last-child
        {
            font-size: 0.7em;
        }

    }

    @media (min-width: 768px) {

        h1
        {
            font-size: 2em;
        }

        p
        {
            max-width: 920px;
        }

        .container-buttons
        {
            display: flex;

            margin: 2em auto 0;
            max-width: 920px;
        }

        .container-buttons button
        {
            flex: 1;
        }

        .container-buttons button:first-child
        {
            margin-right: 2em;
        }

        .container-buttons button > div:first-child
        {
            font-size: 1.2em;
            margin-bottom: 1em;
        }

        .container-buttons button > div:last-child
        {
            font-size: 0.9em;
        }

    }

    @media (max-width: 991px) {

    }

    @media (min-width: 992px) {

        .container-buttons button > div:first-child
        {
            font-size: 1.4em;
        }

    }

    @media (max-width: 1199px) {

    }

    @media (min-width: 1200px) {

    }
</style>
