import { render, staticRenderFns } from "./ComponentInfoBox.vue?vue&type=template&id=69fb5fe6&scoped=true"
import script from "./ComponentInfoBox.vue?vue&type=script&lang=js"
export * from "./ComponentInfoBox.vue?vue&type=script&lang=js"
import style0 from "./ComponentInfoBox.vue?vue&type=style&index=0&id=69fb5fe6&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "69fb5fe6",
  null
  
)

export default component.exports