<template>
    <div :class="['component-input-text', { focus }]">
        <div class="container-main">
            <img class="icon" :src="icon_" alt="" v-if="icon_">

            <template v-if="type === 'datalist'">
                <datalist :id="datalistId">
                    <option :value="item.value" :key="index" v-for="(item, index) in datalistOptions">{{item.label}}</option>
                </datalist>
            </template>

            <template v-if="type !== 'textarea'">
                <input :autocomplete="autocomplete" :class="{ 'validation-error': isInvalid }" :list="type === 'datalist' ? datalistId : false" :placeholder="placeholder" ref="input" size="1" :type="type" :value="value" v-mask="mask_" @blur="inputBlur" @focus="inputBlur" @input="inputInput" @keyup.enter="keyupEnter" v-if="mask_">
                <input :autocomplete="autocomplete" :class="{ 'validation-error': isInvalid }" :list="type === 'datalist' ? datalistId : false" :placeholder="placeholder" ref="input" size="1" :type="type" :value="value" @blur="inputBlur" @focus="inputFocus" @input="inputInput" @keyup.enter="keyupEnter" v-else>
            </template>
            <template v-else>
                <textarea :class="{ 'validation-error': isInvalid }" cols="1" :placeholder="placeholder" ref="input" rows="3" :type="type" :value="value" v-mask="mask_" @blur="inputBlur" @focus="inputFocus" @input="inputInput" @keyup.enter="keyupEnter" v-if="mask_"></textarea>
                <textarea :class="{ 'validation-error': isInvalid }" cols="1" :placeholder="placeholder" ref="input" rows="3" :type="type" :value="value" @blur="inputBlur" @focus="inputFocus" @input="inputInput" @keyup.enter="keyupEnter" v-else></textarea>
            </template>

            <button v-if="showPasswordButton_" @click="buttonShowPasswordClick">
                <img src="~@/assets/img/ico/ico_eye.svg" alt="">
            </button>
        </div>
        <div class="container-validation-message" v-if="value && isInvalid">
            Formato incorreto
        </div>
    </div>
</template>

<script>
    import {TheMask} from 'vue-the-mask'
    import {mask} from 'vue-the-mask'

    export default {
        name: 'ComponentInputText',
        directives: {
            mask
        },
        props: {
            datalistOptions: String,
            icon: String,
            maskPattern: String,
            autocomplete: { type: String, default: 'false'},
            placeholder: String,
            showPasswordButton: String,
            type: String,
            validation: String,
            value: String
        },
        mixins: [],
        components: {
            TheMask
        },//components
        data() {
            return {
                focus: false,
                stInput: null,
                valid: null
            }
        },//data
        computed: {

            datalistId() {
                return `datalist_${this.$helpers.uuidv4()}`
            },

            icon_() {

                let ret = false

                if(this.icon) {
                    ret = this.icon
                }

                return ret

            },

            mask_() {

                let ret = false

                if(this.maskPattern) {
                    ret = this.maskPattern
                }

                return ret

            },

            isInvalid() {
                return this.valid === false
            },

            showPasswordButton_() {

                let ret = false

                if(this.showPasswordButton === 'true' || this.showPasswordButton === true) {
                    ret = true
                }

                return ret

            },

        },//computed
        methods: {

            validate(value) {

                const validation = this.validation

                clearTimeout(this.stInput)

                this.stInput =
                    setTimeout(
                        () => {

                            this.valid = this.$helpers.validateSingleField(value, validation)

                            this.$emit('isValid', this.valid)

                        },
                        750
                    )

            },

            inputBlur(e) {
                this.focus = false
            },

            inputFocus(e) {
                this.focus = true
            },

            inputInput(e) {

                const value = e.currentTarget.value

                this.$emit('input', value)

                this.validate(value)

            },

            buttonShowPasswordClick(e) {

                if(this.$refs.input.type === 'password') {
                    this.$refs.input.type = 'text'
                } else {
                    this.$refs.input.type = 'password'
                }

            },

            keyupEnter(e) {
                this.$emit('keyupEnter', e)
            }

        },//methods
        watch: {

            value(value, oldValue) {
                this.validate(value)
            }

        },//watch
        created() {},//created
        mounted() {
            this.validate(this.value)
        },//mounted
        updated() {},//updated
        destroyed() {},//destroyed
    }
</script>

<style scoped>
    .component-input-text
    {
        display: inline-block;
    }

    .component-input-text .container-main
    {
        display: inline-flex;
        align-items: center;

        background-color: #fff;
        border: 1px solid #d0d0d0;
        border-radius: 4px;
        box-sizing: border-box;
        padding: 0.4em;
        width: 100%;
    }

    .component-input-text.focus .container-main
    {
        box-shadow: 0 0 2px 2px rgba(41, 177, 204, 0.4);
    }

    .component-input-text .container-validation-message
    {
        color: #d00;
        font-size: 0.7em;
        margin: 0;
        padding: 0.2em 0;
    }

    button
    {
        background-color: transparent;
        border: none;
        padding: 0;
    }

    input,
    textarea
    {
        flex: 1;

        background-color: transparent;
        border: none;
    }

    input::-webkit-input-placeholder,
    textarea::-webkit-input-placeholder
    {
        font-weight: 300;
        opacity: 0.5;
    }

    input::-moz-placeholder,
    textarea::-moz-placeholder
    {
        font-weight: 300;
        opacity: 0.5;
    }

    input:-ms-input-placeholder,
    textarea:-ms-input-placeholder
    {
        font-weight: 300;
        opacity: 0.5;
    }

    input::-ms-input-placeholder,
    textarea::-ms-input-placeholder
    {
        font-weight: 300;
        opacity: 0.5;
    }

    input::placeholder,
    textarea::placeholder
    {
        font-weight: 300;
        opacity: 0.5;
    }

    input.validation-error,
    textarea.validation-error
    {
        color: #d00;
    }

    .icon
    {
        margin-right: 0.3em;
        max-height: 1em;
        width: 1.1em;
    }

    @media (max-width: 767px) {

    }

    @media (min-width: 768px) {

    }

    @media (min-width: 992px) {

    }

    @media (max-width: 1199px) {

    }

    @media (min-width: 1200px) {

        input,
        textarea
        {
            min-width: 6em;
        }

    }
</style>
