//State
const state = {

    company: null,

    customerFields: null,

    customerFieldsOrder: null

}

//Getters
const getters = {

    company(state) {
        return state.company
    },

    companyTreatmentSingular(state) {

        let ret = 'Empresa'

        if(state.company) {
            ret = state.company.company_treatment
        }

        return ret

    },

    companyTreatmentPlural(state) {

        let ret = 'Empresas'

        if(state.company) {
            ret = state.company.company_treatment_plural
        }

        return ret

    },

    customerTreatmentSingular(state) {

        let ret = 'Cliente'

        if(state.company) {
            ret = state.company.customer_treatment
        }

        return ret

    },

    customerTreatmentPlural(state) {

        let ret = 'Clientes'

        if(state.company) {
            ret = state.company.customer_treatment_plural
        }

        return ret

    },

    customerFieldsOrder(state) {
        return state.customerFieldsOrder
    }

}

//Actions
const actions = {

    loadCompany(context) {

        return new Promise(
            (resolve, reject) => {

                const companyId = context.rootGetters['App/user'].company_id

                this._vm.$fetch.get(`/api/v1/companies/${companyId}`)
                    .then(
                        response => {

                            context.dispatch('setCompany', response.data)

                            resolve(response)

                        }
                    )
                    .catch(
                        error => {

                            this._vm.$eventbus.$emit(
                                'ComponentToast/add',
                                {
                                    autoHide: true,
                                    content: `Erro ao carregar a ${context.getters['companyTreatmentSingular'].toLowerCase()}`,
                                    icon: null,
                                    showCloseButton: false,
                                    type: 'error'
                                }
                            )

                            reject(error)

                        }
                    )

            }

        )

    },

    loadCustomerFields(context) {

        return new Promise(
            (resolve, reject) => {

                context.dispatch('loadCompany')
                    .then(
                        responseCompany => {

                            const companyId = responseCompany.data.id

                            this._vm.$fetch.get(`/api/v1/companies/${companyId}/customer_fields`)
                                .then(
                                    responseCustomerFields => {

                                        const data = responseCustomerFields.data

                                        context.commit('SET_CUSTOMER_FIELDS', data)

                                        resolve(
                                            {
                                                responseCompany,
                                                responseCustomerFields
                                            }
                                        )

                                    }
                                )
                                .catch(
                                    errorCustomerFields => {

                                        reject(errorCustomerFields)

                                        this._vm.$eventbus.$emit(
                                            'ComponentToast/add',
                                            {
                                                autoHide: true,
                                                content: `Erro ao carregar as perguntas da ${context.getters['companyTreatmentSingular'].toLowerCase()}`,
                                                icon: null,
                                                showCloseButton: false,
                                                type: 'error'
                                            }
                                        )

                                    }
                                )

                        }
                    )
                    .catch(
                        errorCompany => {
                            reject(errorCompany)
                        }
                    )

            }
        )

    },

    setCompany(context, obj) {

        localStorage.setItem('ordexa/company/id', obj.id)
        localStorage.setItem('ordexa/company/hash', obj.hashid)

        context.commit('SET_COMPANY', obj)

        context.commit('SET_CUSTOMER_FIELDS_ORDER', obj.customer_fields_order)

    },

}

//Mutations
const mutations = {

    SET_COMPANY(state, obj) {
        state.company = obj
    },

    SET_CUSTOMER_FIELDS(state, obj) {
        state.customerFields = obj
    },

    SET_CUSTOMER_FIELDS_ORDER(state, obj) {
        state.customerFieldsOrder = obj
    },

}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
