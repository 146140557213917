<template>
    <section id="view-onboarding-your-business">

        <ComponentLoading v-if="requestingLoadCompany"/>
        <template v-else>

            <h1>Sua empresa ou negócio</h1>
            <ComponentBusinessProfile
                :company="company"
                :key="JSON.stringify(company)"
                :pictureFileUrl="company.image_url"
                ref="componentBusinessProfile"
                @requestingDone="componentBusinessProfileRequestingDone"
                @requestingUpdate="componentBusinessProfileRequestingUpdate"
                @updated="componentBusinessProfileUpdated"/>
            <div class="container-buttons">
                <router-link class="btn-default btn-secondary btn-back" :disabled="requestingUpdateCompany" tag="button" :to="{ name: 'OnboardingWelcome' }">
                    <img src="~@/assets/img/ico/ico_arrow-white.svg" alt="">
                    <span v-if="$mq.match(/lg/)">VOLTAR</span>
                </router-link>
                <button class="btn-default btn-primary btn-continue" :disabled="requestingUpdateCompany" type="button" @click="buttonContinueClick">

                    <template v-if="requestingUpdateCompany">
                        <img class="icon-circle-segment-animated" src="~@/assets/img/ico/ico_circle_segment_white.svg" alt="">
                        <span>AGUARDE</span>
                    </template>
                    <template v-else>
                        <span v-if="$mq.match(/lg/)">CONTINUAR</span>
                        <img src="~@/assets/img/ico/ico_arrow-white.svg" alt="">
                    </template>

                </button>
            </div>

        </template>

    </section>
</template>

<script>
    import MixinView from '@/mixins/MixinView.js'

    import ComponentBusinessProfile from "@/components/ComponentBusinessProfile"
    import ComponentInputText from "@/components/ui/ComponentInputText"
    import ComponentLoading from "@/components/ui/ComponentLoading"

    export default {
        name: 'OnboardingYourBusiness',
        props: [],
        mixins: [MixinView],
        components: {
            ComponentBusinessProfile,
            ComponentInputText,
            ComponentLoading
        },//components
        data() {
            return {

                company: {
                    company_treatment: null,
                    customer_treatment: null,
                    image_url: null,
                },

                model: {},

                requestingLoadCompany: false,

                requestingUpdateCompany: false,

            }
        },//data
        computed: {

            userName() {
                return localStorage.getItem('ordexa/user/name')
            }

        },//computed
        methods: {

            loadCompany() {

                this.requestingLoadCompany = true

                this.$store.dispatch('Companies/loadCompany')
                    .then(
                        responseCompany => {
                            this.company = responseCompany.data
                        }
                    )
                    .catch(
                        error => {}
                    )
                    .finally(
                        () => {
                            this.requestingLoadCompany = false
                        }
                    )

            },

            //Events

            buttonContinueClick(e) {

                const companyId = this.company.id
                const isValid = this.$helpers.validateFields(this.$refs.componentBusinessProfile.$children)

                if(isValid) {

                    this.model = this._.cloneDeep(this.$refs.componentBusinessProfile.model)

                    let data = {
                        'company[company_treatment]': this.model.companyTreatment,
                        'company[customer_treatment]': this.model.customerTreatment
                    }

                    if(this.model.pictureFile) {
                        data['company[attachment_attributes][attachment]'] = this.model.pictureFile
                    }

                    this.requestingUpdateCompany = true

                    this.axios.patch(`api/v1/companies/${companyId}`, data)
                        .then(
                            response => {
                                this.$router.replace({ name: 'OnboardingPersonalProfile' }, () => {}, () => {})
                            }
                        )
                        .catch(
                            error => {

                                const data = error.response.data

                                if(data.errors) {

                                    data.errors.forEach(
                                        (item, index, array) => {

                                            this.$eventbus.$emit(
                                                'ComponentToast/add',
                                                {
                                                    autoHide: true,
                                                    content: 'Erro ao atualizar sua empresa/negócio',
                                                    icon: null,
                                                    showCloseButton: false,
                                                    type: 'error'
                                                }
                                            )

                                        }
                                    )

                                } else {

                                    this.$eventbus.$emit(
                                        'ComponentToast/add',
                                        {
                                            autoHide: true,
                                            content: 'Falha de comunicação com o servidor',
                                            icon: null,
                                            showCloseButton: false,
                                            type: 'error'
                                        }
                                    )

                                }

                            }
                        )
                        .finally(
                            () => {
                                this.requestingUpdateCompany = false
                            }
                        )

                } else {

                    this.$eventbus.$emit(
                        'ComponentToast/add',
                        {
                            autoHide: true,
                            content: 'Preencha corretamente todos os campos para prosseguir (verifique os obrigatórios)',
                            icon: null,
                            showCloseButton: false,
                            type: 'error'
                        }
                    )

                }

            },

            componentBusinessProfileRequestingDone() {
                this.loadCompany()
            },

            componentBusinessProfileRequestingUpdate() {
                this.requestingUpdateCompany = true
            },

            componentBusinessProfileUpdated() {
                this.requestingUpdateCompany = false
            }

        },//methods
        watch: {},//watch
        created() {},//created
        mounted() {

            this.loadCompany()

        },//mounted
        updated() {},//updated
        destroyed() {},//destroyed
        beforeRouteEnter(to, from, next) {
            next(
                vm => {}
            )
        },//beforeRouteEnter
        beforeRouteUpdate(to, from, next) {
            next()
        },//beforeRouteUpdate
        beforeRouteLeave(to, from, next) {
            next()
        }//beforeRouteLeave

    }
</script>

<style scoped>
    #view-onboarding-your-business
    {
        text-align: center;
    }

    h1
    {
        font-weight: 400;
        margin-bottom: 1em;
    }

    .container-buttons
    {
        display: flex;
        justify-content: space-between;
    }

    .container-buttons button
    {
        font-size: 0.9em;
        padding-left: 3em;
        padding-right: 3em;
    }

    .container-buttons button img
    {
        vertical-align: middle;
    }

    .container-buttons button img:first-child
    {
        margin-right: 0.5em;
    }

    .container-buttons button span
    {
        vertical-align: middle;
    }

    .btn-back img
    {
        margin-right: 0.5em;
        transform: rotate(180deg);
    }

    .btn-continue img
    {
        margin-left: 0.5em;
    }

    @media (max-width: 767px) {

    }

    @media (min-width: 768px) and (max-width: 1199px) {

    }

    @media (max-width: 1199px) {

        h1
        {
            font-size: 1.25em;
        }

    }

    @media (min-width: 1200px) {

        h1
        {
            font-size: 1.5em;
        }

    }
</style>
