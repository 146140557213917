require('es6-promise').polyfill()
const originalFetch = require('isomorphic-fetch')
const fetch = require('fetch-retry')(originalFetch)

const baseURL = process.env.VUE_APP_API_BASE_URL
const retryOptions = {
    retries: 2,
    retryDelay: 3000,
    retryOn: [403, 500]
}

let vm = null

function get(url) {

    const requestOptions = getRequestOptions()
    const vm = this.vm
    const user = vm.$store.getters['App/user']

    url = url.match(/http[s]?:\/\//) ? url : baseURL + url

    requestOptions.method = 'GET'

    const st = setTimeout(
        () => {

            vm.$rollbar.configure(
                {
                    payload: {
                        person: {
                            id: user.id,
                            username: user.email,
                            email: user.email
                        }
                    }
                }
            )

            vm.$rollbar.warning(
                'A api demorou mais de 25s para responder',
                {
                    company_id: user.company_id,
                    email: user.email,
                    id: user.id,
                    username: user.email
                }
            )

        },
        25000
    )

    const request = fetch(url, requestOptions).then(
        response => {

            clearTimeout(st)

            return response.text().then(
                text => {

                    const data = text && JSON.parse(text)

                    if (!response.ok) {
                        const error = (data && data.message) || response.statusText
                        return Promise.reject(
                            {
                                error,
                                response
                            }
                        )
                    }

                    return {
                        data,
                        response
                    }

                }
            )

        }
    )

    return request

}

//Functions

function getDefaultHeaders() {

    const accessToken = localStorage.getItem('ordexa/headers/access-token')
    const client = localStorage.getItem('ordexa/headers/client')
    const expiry = localStorage.getItem('ordexa/headers/expiry')
    const tokenType = localStorage.getItem('ordexa/headers/token-type')
    const uid = localStorage.getItem('ordexa/headers/uid')

    return {
        'access-token': accessToken,
        'client': client,
        'expiry': expiry,
        'token-type': tokenType,
        'uid': uid
    }

}

function getRequestOptions() {

    const headers = getDefaultHeaders()

    return {
        headers,
        ...retryOptions
    }

}

export default {
    get,
    // post,
    // put,
    // delete: _delete,
    vm
}
