<template>
    <Editor
        api-key="c1voym5l3vgicaq9xyxdncfgzuiebtlwbswiv19rawih3onc"
        :disabled="disabled"
        :init="editor.config"
        v-model="model"
        @onInit="onInit" />
</template>

<script>
    import Editor from '@tinymce/tinymce-vue'

    export default {
        name: 'ComponentTinyMCE5',
        props: [
            'disabled',
            'value'
        ],
        mixins: [],
        components: {
            Editor
        },//components
        data() {
            return {

                editor: {
                    config: {
                        entity_encoding : 'raw',
                        file_picker_types: 'image',
                        file_picker_callback: function (cb, value, meta) {

                            var input = document.createElement('input');

                            input.setAttribute('type', 'file');
                            input.setAttribute('accept', 'image/*');

                            input.onchange = function () {

                                var file = this.files[0];
                                var reader = new FileReader();

                                reader.onload = function () {

                                    var id = 'blobid' + (new Date()).getTime();
                                    var blobCache =  tinymce.activeEditor.editorUpload.blobCache;
                                    var base64 = reader.result.split(',')[1];
                                    var blobInfo = blobCache.create(id, file, base64);
                                    blobCache.add(blobInfo);

                                    cb(blobInfo.blobUri(), { title: file.name });
                                };

                                reader.readAsDataURL(file);

                            };

                            input.click();

                        },
                        forced_root_block: false,
                        image_caption: false,
                        language: 'pt_BR',
                        menubar: false,
                        min_height: 350,
                        mobile: {
                            contextmenu: false,
                            toolbar_mode: 'scrolling'
                        },
                        paste_data_images: true,
                        plugins: 'autoresize charmap code importcss print preview paste  searchreplace autolink save directionality  visualblocks visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern noneditable help quickbars emoticons hr',
                        quickbars_insert_toolbar: false,
                        quickbars_selection_toolbar: false,
                        resize: true,
                        statusbar: false,
                        toolbar: 'bold italic underline strikethrough | forecolor backcolor removeformat | image link | table | align | outdent indent | numlist bullist | charmap emoticons hr | fontselect fontsizeselect formatselect | undo redo',
                        toolbar_mode: 'sliding',
                    }
                },

                model: null,

            }
        },//data
        computed: {},//computed
        methods: {

            onInit(e) {
                this.model = this.value
            }

        },//methods
        watch: {

            model(value, oldValue) {
                this.$emit('input', value)
            },

            value(value_, oldValue) {
                this.model = value_
            },

        },//watch
        created() {},//created
        mounted() {},//mounted
        updated() {},//updated
        destroyed() {},//destroyed
    }
</script>

<style scoped>
    @media (max-width: 767px) {

    }

    @media (min-width: 768px) {

    }

    @media (min-width: 992px) {

    }

    @media (max-width: 1199px) {

    }

    @media (min-width: 1200px) {

    }
</style>
