import Vue from 'vue'
import axios from 'axios'
import lodash from 'lodash'
import VueAxios from 'vue-axios'

axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL
axios.defaults.timeout = 29500

axios.defaults.transformRequest = [
    (data, headers) => {

        if (data) {
            if (data.meta) {

                if (data.meta.contentType) {
                    headers['Content-Type'] = data.meta.contentType;
                }

                delete data.meta;

                data = JSON.stringify(data);

            } else {

                const formData = new FormData();

                headers['Content-Type'] = 'multipart/form-data';

                //Converts object literal or JSON object to form data before sending
                _.forEach(
                    data,
                    (value, key) => {
                        formData.append(key, value)
                    }
                );

                data = formData;

            }
        }

        return data;

    }
]

axios.interceptors.request.use(
    request => {

        const accessToken = localStorage.getItem('ordexa/headers/access-token')
        const client = localStorage.getItem('ordexa/headers/client')
        const contentType = localStorage.getItem('ordexa/headers/content-type')
        const expiry = localStorage.getItem('ordexa/headers/expiry')
        const tokenType = localStorage.getItem('ordexa/headers/token-type')
        const uid = localStorage.getItem('ordexa/headers/uid')

        request.headers['access-token'] = accessToken
        request.headers['client'] = client
        request.headers['expiry'] = expiry
        request.headers['token-type'] = tokenType
        request.headers['uid'] = uid

        if (request.method === 'delete') {
            request.headers['content-type'] = 'application/x-www-form-urlencoded'
        }

        return request

    },
    error => {
        return Promise.reject(error)
    }
)

axios.interceptors.response.use(
    response => {
        return response
    },
    error => {

        const httpRequestError = new CustomEvent(
            'httpRequestError', {
                detail: {
                    error
                }
            }
        )

        window.dispatchEvent(httpRequestError)

        return Promise.reject(error)

    }
)

Vue.use(VueAxios, axios)