<template>
    <section id="view-customers-details" ref="viewCustomersDetails">

        <template v-if="requestingCustomer">
            <ComponentLoading></ComponentLoading>
        </template>

        <template v-if="!requestingCustomer && customer">

            <div class="container-view-content">

                <div class="container-customer-header" ref="containerCustomerHeader">
                    <div class="row-name-and-button">
                        <div class="col-left">
                            <span class="user-picture" :style="{ 'background-image': `url(${userPictureUrl})` }"></span>
                            <span class="customer-name" v-if="$mq.match(/lg/)">{{ customer.name }}</span>
                            <span class="customer-name" v-if="$mq.match(/xs|sm|md/)">{{ customer.name.split(' ')[0] }}</span>
                        </div>
                        <div class="col-right">
                            <transition name="fade">
                                <button type="button" class="btn-default btn-primary button-edit" @click="buttonEditCustomerClick" v-show="customerInfo.show">
                                    <img src="~@/assets/img/ico/ico_edit_blue.svg" alt="">
                                    <span v-if="$mq.match(/lg/)">Editar</span>
                                </button>
                            </transition>
                            <button type="button" class="btn-default btn-primary button-show-customer-info" :title="'Exibir os dados do ' + $store.getters['Companies/customerTreatmentSingular'].toLowerCase()" @click="buttonShowCustomerInfoClick">
                                <img :class="[{ active: customerInfo.show }]" src="~@/assets/img/ico/ico_arrow_down_white.svg" alt="">
                                <span v-if="$mq.match(/lg/)">Ver dados</span>
                            </button>
                        </div>
                    </div>
                    <transition name="fade">
                        <hr v-if="customerInfo.show">
                    </transition>
                    <transition name="fade">
                        <div class="container-customer-info" ref="containerCustomerInfo" v-if="customerInfo.show">
                            <div class="container-item-question">
                                <div>1. Nome</div>
                                <div>{{ customer.name }}</div>
                            </div>
                            <div class="container-item-question" :key="index" v-for="(item, index) in answersQuestions">
                                <div>{{ index + 2 }}. {{ item.question }}</div>
                                <div>{{ getAnswerAsString(item.answer) }}<span class="temporal-distance" v-if="item.answerType === 'date' && item.answer !== 'Não informado'">({{ $helpers.getTemporalDistance(item.answer) }})</span></div>
                            </div>
                        </div>
                    </transition>
                </div>

                <div class="container-notes" ref="containerNotes" v-if="$can('read', 'CustomerNote') || $can('show', 'CustomerNote')">
                    <div class="container-title">
                        <div class="col-left">
                            <img :class="[{ active: customerInfo.show }]" src="~@/assets/img/ico/ico_chat.svg" alt=""><span>Anotações</span>
                        </div>
                        <div class="col-right">
                            <button type="button" class="btn-default btn-primary button-add-note" @click="buttonAddNoteClick">
                                <img src="~@/assets/img/ico/ico_plus_white.svg" alt="">
                                <span v-if="$mq.match(/lg/)">Criar anotação</span>
                            </button>
                        </div>
                    </div>

                    <div class="container-component-note-add-update" ref="containerComponentNoteAddUpdate" v-if="addNote.show">
                        <ComponentNoteAddUpdate
                            :body="addNote.body"
                            :customerId="customerId"
                            :noteId="null"
                            @cancel="componentNoteAddUpdateCancel"
                            @noteSaved="componentNoteAddUpdateNoteSaved"/>
                    </div>

                    <div :class="['container-added-notes']">
                        <div class="container-requesting-notes" v-if="requestingCustomerNotes">
                            <ComponentLoading label="Carregando anotações"></ComponentLoading>
                        </div>
                        <div class="container-items" v-if="hasNotes && !requestingCustomerNotes">
                            <ComponentNoteItem
                                :company="company"
                                :customerId="customerId"
                                :key="item.id"
                                :item="item"
                                :notesEditing="notesEditing"
                                @duplicateNote="componentNoteItemDuplicateNote"
                                @noteDeleted="componentNoteItemNoteDeleted"
                                @noteLocked="componentNoteItemNoteLocked"
                                @noteSaved="componentNoteItemNoteSaved"
                                v-for="(item, index) in notes" />
                        </div>
                        <div class="container-no-items" v-if="!hasNotes && !requestingCustomerNotes">
                            Você ainda não cadastrou anotações para este {{ company.customer_treatment.toLowerCase() }}
                        </div>
                    </div>

                </div>
                <div class="container-no-access-to-notes" v-else>
                  Você não tem acesso às anotações. Solicite este acesso a um usuário administrador.
                </div>

            </div>

        </template>

        <ComponentModal class="component-modal-confirm-view-exit" ref="modalConfirmViewExit">
            <template v-slot:title>
                <span>Atenção</span>
            </template>
            <template v-slot:content>
                <p>Você está editando anotações. Ao sair desta tela, todas as alterações não salvas serão descartadas. Tem certeza de que deseja sair?</p>
            </template>
            <template v-slot:footer>
                <button type="button" class="btn-default btn-terciary" @click="modalConfirmViewExitNo">Não</button>
                <button type="button" class="btn-default btn-primary" @click="modalConfirmViewExitYes">Sim</button>
            </template>
        </ComponentModal>

    </section>
</template>

<script>
    import MixinView from '@/mixins/MixinView.js'

    import ComponentInputText from "@/components/ui/ComponentInputText"
    import ComponentLoading from "@/components/ui/ComponentLoading"
    import ComponentModal from "@/components/ui/ComponentModal"
    import ComponentNoteAddUpdate from "@/components/Note/ComponentNoteAddUpdate"
    import ComponentNoteItem from "@/components/Note/ComponentNoteItem"
    import ComponentTabs from "@/components/ui/ComponentTabs"

    export default {
        name: 'CustomersDetails',
        props: [],
        mixins: [MixinView],
        components: {
            ComponentInputText,
            ComponentLoading,
            ComponentModal,
            ComponentNoteAddUpdate,
            ComponentNoteItem,
            ComponentTabs
        },//components
        data() {
            return {

                addNote: {
                    body: null,
                    show: false
                },

                company: null,

                containerAnswersQuestions: {
                    showAll: false
                },

                customerFields: null,

                customer: null,

                customerInfo: {
                    show: false
                },

                notes: null,

                notesEditing: [],

                requestingCustomer: false,

                requestingCustomerNotes: false,

                requestingNoteTemplates: false,

                routeExitTo: null

            }
        },//data
        computed: {

            answersQuestions() {

                let items = []

                this.customerFieldsOrder.map(
                    (questionId, index, array) => {

                        const foundQuestion = this._.find(this.customerFields, { id: questionId })

                        let foundAnswer = ''

                        if(this.customer.fields_values) {

                            foundAnswer = this.customer.fields_values[questionId]

                            if(!foundAnswer) {
                                foundAnswer = 'Não informado'
                            }

                        } else {
                            foundAnswer = 'Não informado'
                        }

                        if(foundQuestion) {
                            items.push(
                                {
                                    answer: foundAnswer ? foundAnswer : '',
                                    answerType: foundQuestion.answer_type,
                                    question: foundQuestion.question,
                                }
                            )
                        }

                    }
                )

                return items

            },

            customerFieldsOrder() {
                return this.$store.getters['Companies/customerFieldsOrder']
            },

            customerId() {

                const ret = this.$route.params.id

                return ret

            },

            hasNotes() {

                let ret = false

                if(this.notes) {

                    if(this.notes.length > 0) {
                        ret = true
                    }

                }

                return ret

            },

            isEditing() {
                return this.notesEditing.length > 0
            },

            user() {
              return this.$store.getters['App/user']
            },

            userPictureUrl() {

                if(this.customer.image_url) {
                    return this.customer.image_url
                } else {
                    return require('@/assets/img/ico/ico_profile.svg')
                }

            },

        },//computed
        methods: {

            getAnswerAsString(answer) {

                let ret = answer

                if(Array.isArray(answer)) {
                    ret = ret.join(', ')
                } else {

                    if(answer.match(/[0-9]{4}-[0-9]{2}-[0-9]{2}T[0-9]{2}:[0-9]{2}:[0-9]{2}\.[0-9]{3}Z/)) {
                        ret = this.$moment(answer).format('DD/MM/YYYY HH:mm')
                    }

                    if(answer.match(/[0-9]{4}-[0-9]{2}-[0-9]{2}/)) {
                        ret = this.$moment(answer).format('DD/MM/YYYY')
                    }

                }

                ret = ret.replace('}', '').replace('{', '- ')

                return ret

            },

            loadCustomer() {

                return new Promise(
                    (resolve, reject) => {

                        const customerId = this.customerId

                        this.requestingCustomer = true

                        this.$store.dispatch('Companies/loadCustomerFields')
                            .then(
                                responseLoadCustomerFields => {

                                    this.company = responseLoadCustomerFields.responseCompany.data
                                    this.customerFields = responseLoadCustomerFields.responseCustomerFields.data

                                    this.$store.dispatch('Customers/loadCustomerById', {customerId})
                                        .then(
                                            responseLoadCustomerById => {

                                                const data = responseLoadCustomerById.data

                                                this.customer = data

                                                resolve(responseLoadCustomerById)

                                            }
                                        )
                                        .catch(
                                            error => {
                                                reject(error)
                                            }
                                        )
                                        .finally(
                                            () => {
                                                this.requestingCustomer = false
                                            }
                                        )

                                }
                            )
                            .catch(
                                error => {
                                    reject(error)
                                }
                            )

                    }
                )

            },

            loadCustomerNotes() {

                const customerId = this.customerId

                this.requestingCustomerNotes = true

                this.$fetch.get(`/api/v1/customers/${customerId}/customer_notes`)
                    .then(
                        response => {

                            const data = response.data

                            this.notes = this._.orderBy(data, ['created_at'], ['desc'])

                        }
                    )
                    .catch(
                        error => {

                            this.$eventbus.$emit(
                                'ComponentToast/add',
                                {
                                    autoHide: true,
                                    content: 'Erro ao carregar as anotações',
                                    icon: null,
                                    showCloseButton: false,
                                    type: 'error'
                                }
                            )

                        }
                    )
                    .finally(
                        () => {
                            this.requestingCustomerNotes = false
                        }
                    )

            },

            setAddNoteDraftFromAutosave() {

              const customerId = this.customerId
              const lsAutoSavedBody = localStorage.getItem(`ordexa/notes/autosave/${customerId}/add-note-draft`)

              if(lsAutoSavedBody) {

                  this.addNote.body = lsAutoSavedBody

                  this.addNote.show = true

              }

            },

            //Events

            buttonAddNoteClick(e) {
                this.addNote.show = true
            },

            buttonShowCustomerInfoClick(e) {
                this.customerInfo.show = !this.customerInfo.show
            },

            buttonEditCustomerClick(e) {

                const customerId = this.customerId

                this.$router.push(
                    {
                        path: `/customers/edit/${customerId}`
                    },
                    () => {},
                    () => {}
                )
            },

            componentNoteAddUpdateCancel() {

                this.addNote.body = null

                this.addNote.show = false

            },

            componentNoteAddUpdateNoteSaved() {

                this.addNote.body = null

                this.addNote.show = false

                this.loadCustomerNotes()

            },

            componentNoteItemDuplicateNote(body) {

                let containerNotesTop = null

                this.addNote.body = body

                this.addNote.show = true

                this.$nextTick(
                    () => {

                        containerNotesTop = this.$refs.containerNotes.offsetTop

                        this.$eventbus.$emit('ContainerRouterView/scrollTo', { left: 0, top: containerNotesTop - 60, behavior: 'smooth' })

                    }
                )

            },

            componentNoteItemNoteDeleted() {
                this.loadCustomerNotes()
            },

            componentNoteItemNoteLocked() {
                this.loadCustomerNotes()
            },

            componentNoteItemNoteSaved() {
                this.loadCustomerNotes()
            },

            modalConfirmViewExitNo() {

                this.routeExitTo = null

                this.$refs.modalConfirmViewExit.show = false

            },

            modalConfirmViewExitYes() {

                this.addNote.show = false
                this.notesEditing = []

                this.$router.push({ path: this.routeExitTo.path }, ()=>{}, ()=>{})

            }

        },//methods
        watch: {

            'customerInfo.show': function(value, oldValue) {

                if(value) {

                    if(this.$mq.match(/lg/)) {

                        this.$nextTick(
                            () => {

                                const elViewCustomersDetails = this.$refs.viewCustomersDetails
                                const elViewCustomersDetailsHeight = elViewCustomersDetails.offsetHeight

                                const elContainerCustomerInfo = this.$refs.containerCustomerInfo

                                elContainerCustomerInfo.style.maxHeight = (elViewCustomersDetailsHeight * 0.6) + 'px'

                            }
                        )

                    }

                }

            },

            requestingCustomerNotes(value, oldValue) {

                if(!value) {

                    if(this.$mq.match(/lg/)) {

                        this.$nextTick(
                            () => {

                                const elContainerCustomerHeader = this.$refs.containerCustomerHeader

                                const elViewCustomersDetails = this.$refs.viewCustomersDetails
                                const elViewCustomersDetailsHeight = elViewCustomersDetails.offsetHeight

                                const elContainerNotes = this.$refs.containerNotes

                                if(elContainerNotes) {

                                  const elContainerNotesWidth = elContainerNotes.offsetWidth

                                  elContainerCustomerHeader.style.width = elContainerNotesWidth + 'px'

                                  elContainerCustomerHeader.classList.add('customer-header-fixed')
                                  elContainerNotes.classList.add('customer-header-fixed')

                                }

                            }
                        )

                    }

                }

            }

        },//watch
        created() {},//created
        mounted() {

            this.loadCustomer()
                .then(
                    response => {

                        this.loadCustomerNotes()

                        this.setAddNoteDraftFromAutosave()

                    }
                )
                .catch(
                    error => {
                        this.$router.replace( { name: 'Customers' }, () => {},  () => {} )
                    }
                )

        },//mounted
        updated() {},//updated
        destroyed() {},//destroyed
        beforeRouteEnter(to, from, next) {
            next(
                vm => {
                }
            )
        },//beforeRouteEnter
        beforeRouteUpdate(to, from, next) {
            next()
        },//beforeRouteUpdate
        beforeRouteLeave(to, from, next) {

            if(this.isEditing) {

                this.$refs.modalConfirmViewExit.show = true

                this.routeExitTo = to

                next(false)

            } else {
                next()
            }

        }//beforeRouteLeave

    }
</script>

<style scoped>

    #view-customers-details
    {
        margin-bottom: 2em;
    }

    h2
    {
        font-size: 1.25em;
        font-weight: 300;
        margin: 0;
        margin-bottom: 1em;
    }

    .container-view-content
    {
        overflow: auto;
        margin: 0 auto 2em;
    }

    .container-view-header
    {
        margin-bottom: 1.5em;
        margin-top: 0.75em;
    }

    .container-view-header .link-back
    {

    }

    .container-view-header .link-back img
    {
        height: 0.75em;
        margin-right: 0.5em;
    }

    .container-customer-header
    {
        background-color: #fff;
        border-radius: 5px;
        box-sizing: border-box;
        margin-bottom: 1em;
        padding: 1.25rem 1rem;
    }

    .container-customer-header .row-name-and-button
    {
        display: flex;
    }

    .container-customer-header .row-name-and-button .col-left
    {
        flex: 3;
        display: flex;
        align-items: center;
    }

    .container-customer-header .row-name-and-button .col-right
    {
        flex: 2;
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }

    .container-customer-header .user-picture
    {
        background-position: center;
        background-size: cover;
        border-radius: 100px;
        display: inline-block;
        height: 3.2em;
        margin-right: 0.65em;
        width: 3.2em;
    }

    .container-customer-header .customer-name
    {
        display: inline-block;
        font-weight: 300;
    }

    .container-customer-header button
    {
        display: inline-flex;
        align-items: center;

        border: 2px solid transparent;
        font-size: 0.85em;
        min-height: 2.5em;
        padding: 0.65em 1.5em;
    }

    .container-customer-header .button-edit
    {
        border-color: #29B1CC;
        color: #29B1CC;
        background-color: transparent;
        margin-right: 1em;
    }

    .container-customer-header .button-show-customer-info
    {

    }

    .container-customer-header .button-show-customer-info img
    {
        height: 0.65em;
        padding: 0;
        vertical-align: middle;

        transition: all 0.2s ease;
    }

    .container-customer-header .button-show-customer-info img.active
    {
        transform: rotate(180deg);
    }

    .container-customer-info
    {
        overflow: auto;
        padding: 1em;
    }

    .container-item-question
    {
        margin-bottom: 1.5em;
    }

    .container-item-question:last-child
    {
        margin-bottom: 0;
    }

    .container-item-question > :first-child
    {
        color: #119ab2;
        font-size: 0.95em;
        font-weight: 400;
        margin-bottom: 0.1em;
    }

    .container-item-question > :last-child
    {
        font-size: 1.1em;
    }

    .container-item-question .temporal-distance
    {
        margin-left: 0.5em;
        opacity: 0.5;
    }

    .container-notes
    {
        background-color: #fff;
        border-radius: 5px;
        box-sizing: border-box;
    }

    .container-title
    {
        display: flex;
        align-items: center;

        background-color: #4f4f4f;
        border-radius: 5px 5px 0 0;
        color: #fff;
    }

    .container-title img
    {
        margin-right: 0.5em;
        vertical-align: bottom;
    }

    .container-title > div
    {
        flex: 1;

        padding: 1rem;
    }

    .container-title .col-left
    {
        font-size: 1.15em;
    }

    .container-title .col-right
    {
        text-align: right;
    }

    .button-add-note
    {
        display: inline-flex;
        align-items: center;
        justify-content: center;

        font-size: 0.95em;
        padding: 0.6em 1.2em;
    }

    .button-add-note img
    {

    }

    .container-component-note-add-update
    {
        background-color: #d7f2f7;
        padding: 0.5em 1.25rem;
    }

    .container-component-note-add-update >>> .container-buttons-top > div
    {
        padding-left: 0;
        padding-right: 0;
    }

    .container-component-note-add-update >>> .container-buttons-bottom
    {
        padding: 1em 0;
    }

    .container-items
    {
        padding: 1.25rem;
    }

    .container-added-notes .container-no-items
    {
        font-size: 1.25em;
        letter-spacing: -0.015em;
        opacity: 0.75;
        padding: 2em;
        text-align: center;
    }

    .container-no-access-to-notes
    {
      font-size: 1.25em;
      font-weight: 200;
      opacity: 0.75;
      padding: 2em;
      text-align: center;
    }

    .component-modal-confirm-view-exit >>> .container-dialog
    {
        max-width: 480px;
    }

    @media (max-width: 767px) {

        .component-modal-confirm-view-exit >>> .container-dialog
        {
            max-width: 86%;
        }

    }

    @media (min-width: 768px) and (max-width: 1199px) {

    }

    @media (max-width: 1199px) {

        .container-customer-header .button-edit img
        {
            margin-right: 0;
        }

        .container-customer-header .button-show-customer-info img
        {
            margin-right: 0;
        }

        .container-customer-header .user-picture
        {
            height: 1.8em;
            width: 1.8em;
        }

        .container-customer-header .customer-name
        {
            font-size: 1.1em;
        }

        .button-add-note
        {
            height: 2.4em;
            width: 2.4em;
        }

        .button-add-note img
        {
            margin-right: 0;
        }

        .container-requesting-notes .component-loading >>> span
        {
            width: min-content;
        }

    }

    @media (min-width: 1200px) {

        #view-customers-details
        {
            position: relative;
        }

        .container-view-content
        {
            width: 100%;
        }

        .container-customer-header.customer-header-fixed
        {
            box-shadow: 0 2px 9px 1px rgba(0, 0, 0, 0.2);
            position: fixed;
            width: inherit;
            z-index: 10;
        }

        .container-customer-header .button-show-customer-info img
        {
            margin-right: 0.5em;
        }

        .container-customer-header .user-picture
        {
            height: 3.2em;
            width: 3.2em;
        }

        .container-customer-header .customer-name
        {
            font-size: 1.4em;
        }

        .container-notes.customer-header-fixed
        {
            margin-top: 7em;
        }

        .button-add-note img
        {
            margin-right: 0.5em;
        }

    }
</style>
