<template>
    <div class="component-info-box">
        <div class="container-icon">
            <img src="~@/assets/img/ico/ico_info-white.svg" alt="">
        </div>
        <div class="container-content" v-html="content"></div>
    </div>
</template>

<script>
    export default {
        name: 'ComponentInfoBox',
        props: [
            'content'
        ],
        mixins: [],
        components: {},//components
        data() {
            return {}
        },//data
        computed: {},//computed
        methods: {},//methods
        watch: {},//watch
        created() {},//created
        mounted() {},//mounted
        updated() {},//updated
        destroyed() {},//destroyed
    }
</script>

<style scoped>
    .component-info-box
    {
        display: flex;
        align-items: flex-start;

        background-color: rgba(51, 51, 51, 0.05);
        border-radius: 4px;
        box-sizing: border-box;
        padding: 1em;
    }

    .container-icon
    {
        margin-right: 1em;
    }

    .container-content
    {
        font-size: 0.75em;
        text-align: left;
    }

    @media (max-width: 767px) {

    }

    @media (min-width: 768px) {

    }

    @media (min-width: 992px) {

    }

    @media (max-width: 1199px) {

    }

    @media (min-width: 1200px) {

    }
</style>
