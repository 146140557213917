<template>
    <section id="layout-login">
        <div class="container-view">
            <transition name="fade" mode="out-in">
                <router-view></router-view>
            </transition>
        </div>
    </section>
</template>

<script>
    import MixinLayout from '@/mixins/MixinLayout.js'

    export default {
        name: 'LayoutLogin',
        props: [],
        mixins: [MixinLayout],
        components: {},//components
        data() {
            return {}
        },//data
        computed: {},//computed
        methods: {},//methods
        watch: {},//watch
        created() {},//created
        mounted() {},//mounted
        updated() {},//updated
        destroyed() {},//destroyed
        beforeRouteEnter(to, from, next) {
            next(
                vm => {
                }
            )
        },//beforeRouteEnter
        beforeRouteUpdate(to, from, next) {
            next()
        },//beforeRouteUpdate
        beforeRouteLeave(to, from, next) {
            next()
        }//beforeRouteLeave

    }
</script>

<style scoped>
    #layout-login
    {
        display: flex;

        background-size: cover;
        background-image: linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%), url(~@/assets/img/bg_login.jpg);
    }

    .container-view
    {
        display: flex;
        justify-content: center;

        background-color: #fff;
        box-sizing: border-box;
    }

    @media (max-width: 767px) {

    }

    @media (min-width: 768px) and (max-width: 1199px) {

    }

    @media (max-width: 1199px) {

        #layout-login
        {
            align-items: center;
            justify-content: center;

            background-position: center;
        }

        .container-view
        {
            align-items: flex-start;

            border-radius: 5px;
            max-width: 270px;
            padding: 2em;
        }

    }

    @media (min-width: 1200px) {

        #layout-login
        {
            align-items: stretch;

            background-position: 28vw;
        }

        .container-view
        {
            align-items: center;

            width: 50%;
        }

    }
</style>
