<template>
    <section id="view-business-main">

        <ComponentViewTabs class="component-view-tabs" :tabs="viewTabs"></ComponentViewTabs>

        <template v-if="requesting">
            <ComponentLoading></ComponentLoading>
        </template>
        <div class="container-view-content" v-show="!requesting">
            <h1>Editar informações gerais</h1>
            <ComponentBusinessProfile
                :company="company"
                :companyTreatment="company.company_treatment"
                :customerTreatment="company.customer_treatment"
                :key="JSON.stringify(company)"
                :pictureFileUrl="company.image_url"
                ref="componentBusinessProfile"
                @requestingDone="componentBusinessProfileRequestingDone"
                @requestingUpdate="componentBusinessProfileRequestingUpdate"
                @updated="componentBusinessProfileUpdated"
                v-if="company"/>
            <div class="container-buttons">
                <button class="btn-default btn-primary" @click="buttonSaveClick">Salvar</button>
            </div>
        </div>

    </section>
</template>

<script>
    import MixinView from '@/mixins/MixinView.js'
    import MixinBusiness from '@/mixins/Business/MixinBusiness'

    import ComponentBusinessProfile from "@/components/ComponentBusinessProfile"
    import ComponentLoading from "@/components/ui/ComponentLoading"

    export default {
        name: 'BusinessMain',
        props: [],
        mixins: [MixinView, MixinBusiness],
        components: {
            ComponentBusinessProfile,
            ComponentLoading
        },//components
        data() {
            return {

                company: null,

                requesting: false

            }
        },//data
        computed: {

            user() {
                return this.$store.getters['App/user']
            }

        },//computed
        methods: {

            loadCompany() {

                this.requesting = true

                this.$store.dispatch('Companies/loadCompany')
                    .then(
                        responseCompany => {
                            this.company = responseCompany.data
                        }
                    )
                    .catch(
                        error => {}
                    )
                    .finally(
                        () => {
                            this.requesting = false
                        }
                    )

            },

            //Events

            buttonSaveClick(e) {

                const isValid = this.$helpers.validateFields(this.$refs.componentBusinessProfile.$children)

                if(isValid) {

                    this.$refs.componentBusinessProfile.updateCompany()

                } else {

                    this.$eventbus.$emit(
                        'ComponentToast/add',
                        {
                            autoHide: true,
                            content: 'Preencha corretamente todos os campos para prosseguir (verifique os obrigatórios)',
                            icon: null,
                            showCloseButton: false,
                            type: 'error'
                        }
                    )

                }

            },

            componentBusinessProfileRequestingDone() {
                this.loadCompany()
            },

            componentBusinessProfileRequestingUpdate() {
                this.requesting = true
            },

            componentBusinessProfileUpdated() {

            }

        },//methods
        watch: {},//watch
        created() {},//created
        mounted() {
            this.loadCompany()
        },//mounted
        updated() {},//updated
        destroyed() {},//destroyed
        beforeRouteEnter(to, from, next) {
            next(
                vm => {
                }
            )
        },//beforeRouteEnter
        beforeRouteUpdate(to, from, next) {
            next()
        },//beforeRouteUpdate
        beforeRouteLeave(to, from, next) {
            next()
        }//beforeRouteLeave

    }
</script>

<style scoped>
    #view-business-main
    {

    }

    .component-view-tabs
    {
        margin-bottom: 1.5em;
    }

    .container-requesting
    {
        display: flex;
        align-items: center;
        justify-content: center;

        font-size: 1.5em;
        opacity: 0.5;
        padding: 2em 1em;
    }

    .container-requesting img
    {
        height: 1.25em;
        margin-right: 0.5em;
    }

    .container-view-content
    {
        background-color: #fff;
        border-radius: 5px;
        box-sizing: border-box;
        margin: 0 auto 2em;
        padding: 1em;
    }

    .container-view-content h1
    {
        font-size: 1.2em;
        font-weight: 400;
        margin: 0 0 1em;
    }

    .container-buttons
    {
        text-align: right;
    }

    @media (max-width: 767px) {

    }

    @media (min-width: 768px) and (max-width: 1199px) {

    }

    @media (max-width: 1199px) {

        .container-view-content
        {

        }

    }

    @media (min-width: 1200px) {

        .container-view-content
        {
            width: 350px;
        }

    }
</style>
