<template>
    <section id="view-signup">
        <header>
            <img class="logo-ordexa" src="~@/assets/img/logo_ordexa.svg" alt="">
        </header>
        <div class="container-columns">
            <div class="container-form">
                <div class="container-mobile-plan">
                    Plano {{selectedPlan.title}}
                </div>
                <div class="container-title">
                    <h2>Cadastre-se agora</h2>
                    <div>e comece a se organizar em menos de 60 segundos</div>
                </div>
                <div class="container-errors" v-if="this.errors.length > 0">
                    <h3>Verifique os erros:</h3>
                    <ul>
                        <li :key="`error-${index}`" v-for="(error, index) in errors">{{error}}</li>
                    </ul>
                </div>
                <div class="container-fields">
                    <div :class="['container-line', {invalid: !validation.name}]">
                        <label for="">Nome completo</label>
                        <input type="text" v-model="model.name">
                    </div>
                    <div :class="['container-line', {invalid: !validation.email}]">
                        <label for="">E-mail</label>
                        <input type="text" v-model="model.email">
                    </div>
                    <div :class="['container-line', {invalid: !validation.emailConfirmation}]">
                        <label for="">Confirme seu e-mail</label>
                        <input type="text" v-model="model.emailConfirmation">
                    </div>
                    <div class="container-line">
                        <button :disabled="requesting" @click="buttonStartTestClick">
                            <span v-if="!requesting">Iniciar 10 dias de teste grátis</span>
                            <span v-else>
                                <img class="icon-circle-segment-animated" src="~@/assets/img/ico/ico_circle_segment_white.svg" alt="">
                                <span>Aguarde</span>
                            </span>
                        </button>
                    </div>
                </div>
                <hr>
                <div class="container-text-try-now">
                    Fazendo seu cadastro agora você ganha acesso a <b>todas as funcionalidades</b> do Ordexa, não paga <b>NADA nos primeiros 10 dias</b> e pode cancelar a qualquer momento. Experimente já!
                </div>
            </div>
            <div class="container-plan-info" :style="{'background-image': `url(${selectedPlan.iconBg})`}">
                <div class="container-title">
                    <h2>Plano {{selectedPlan.title}}</h2>
                    <img :src="selectedPlan.icon" alt="">
                </div>
                <ul>
                    <li>Até {{selectedPlan.usersLimit}} usuários</li>
                    <li>Cadastros ilimitados no primeiro mês</li>
                    <li>{{selectedPlan.newRegistersLimit}} novos cadastros por mês (2º mês em diante)</li>
                    <li>10 dias de teste grátis</li>
                    <li>Acesse de onde quiser</li>
                    <li>100% online</li>
                    <li>Todas as funcionalidades disponíveis</li>
                </ul>
            </div>
        </div>
    </section>
</template>

<script>
    import MixinAuth from '@/mixins/MixinAuth.js'

    export default {
        name: 'Signup',
        props: [],
        mixins: [MixinAuth],
        components: {},//components
        data() {
            return {

                errors: [],

                model: {
                    name: '',
                    email: '',
                    emailConfirmation: '',
                },

                plans: {
                    lean: {
                        title: 'Lean',
                        usersLimit: '2',
                        newRegistersLimit: '10',
                        icon: '/img/icon_plan_lean_circle_white.svg',
                        iconBg: '/img/icon_plan_lean_bg.svg',
                    },
                    team: {
                        title: 'Team',
                        usersLimit: '5',
                        newRegistersLimit: '30',
                        icon: '/img/icon_plan_team_circle_white.svg',
                        iconBg: '/img/icon_plan_team_bg.svg',
                    },
                },

                requesting: false,

                validation: {
                    name: true,
                    email: true,
                    emailConfirmation: true,
                },



            }
        },//data
        computed: {
            
            selectedPlan() {
                return this.plans[this.queryPlan];
            },

            queryPlan() {
                return this.$route.query.plan;
            },

        },//computed
        methods: {

            checkQsPlanAndRedirect() {

                if(!this.queryPlan || !this.plans[this.queryPlan]) {
                    this.$router.replace(`${this.$route.path}?plan=lean`);
                }

            },

            resetErrors() {
                
                this.errors = [];

                this.validation = {
                    name: true,
                    email: true,
                    emailConfirmation: true,
                };

            },

            signup() {

                const payload = {  
                    checkout_process: {
                        plan_identifier: this.queryPlan,
                        customer_name: this.model.name,
                        customer_email: this.model.email,
                        customer_email_confirmation: this.model.emailConfirmation,
                    },
                    meta: {
                        contentType: 'application/json',
                    }
                };

                this.resetErrors();

                this.requesting = true;

                this.axios.post(
                    '/api/v1/auth/create_account',
                    payload,
                    {
                        headers: {
                            'Content-type': 'application/json'
                        }
                    }
                )
                .then(
                    response => {
                        this.setAuth(response)
                    }
                )
                .catch(
                    error => {
                        
                        const response = error.response;
                        const data = response.data;
                        const errors = data.errors;

                        if(errors.customer_name) {
                            this.validation.name = false;
                        }

                        if(errors.customer_email) {
                            this.validation.email = false;
                            this.validation.emailConfirmation = false;
                        }

                        this.errors = errors.full_messages;

                    }
                )
                .finally(
                    () => {
                        this.requesting = false;                       
                    }
                );

            },

            //Events

            buttonStartTestClick(e) {
                this.signup();
            },

        },//methods
        watch: {

            '$route': function(value, oldValue) {
                this.checkQsPlanAndRedirect();
            },

        },//watch
        created() {},//created
        beforeMount() {
            this.checkQsPlanAndRedirect();
        },//beforeMount
        mounted() {},//mounted
        updated() {},//updated
        destroyed() {},//destroyed
        beforeRouteEnter(to, from, next) {
            next(
                vm => {}
            )
        },//beforeRouteEnter
        beforeRouteUpdate(to, from, next) {
            next()
        },//beforeRouteUpdate
        beforeRouteLeave(to, from, next) {
            next()
        }//beforeRouteLeave

    }
</script>

<style scoped>
    #view-signup
    {
        display: flex;
        flex-direction: column;
    }

    h2
    {
        font-size: 1.75em;
        font-weight: 600;
        margin: 0;
    }

    header
    {
        background-color: #efefef;
        padding: 2em;
    }

    header img
    {
        height: 1.75em;
    }
 
    .container-columns
    {
        
    }
 
    .container-columns > div
    {
        flex: 1;

        padding: 2em 2.25em;
    }

    .container-title
    {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
    }
    
    .container-form .container-title
    {
        display: block;
    }

    .container-form .container-title h2
    {
        color: #444;
    }

    .container-form .container-errors
    {
        background-color: #F8D7D9;
        border-radius: 6px;
        color: #842029;
        margin-top: 1.35em;
        padding: 1em;
    }

    .container-form .container-errors h3
    {
        font-size: 1rem;
        margin: 0;
    }

    .container-form .container-line
    {
        margin-top: 1.25em;
    }

    .container-form .container-line label
    {
        display: block;
        font-weight: 400;
        margin-bottom: 0.25em;
    }

    .container-form .container-line.invalid label
    {
        color: #D73641;
    }

    .container-form .container-line input
    {
        border: 1px solid #d0d0d0;
        border-radius: 6px;
        box-sizing: border-box;
        font-size: 1.25em;
        padding: 0.5em;
        width: 100%;
    }

    .container-form .container-line.invalid input
    {
        border-color: #D73641;
    }

    .container-form .container-line button
    {
        background-color: #40AD54;
        border: none;
        border-radius: 6px;
        color: #fff;
        font-weight: 500;
        padding: 1em;
        width: 100%;
    }

    .container-form .container-text-try-now
    {
        color: #444;
        font-size: 0.9em;
    }

    .container-plan-info
    {
        background-color: #27B1CC;
        background-repeat: no-repeat;
    }

    .container-plan-info .container-title h2
    {
        color: #fff;
    }

    .container-plan-info ul
    {
        color: #fff;
        font-size: 1.25em;
        font-weight: 400;
        line-height: 135%;
        margin: 0;
        margin-left: 1em;
        padding: 0;
    }

    .container-plan-info ul li
    {
        margin-bottom: 1em;
    }

    @media (max-width: 767px) {

    }

    @media (min-width: 768px) and (max-width: 1199px) {

    }

    @media (max-width: 1199px) {

        .container-form .container-mobile-plan
        {
            border: 2px solid #27B1CC;
            border-radius: 6px;
            color: #27B1CC;
            font-size: 1.1em;
            margin-bottom: 1em;
            padding: 0.5em;
        }

        .container-form .container-line button
        {
            font-size: 1.05em;
            padding-bottom: 1.25em;
            padding-top: 1.25em;
        }

        .container-plan-info
        {
            background-position: bottom center;
            background-size: contain;
        }

        .container-plan-info .container-title img
        {
            height: 4em;
        }

    }

    @media (min-width: 1200px) {
 
        .container-columns
        {
            flex: 1;
            display: flex;
        }

        .container-form .container-mobile-plan
        {
            display: none;
        }

        .container-form .container-line button
        {
            font-size: 1.25em;
        }

        .container-plan-info
        {
            background-position: bottom right;
        }

    }
</style>
