<template>
    <div :class="['component-form-fill-item', { invalid: !isValid && saveCount > 0 }]">
        <div class="container-question-text">
            <span>{{ index + 2 }}. </span>
            <span>{{ item.question }}</span>
            <span class="required-mark" v-if="item.mandatory">*</span>
            <span class="invalid-mark" v-if="!isValid && saveCount > 0">
                <img src="~@/assets/img/ico/ico_exclamation_triangle_red.svg" alt="">
            </span>
        </div>
        <div class="container-question-controls">

            <template v-if="answerType === 'short_text'">
                <ComponentInputText class="full-width" placeholder="resposta" type="text" v-model="model"></ComponentInputText>
            </template>
            <template v-else-if="answerType === 'text'">
                <ComponentInputText class="full-width" placeholder="resposta" type="textarea" v-model="model"></ComponentInputText>
            </template>
            <template v-else-if="answerType === 'multiple_choice'">
                <ComponentRadioGroup :items="fieldOptions" :value="valueWithoutOptionOther" @change="componentRadioGroupChange"></ComponentRadioGroup>
                <ComponentInputText class="component-input-other" :placeholder="fieldOptions[fieldOptions.length-1] + ' - especifique'" type="text" v-model="optionOther" v-if="isOptionOtherSelected"></ComponentInputText>
            </template>
            <template v-else-if="answerType === 'multiple_response'">
                <ComponentCheckboxGroup :items="fieldOptions" :value="valueWithoutOptionOther" @change="componentCheckboxGroupChange"></ComponentCheckboxGroup>
                <ComponentInputText class="component-input-other" :placeholder="fieldOptions[fieldOptions.length-1] + ' - especifique'" type="text" v-model="optionOther" v-if="isOptionOtherSelected"></ComponentInputText>
            </template>
            <template v-else-if="answerType === 'selectbox'">
                <ComponentDropdown class="dropdown" :items="fieldOptions" :selectedIndex="0" @change="dropdownQuestionTypeChange"></ComponentDropdown>
            </template>
            <template v-else-if="answerType === 'rg'">
                <ComponentInputText class="component-input-rg" placeholder="rg" type="text" v-model="model"></ComponentInputText>
            </template>
            <template v-else-if="answerType === 'cpf'">
                <ComponentInputText class="component-input-cpf" :maskPattern="['###.###.###-##']" placeholder="000.000.000-00" ref="componentInputCpf" type="text" v-model="model"></ComponentInputText>
            </template>
            <template v-else-if="answerType === 'date'">
                <span class="container-date-control">
                    <ComponentInputText class="component-input-date" :maskPattern="['##/##/####']" placeholder="dd/mm/aaaa" ref="componentInputDate" type="text" v-model="model"></ComponentInputText>
                    <date-pick
                        :editable="true"
                        format="DD/MM/YYYY"
                        :months="datePick.months"
                        :nextMonthCaption="datePick.nextMonthCaption"
                        :prevMonthCaption="datePick.prevMonthCaption"
                        :setTimeCaption="datePick.setTimeCaption"
                        :weekdays="datePick.weekdays"
                        v-model="model">
                        <template v-slot:default="{toggle, inputValue}">
                            <button type="button" @click="toggle">
                                <img src="@/assets/img/ico/ico_type_date.svg" alt="">
                            </button>
                        </template>
                    </date-pick>
                </span>
            </template>
            <template v-else-if="answerType === 'time'">
                <ComponentInputText class="component-input-time" :maskPattern="['##:##']" placeholder="00:00" ref="componentInputTime" type="text" v-model="model"></ComponentInputText>
            </template>
            <template v-else-if="answerType === 'phone'">
                <ComponentInputText class="component-input-phone" :maskPattern="['(##) ####-####', '(##) #####-####']" placeholder="(00) 00000-0000" ref="componentInputPhone" type="text" v-model="model"></ComponentInputText>
            </template>
        </div>
    </div>
</template>

<script>
    import DatePick from 'vue-date-pick';

    import ComponentCheckboxGroup from "@/components/ui/ComponentCheckboxGroup"
    import ComponentDropdown from "@/components/ui/ComponentDropdown"
    import ComponentInputText from "@/components/ui/ComponentInputText"
    import ComponentRadioGroup from "@/components/ui/ComponentRadioGroup"

    export default {
        name: 'ComponentFormFillItem',
        props: [
            'id',
            'index',
            'item',
            'saveCount',
            'value'
        ],
        mixins: [],
        components: {
            ComponentCheckboxGroup,
            ComponentDropdown,
            ComponentInputText,
            ComponentRadioGroup,
            DatePick
        },//components
        data() {
            return {

                datePick: {
                    nextMonthCaption: 'Próximo',
                    prevMonthCaption: 'Anterior',
                    setTimeCaption: 'Definir hora:',
                    weekdays: ['Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb', 'Dom'],
                    months: [
                        'Janeiro',
                        'Fevereiro',
                        'Março',
                        'Abril',
                        'Maio',
                        'Junho',
                        'Julho',
                        'Agosto',
                        'Setembro',
                        'Outubro',
                        'Novembro',
                        'Dezembro'
                    ]
                },

                model: null,

                optionOther: null,

                optionOtherRegex: /^[Oo]utr(?:o|a|os|as|os\(as\))/

            }
        },//data
        computed: {

            answerType() {
                return this.item.answer_type
            },

            fieldOptions() {
                return this.item.options
            },

            isMandatory() {
                return this.item.mandatory
            },

            isOptionOtherSelected() {

                const optionOtherRegex = this.optionOtherRegex

                let ret = false

                if(this.model) {

                    if(typeof this.model === 'string') {

                        if(this.model.match(optionOtherRegex)) {
                            ret = true
                        }

                    }
                    else if(this.$moment.isDate(this.model)) {
                        ret = false
                    }
                    else {

                        const filterResult = this.model.filter(
                            item => {
                                return item.match(optionOtherRegex)
                            }
                        )

                        if(filterResult.length > 0) {
                            ret = true
                        }

                    }

                }

                return ret

            },

            isValid() {

                let ret = true

                if(this.isMandatory && !this.model) {
                    return false
                }

                if(this.model) {

                    if(this.answerType === 'cpf') {

                        if(!this.model.match(/^[0-9]{3}.[0-9]{3}.[0-9]{3}-[0-9]{2}$/)) {
                            ret = false
                        }

                    }

                    if(this.answerType === 'time') {

                        if(!this.model.match(/^[0-2]{1}[0-9]{1}:[0-6]{1}[0-9]{1}$/)) {
                            ret = false
                        } else {

                            const splitModel = this.model.split(':')

                            if(parseInt(splitModel[0]) > 23) {
                                ret = false
                            }

                            if(parseInt(splitModel[1]) > 59) {
                                ret = false
                            }

                        }

                    }

                }

                return ret

            },

            valueWithoutOptionOther() {

                let ret = null
                let value = this._.cloneDeep(this.value)

                if(value) {

                    if(typeof value === 'string') {
                        ret = this.getOptionOtherWithoutText(value)
                    } else {

                        ret = value.map(
                            (item, index, array) => {
                                return this.getOptionOtherWithoutText(item)
                            }
                        )

                    }

                }

                return ret

            }

        },//computed
        methods: {

            getOptionOtherWithoutText(str) {
                return str.replace(/\{.*?\}/, '').trim()
            },

            getModelWithOptionOther(model, optionOther) {

                let ret = model

                if(model) {

                    if(typeof model === 'string') {

                        if (this.isOptionOtherSelected) {
                            ret = model + ` {${optionOther}}`
                        }

                    }
                    else if(this.$moment.isDate(model)) {
                        ret = this.$moment(model).startOf('day').format('YYYY-MM-DD')
                    } else {

                        let otherOptionIndex = null

                        model.map(
                            (item, index, array) => {
                                if(item.match(this.optionOtherRegex)) {
                                    otherOptionIndex = index
                                }
                            }
                        )

                        if (this.isOptionOtherSelected) {
                            model[otherOptionIndex] = model[otherOptionIndex].replace(/ \{.*?\}/, '') + ' {' + optionOther + '}'
                        }

                        ret = model

                    }

                }

                return ret

            },

            setInitialModel() {

                if(this.value) {

                    if(typeof this.value === 'string') {

                        if(this.answerType === 'date') {
                            this.model = this.$moment(this.value).format('DD/MM/YYYY')
                        } else {
                            this.model = this.value
                        }


                    }

                }

            },

            setOptionOther() {

                const optionOtherRegex = this.optionOtherRegex
                let value = this._.cloneDeep(this.value)

                if(this.value) {

                    if(Array.isArray(value)) {

                        value.map(
                            (item, index, array) => {

                                if(item.match(optionOtherRegex)) {

                                    this.optionOther = item.match(/\{.*?\}/)[0].replace(/\{/, '').replace(/\}/, '')

                                }

                            }
                        )

                    } else {

                        if(value.match(optionOtherRegex)) {
                            this.optionOther = value.match(/\{.*?\}/)[0].replace(/\{/, '').replace(/\}/, '')
                        }

                    }

                }

            },

            //Events

            dropdownQuestionTypeChange(item) {
                this.model = item.value
            },

            componentCheckboxGroupChange(item) {

                this.model = item.map(
                    option => {

                        if(option.label) {
                            return option.label
                        } else {
                            return option
                        }

                    }
                )

            },

            componentRadioGroupChange(item) {
                this.model = item.value
            }

        },//methods
        watch: {

            model(value, oldValue) {

                let model = this._.cloneDeep(value)
                let optionOther = this.optionOther

                if(this.answerType === 'date') {
                    model = this.$moment(model, 'DD/MM/YYYY').format('YYYY-MM-DD')
                }

                this.$emit(
                    'modelChange',
                    {
                        id: this.id,
                        value: this.getModelWithOptionOther(model, optionOther)
                    }
                )

            },

            optionOther(value, oldValue) {

                let model = this._.cloneDeep(this.model)
                let optionOther = value

                this.$emit(
                    'modelChange',
                    {
                        id: this.id,
                        value: this.getModelWithOptionOther(model, optionOther)
                    }
                )

            },

        },//watch
        created() {},//created
        mounted() {

            this.setInitialModel()

            this.setOptionOther()

            this.$emit(
                'modelChange',
                {
                    id: this.id,
                    value: this.model
                }
            )

        },//mounted
        updated() {},//updated
        destroyed() {},//destroyed
    }
</script>

<style lang="scss">
    $vdpColor: #29B1CC;
    @import 'vue-date-pick/src/vueDatePick.scss';
</style>
<style scoped>
    .component-form-fill-item
    {
        margin-bottom: 1.5em;
    }

    .container-question-text
    {
        color: #2794af;
        font-size: 1.15em;
        font-weight: 400;
        margin-bottom: 0.5em;

        transition: 0.15s all;
    }

    .component-form-fill-item.invalid .container-question-text
    {
        color: #e00;
    }

    .container-question-controls
    {

    }

    .container-question-controls .component-input-text.full-width
    {
        width: 100%;
    }

    .container-date-control
    {
        display: inline-flex;
        align-items: center;
    }

    .container-date-control .component-input-date
    {
        width: 9em;
    }

    .container-date-control >>> .vdpComponent > button
    {
        background-color: #f0f0f0;
        border: none;
        border-radius: 100px;
        margin-left: 0.5em;
        padding: 0.75em;
    }

    .component-input-rg
    {
        width: 12em;
    }

    .component-input-cpf
    {
        width: 12em;
    }

    .component-input-time
    {
        width: 5em;
    }

    .component-input-phone
    {
        width: 10em;
    }

    .invalid-mark
    {
        display: inline-flex;
        align-items: center;
        justify-content: center;

        font-weight: bold;
        margin-left: 0.25em;
        vertical-align: middle;

        animation-duration: 2s;
        animation-direction: alternate;
        animation-iteration-count: infinite;
        animation-name: invalidMark;
    }

    .invalid-mark img
    {
        height: 1.05em;
    }

    @keyframes invalidMark {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }

    @media (max-width: 767px) {

    }

    @media (min-width: 768px) {

    }

    @media (min-width: 992px) {

    }

    @media (max-width: 1199px) {

        .dropdown
        {
            width: 100%;
        }

        .component-input-other
        {
            width: 100%;
        }

    }

    @media (min-width: 1200px) {

        .dropdown
        {
            width: 30em;
        }

        .component-input-other
        {
            width: 30em;
        }

    }
</style>
