<template>
    <div class="component-note-add-update">

        <div class="container-fields">
            <div class="container-buttons-top">
                <div class="col-left">
                    <template v-if="$mq.match(/lg/)">
                        <span class="text-message-is-editing" v-if="noteId">Você está editando esta anotação</span>
                    </template>
                    <template v-else>
                        <span class="text-message-is-editing" v-if="noteId">Editando</span>
                    </template>
                </div>
                <div class="col-right">
                    <span class="container-import-model">
                        <template v-if="requestingNoteTemplates">
                            <span class="text-loading-templates">Carregando modelos</span>
                        </template>
                        <template v-else>
                            <label v-if="$mq.match(/xs|sm|md/)">Importar:</label>
                            <label v-if="$mq.match(/lg/)">Importar modelo:</label>
                            <v-select class="v-select-import-template" :options="dropdownImportTemplate.items" @input="vSelectImportTemplateInput"></v-select>
                        </template>
                    </span>
                </div>
            </div>
            <div class="container-editor">
                <ComponentEditor v-model="editor.model" />
            </div>
            <div class="container-buttons-bottom">
                <div class="col-left">
                    <button type="button" class="btn-default btn-primary" :disabled="requestingSaveNote" @click="buttonSaveClick">
                        <span>Salvar</span>
                    </button>
                    <button type="button" class="btn-default btn-terciary" :disabled="requestingSaveNote" @click="buttonCancelClick">
                        <span v-if="noteId">Cancelar edição</span>
                        <span v-else>{{buttonAddNoteCancelLabel}}</span>
                    </button>
                </div>
                <div class="col-right">
                    <button type="button" class="button-save-note-as-model" title="Salvar esta anotação como modelo" @click="buttonSaveTemplateClick">
                        <img src="~@/assets/img/ico/ico_new_model.svg" alt="">
                        <span v-if="$mq.match(/lg/)">Salvar esta anotação como modelo</span>
                    </button>
                </div>
            </div>
        </div>

        <ComponentModal class="component-modal-save-template" ref="modalSaveTemplate">
            <template v-slot:title>
                <span>Salvar modelo</span>
            </template>
            <template v-slot:content>
                <ComponentLoading v-if="requestingNoteTemplates"></ComponentLoading>
                <template v-else>
                    <div class="container-text-where-to-find-template">Todos os modelos de anotação salvos ficarão armazenados. Para acessá-los e editá-los vá em <b>Modelos &rarr; Modelos de anotação</b></div>
                    <div class="container-group-input">
                        <label>Nome do modelo<span class="required-mark">*</span></label>
                        <ComponentInputText placeholder="nome" ref="inputNameSaveTemplate" type="text" :validation="{ required: true }" @keyupEnter="modalSaveTemplateInputNameKeyupEnter" v-model="modalSaveTemplate.model.name"></ComponentInputText>
                    </div>
                </template>
            </template>
            <template v-slot:footer>
                <button type="button" class="btn-default btn-terciary" @click="modalSaveTemplateButtonCancelClick">Cancelar</button>
                <button type="button" class="btn-default btn-primary" :disabled="requestingNoteTemplates || requestingSaveTemplate" @click="modalSaveTemplateButtonSaveClick">Salvar</button>
            </template>
        </ComponentModal>

    </div>
</template>

<script>
    import ComponentDropdown from "@/components/ui/ComponentDropdown"
    import ComponentEditor from "@/components/Editor/ComponentEditor"
    import ComponentInputText from "@/components/ui/ComponentInputText"
    import ComponentLoading from "@/components/ui/ComponentLoading"
    import ComponentModal from "@/components/ui/ComponentModal"

    export default {
        name: 'ComponentNoteUpdate',
        props: [
            'body',
            'customerId',
            'noteId'
        ],
        mixins: [],
        components: {
            ComponentDropdown,
            ComponentEditor,
            ComponentInputText,
            ComponentLoading,
            ComponentModal,
        },//components
        data() {
            return {

                buttonAddNoteCancelLabel: 'Cancelar edição',

                dropdownImportTemplate: {
                    items: []
                },

                editor: {
                    model: null
                },

                modalSaveTemplate: {
                  model: {
                    name: null
                  }
                },

                noteTemplates: null,

                requestingNoteTemplates: false,

                requestingSaveTemplate: false,

                requestingSaveNote: false,

                siAutoSave: null,

            }
        },//data
        computed: {},//computed
        methods: {

            autoSaveHandler() {

              const body = this.editor.model
              const customerId = this.customerId
              const lsAutoSavedBody = localStorage.getItem(`ordexa/notes/autosave/${customerId}/add-note-draft`)
              const noteId = this.noteId

              if(!noteId) {

                  if(body !== '') {

                      if(body !== lsAutoSavedBody) {
                          localStorage.setItem(`ordexa/notes/autosave/${customerId}/add-note-draft`, body)
                      }

                  } else {
                      localStorage.removeItem(`ordexa/notes/autosave/${customerId}/add-note-draft`, body)
                  }

                  if(lsAutoSavedBody) {
                      this.buttonAddNoteCancelLabel = 'Descartar'
                  } else {
                      this.buttonAddNoteCancelLabel = 'Cancelar'
                  }

              }

            },

            loadNoteTemplates() {

                this.noteTemplates = null

                this.requestingNoteTemplates = true

                this.$fetch.get(`/api/v1/note_templates`)
                    .then(
                        response => {

                            const data = response.data

                            this.noteTemplates = data

                        }
                    )
                    .catch(
                        error => {

                            this.$eventbus.$emit(
                                'ComponentToast/add',
                                {
                                    autoHide: true,
                                    content: 'Erro ao carregar os modelos',
                                    icon: null,
                                    showCloseButton: false,
                                    type: 'error'
                                }
                            )

                        }
                    )
                    .finally(
                        () => {
                            this.requestingNoteTemplates = false
                        }
                    )

            },

            modalSaveTemplateSave() {

                const arrNoteTemplatesNames = this.noteTemplates.map( item => item.name.toLowerCase() )
                const isValid = this.$helpers.validateFields([this.$refs.inputNameSaveTemplate])

                if(arrNoteTemplatesNames.indexOf(this.modalSaveTemplate.model.name.toLowerCase()) >= 0) {

                    this.$eventbus.$emit(
                        'ComponentToast/add',
                        {
                            autoHide: true,
                            content: 'Já existe um modelo com o nome informado, escolha outro nome',
                            icon: null,
                            showCloseButton: false,
                            type: 'error'
                        }
                    )

                    return false

                }

                if(isValid) {

                    this.requestingSaveTemplate = true

                    this.axios.post(
                        'api/v1/note_templates',
                        {
                            'note_template[name]': this.modalSaveTemplate.model.name,
                            'note_template[body]': this.editor.model
                        }
                    )
                        .then(
                            response => {

                                this.modalSaveTemplate.model.name = null

                                this.$refs.modalSaveTemplate.show = false

                                this.$eventbus.$emit(
                                    'ComponentToast/add',
                                    {
                                        autoHide: true,
                                        content: `Modelo salvo com sucesso`,
                                        icon: null,
                                        showCloseButton: false,
                                        type: 'success'
                                    }
                                )

                                this.loadNoteTemplates()

                            }
                        )
                        .catch(
                            error => {

                                this.$eventbus.$emit(
                                    'ComponentToast/add',
                                    {
                                        autoHide: true,
                                        content: 'Erro ao salvar modelo de anotação',
                                        icon: null,
                                        showCloseButton: false,
                                        type: 'error'
                                    }
                                )

                            }
                        )
                        .finally(
                            () => {
                                this.requestingSaveTemplate = false
                            }
                        )

                } else {

                    this.$eventbus.$emit(
                        'ComponentToast/add',
                        {
                            autoHide: true,
                            content: 'Preencha o nome do modelo',
                            icon: null,
                            showCloseButton: false,
                            type: 'error'
                        }
                    )

                }

            },

            saveNote() {

                const customerId = this.customerId
                const editorContent = this.editor.model
                const noteId = this.noteId

                let requestConfig = {}

                clearInterval(this.siAutoSave)

                requestConfig.data = {
                    'customer_note[body]': editorContent,
                }

                if(noteId) {

                    requestConfig.method = 'patch'
                    requestConfig.url = `/api/v1/customers/${customerId}/customer_notes/${noteId}`

                } else {

                    requestConfig.method = 'post'
                    requestConfig.url = `/api/v1/customers/${customerId}/customer_notes`

                }

                this.requestingSaveNote = true

                this.axios
                    .request(requestConfig)
                    .then(
                        response => {

                            this.$eventbus.$emit(
                                'ComponentToast/add',
                                {
                                    autoHide: true,
                                    content: `Anotação criada/alterada com sucesso`,
                                    icon: null,
                                    showCloseButton: false,
                                    type: 'success'
                                }
                            )
                            this.editor.model = null

                            this.$emit('noteSaved')

                            this.$eventbus.$emit('ContainerRouterView/scrollTo', { left: 0, top: 0 })

                        }
                    )
                    .catch(
                        error => {

                            this.$eventbus.$emit(
                                'ComponentToast/add',
                                {
                                    autoHide: true,
                                    content: 'Erro ao salvar anotação',
                                    icon: null,
                                    showCloseButton: false,
                                    type: 'error'
                                }
                            )

                        }
                    )
                    .finally(
                        () => {
                            this.requestingSaveNote = false
                        }
                    )

            },

            startAutoSave() {

              const autoSaveInterval = 2000

              this.autoSaveHandler()

              this.siAutoSave = setInterval(
                  this.autoSaveHandler,
                  autoSaveInterval
              )

            },

            //Events

            buttonSaveClick(e) {

                const customerId = this.customerId
                const noteId = this.noteId

                if(!noteId) {
                  localStorage.removeItem(`ordexa/notes/autosave/${customerId}/add-note-draft`)
                }

                this.saveNote()

            },

            buttonCancelClick(e) {

                const customerId = this.customerId
                const noteId = this.noteId

                if(!noteId) {
                  localStorage.removeItem(`ordexa/notes/autosave/${customerId}/add-note-draft`)
                }

                clearInterval(this.siAutoSave)

                this.$emit('cancel')
            },

            buttonSaveTemplateClick(e) {

                this.loadNoteTemplates()

                this.$refs.modalSaveTemplate.show = true

            },

            dropdownImportTemplateChange(item) {

                if(item.value) {

                    const noteTemplate = this._.find(this.noteTemplates, { id: item.value })

                    this.editor.model = noteTemplate.body

                }

            },

            modalSaveTemplateInputNameKeyupEnter(e) {
                this.modalSaveTemplateSave()
            },

            modalSaveTemplateButtonSaveClick(e) {
                this.modalSaveTemplateSave()
            },

            modalSaveTemplateButtonCancelClick(e) {

                this.modalSaveTemplate.model.name = null

                this.$refs.modalSaveTemplate.show = false

            },

            vSelectImportTemplateInput(item) {

                if(item) {

                    const noteTemplate = this._.find(this.noteTemplates, { id: item.code })

                    this.editor.model = noteTemplate.body

                } else {

                    //Para esvaziar o conteúdo do editor, primeiro define como vazio e no próximo update
                    //define como null
                    this.editor.model = ''
                    this.$nextTick(
                        () => {
                            this.editor.model = null
                        }
                    )

                }

            }

        },//methods
        watch: {

            body(value, oldValue) {
                this.editor.model = value
            },

            noteTemplates(value, oldValue) {

                if(value) {

                    const orderedTemplateItems = this._.orderBy(value, ['name'], ['asc'])

                    let dropdownImportTemplateItems = []

                    this.dropdownImportTemplate.items = null

                    orderedTemplateItems.forEach(
                        (item, index, array) => {

                            dropdownImportTemplateItems.push(
                                {
                                    label: item.name,
                                    code: item.id,
                                }
                            )

                        }
                    )

                    this.dropdownImportTemplate.items = dropdownImportTemplateItems

                }

            }

        },//watch
        created() {},//created
        mounted() {

            this.editor.model = this.body

            this.loadNoteTemplates()

            this.startAutoSave()

        },//mounted
        updated() {},//updated
        destroyed() {

          clearInterval(this.siAutoSave)

        },//destroyed
    }
</script>

<style lang="scss">

    $vs-state-active-bg: #cdf1f4 !default;
    $vs-state-active-color: #000 !default;

    @import "vue-select/src/scss/vue-select.scss";

</style>

<style scoped>
    .component-note-add-update
    {

    }

    .container-fields
    {

    }

    .container-fields .col-left
    {
        flex: 1;
        display: flex;
        align-items: center;
    }

    .container-fields .col-right
    {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }

    .container-fields .container-buttons-top
    {
        min-height: 2.8em;
    }

    .container-fields .container-buttons-top > div
    {
        display: flex;
        align-items: center;

        padding: 0.75em;
    }

    .container-fields .container-buttons-top .col-left
    {

    }

    .container-fields .container-buttons-top .col-right
    {
        justify-content: flex-end;
    }

    .container-fields .container-buttons-top .text-message-is-editing
    {
        background-color: #119ab2;
        border-radius: 2px;
        color: #fff;
        display: inline-block;
        font-size: 0.87em;
        padding: 0.5em 0.5em;

        /*animation-duration: 1s;*/
        /*animation-direction: alternate;*/
        /*animation-iteration-count: infinite;*/
        /*animation-name: textMEssageIsEditing;*/
    }

    @keyframes textMEssageIsEditing {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }

    .container-editor
    {
        width: 100%;
    }

    .container-fields .container-buttons-bottom
    {
        display: flex;

        padding: 1rem;
    }

    .container-fields .button-save-note-as-model
    {
        display: inline-flex;
        align-items: center;

        background-color: transparent;
        border: none;
        margin: 0;
        padding: 0;
    }

    .container-fields .button-save-note-as-model img
    {
        height: 1.4em;
        margin-right: 0.4em;
    }

    .container-fields .container-import-model
    {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }

    .container-fields .container-import-model label
    {
        font-size: 0.8em;
        font-weight: 400;
        margin-right: 0.5em;
    }

    .container-fields .container-import-model .text-loading-templates
    {
        font-size: 0.8em;
        opacity: 0.65;
    }

    .container-fields .container-import-model .dropdown-import-model
    {
        flex: 1;

        max-width: 270px;
    }

    .container-fields .container-import-model .v-select-import-template
    {
        flex: 1;
    }

    .container-fields .container-import-model .v-select-import-template.vs--open >>> .vs__dropdown-toggle
    {
        box-shadow: 0 0 2px 2px rgba(41, 177, 204, 0.4);
    }

    .container-fields .container-import-model .v-select-import-template >>> .vs__dropdown-toggle
    {
        background: #fff;
        font-size: 0.875em;
    }

    .container-fields .container-import-model .v-select-import-template >>> .vs__dropdown-menu
    {
        font-size: 0.875em;
    }

    .container-fields .container-import-model .v-select-import-template >>> .vs__clear,
    .container-fields .container-import-model .v-select-import-template >>> .vs__open-indicator
    {
        fill: #555;
    }

    .component-modal-save-template
    {

    }

    .component-modal-save-template >>> .container-dialog main
    {
        padding: 0;
    }

    .component-modal-save-template .container-text-where-to-find-template
    {
        background-color: #f6f6f6;
        font-size: 0.85em;
        padding: 1rem;
    }

    .component-modal-save-template .container-group-input
    {
        box-sizing: border-box;
        width: 100%;
        padding: 1rem;
    }

    .component-modal-save-template .component-input-text
    {
        width: 100%;
    }

    @media (max-width: 767px) {

    }

    @media (min-width: 768px) {

    }

    @media (min-width: 992px) {

        .container-fields .container-buttons-top
        {
            display: flex;
        }

    }

    @media (max-width: 1199px) {

        .container-add-note .col-left
        {
            flex: 1;
        }

        .container-add-note .col-right
        {

        }

        .component-modal-save-template >>> .container-dialog
        {
            max-width: 480px;
            width: 86%;
        }

    }

    @media (min-width: 1200px) {

        .container-add-note .col-left
        {
            flex: 1;
        }

        .container-add-note .col-right
        {
            flex: 1;
        }

        .component-modal-save-template >>> .container-dialog
        {
            width: 12em;
        }

    }
</style>
