<template>
    <div class="component-form-builder-item-add-update">
        <div class="container-question">
            <div :class="['col-left']">
                <div>Tipo da resposta:</div>
                <div :class="['container-question-type', this.mode]">
                    <ComponentDropdown :items="dropdownItems" :selectedIndex="indexSelectedItemDropdown" @change="dropdownQuestionTypeChange"></ComponentDropdown>
                </div>
                <div class="container-mandatory-field">
                    <ComponentCheckbox :checked="model.mandatory" label="Preenchimento obrigatório" v-model="model.mandatory"></ComponentCheckbox>
                </div>

            </div>
            <div :class="['col-right', answerType]" v-if="answerType">

                <template v-if="answerType.match(/short_text|text|rg|cpf|date|time|phone/)">
                    <div>Pergunta:</div>
                    <div class="container-row-question"><input autocomplete="false" type="text" placeholder="digite aqui a pergunta" v-model="model.question"></div>
                </template>

                <template v-if="answerType.match(/multiple_choice|multiple_response|selectbox/)">
                    <div class="container-label-question">Pergunta:</div>
                    <div class="container-row-question"><input autocomplete="false" type="text" placeholder="digite aqui a pergunta" v-model="model.question"></div>
                    <div class="container-field-options">
                        <div class="container-label">
                            Opções:
                        </div>
                        <div class="container-options-added">
                            <div class="container-controls-add">
                                <input autocomplete="false" type="text" placeholder="digite aqui a opção" ref="inputFieldOptionAdd" v-model="fieldOptionAdd.input" @keyup.enter="inputFieldOptionAddKeyupEnter">
                                <button type="button" @click="buttonFieldOptionAdd">Acrescentar</button>
                            </div>
                            <draggable handle=".drag-icon" v-model="model.options">
                                <div class="container-option-item" :key="index" v-for="(item, index) in model.options">
                                    <span class="drag-icon">
                                        <img src="~@/assets/img/ico/ico_drag_item.svg" alt="">
                                    </span>
                                    <span class="item-value">
                                        {{ index + 1 }}. {{ item }}
                                    </span>
                                    <button @click="buttonFieldOptionAddDeleteClick($event, index)">
                                        <img src="~@/assets/img/ico/ico_delete_color.svg" alt="">
                                    </button>
                                </div>
                            </draggable>
                            <button type="button" class="button-add-option-other" @click="buttonAddOptionOtherClick">
                                Adicionar opção <b>Outros(as)</b>
                            </button>
                        </div>
                    </div>
                </template>

            </div>
        </div>
        <hr v-if="$mq.match(/lg/)">
        <div class="container-buttons">
            <button class="btn-default btn-primary btn-save" :disabled="requesting" @click="buttonSaveClick">
                <template v-if="requesting">
                    <img class="icon-circle-segment-animated" src="~@/assets/img/ico/ico_circle_segment_white.svg" alt="">
                    <span>Aguarde</span>
                </template>
                <span v-else>Salvar</span>
            </button>
            <button class="btn-default btn-terciary" :disabled="requesting" @click="buttonCancelClick">
                <template v-if="requesting">
                    <img class="icon-circle-segment-animated" src="~@/assets/img/ico/ico_circle_segment_white.svg" alt="">
                    <span>Aguarde</span>
                </template>
                <span v-else>Cancelar</span>
            </button>
        </div>
    </div>
</template>

<script>

    import draggable from 'vuedraggable'

    import ComponentCheckbox from "@/components/ui/ComponentCheckbox"
    import ComponentDropdown from "@/components/ui/ComponentDropdown"

    export default {
        name: 'ComponentFormBuilderItemAddUpdate',
        props: ['id', 'item'],
        mixins: [],
        components: {
            ComponentCheckbox,
            ComponentDropdown,
            draggable
        },//components
        data() {
            return {

                dropdownItems: [
                    {
                        icon: require('@/assets/img/ico/ico_type_input.svg'),
                        label: 'Resposta curta',
                        value: 'short_text',
                    },
                    {
                        icon: require('@/assets/img/ico/ico_type_paragraph.svg'),
                        label: 'Parágrafo',
                        value: 'text',
                    },
                    {
                        icon: require('@/assets/img/ico/ico_type_radio.svg'),
                        label: 'Múltipla escolha',
                        value: 'multiple_choice',
                    },
                    {
                        icon: require('@/assets/img/ico/ico_type_checkbox.svg'),
                        label: 'Caixas de seleção',
                        value: 'multiple_response',
                    },
                    {
                        icon: require('@/assets/img/ico/ico_type_select.svg'),
                        label: 'Lista suspensa',
                        value: 'selectbox',
                    },
                    {
                        icon: require('@/assets/img/ico/ico_type_rg.svg'),
                        label: 'RG',
                        value: 'rg',
                    },
                    {
                        icon: require('@/assets/img/ico/ico_type_rg.svg'),
                        label: 'CPF',
                        value: 'cpf',
                    },
                    {
                        icon: require('@/assets/img/ico/ico_type_date.svg'),
                        label: 'Data',
                        value: 'date',
                    },
                    {
                        icon: require('@/assets/img/ico/ico_type_time.svg'),
                        label: 'Hora',
                        value: 'time',
                    },
                    {
                        icon: require('@/assets/img/ico/ico_type_phone.svg'),
                        label: 'Telefone',
                        value: 'phone',
                    },
                ],

                fieldOptionAdd: {
                    input: ''
                },

                mode: null,

                model: {
                    question: null,
                    answer_type: null,
                    mandatory: null,
                    options: []
                },

                optionOtherRegex: /^[Oo]utr(?:o|a|os|as|os\(as\))/,

                requesting: false

            }
        },//data
        computed: {

            answerType() {
                return this.model.answer_type
            },

            hasAddedOptionOther() {

                const filterResult = this.model.options.filter(
                    item => {
                        return item.match(this.optionOtherRegex)
                    }
                )

                return filterResult.length > 0

            },

            indexSelectedItemDropdown() {
                return this._.findIndex(this.dropdownItems, { 'value': this.model.answer_type })
            },

            user() {
                return this.$store.getters['App/user']
            }

        },//computed
        methods: {

            buttonAddOptionOtherClick(e) {

                if(!this.hasAddedOptionOther) {
                    this.model.options.push('Outros(as)')
                } else {

                    this.$eventbus.$emit(
                        'ComponentToast/add',
                        {
                            autoHide: true,
                            content: 'Já existe uma opção similar em sua lista',
                            icon: null,
                            showCloseButton: false,
                            type: 'error'
                        }
                    )

                }

            },

            buttonCancelClick(e) {
                this.$emit('cancel')
            },

            buttonFieldOptionAdd(e) {
                this.fieldOptionAddOption()
            },

            buttonFieldOptionAddDeleteClick(e, index) {
                this.model.options.splice(index, 1)
            },

            buttonSaveClick(e) {

                const companyId = this.user.company_id

                let requestConfig = {}

                requestConfig.data = {
                    'customer_field[question]': this.model.question,
                    'customer_field[answer_type]': this.model.answer_type,
                    'customer_field[mandatory]': this.model.mandatory,
                    'customer_field[options]': JSON.stringify(this.model.options)
                }

                if(this.mode === 'add') {

                    requestConfig.method = 'post'
                    requestConfig.url = `api/v1/companies/${companyId}/customer_fields`

                } else {

                    const itemId = this.item.id

                    requestConfig.method = 'patch'
                    requestConfig.url = `api/v1/companies/${companyId}/customer_fields/${itemId}`

                }

                this.requesting = true

                this.axios
                    .request(requestConfig)
                    .then(
                        response => {
                            this.$emit('questionAdded', response)
                        }
                    )
                    .catch(
                        error => {

                            const data = error.response.data

                            this.$eventbus.$emit(
                                'ComponentToast/add',
                                {
                                    autoHide: true,
                                    content: 'Não foi possível salvar a pergunta',
                                    icon: null,
                                    showCloseButton: false,
                                    type: 'error'
                                }
                            )

                            this._.forEach(
                                data,
                                (item, index, array) => {

                                    this.$eventbus.$emit(
                                        'ComponentToast/add',
                                        {
                                            autoHide: true,
                                            content: `Motivo: [${index}] ${item}`,
                                            icon: null,
                                            showCloseButton: false,
                                            type: 'error'
                                        }
                                    )

                                }
                            )

                        }
                    )
                    .finally(
                        () => {
                            this.requesting = false
                        }
                    )

            },

            fieldOptionAddOption() {

                if(this.fieldOptionAdd.input) {

                    this.model.options.push(this.fieldOptionAdd.input)

                }

                this.model.options.map(
                    (item, index, array) => {
                        if(item.match(this.optionOtherRegex)) {
                            this.model.options.push(this.model.options.splice(index, 1)[0])
                        }
                    }
                )

                this.fieldOptionAdd.input = ''

                this.fieldOptionAdd.show = false

            },

            dropdownQuestionTypeChange(item) {
                this.model.answer_type = item.value
            },

            inputFieldOptionAddBlur(e) {
                this.fieldOptionAddOption()
            },

            inputFieldOptionAddKeyupEnter(e) {
                this.fieldOptionAddOption()
            }

        },//methods
        watch: {

        },//watch
        created() {},//created
        mounted() {

            if(this.item) {

                this.mode = 'edit'

                this.model.question = this.item.question
                this.model.answer_type = this.item.answer_type
                this.model.mandatory = this.item.mandatory

                if(this.item.options) {
                    this.model.options = this.item.options
                }

            } else {



                this.mode = 'add'

                this.model = {
                    question: '',
                    answer_type: 'short_text',
                    mandatory: false,
                    options: []
                }

            }

        },//mounted
        updated() {},//updated
        destroyed() {},//destroyed
    }
</script>

<style scoped>
    .component-form-builder-item-add-update
    {
        background-color: #fff;
        border-radius: 5px;
        padding: 1em;
    }

    .container-question
    {
        margin-bottom: 2em;
    }

    .container-question .col-left
    {
        margin-right: 2em;
    }

    .container-question .container-question-type
    {
        margin-top: 1em;
    }

    .container-question .container-question-type .component-dropdown
    {
        margin-top: 0.25em;
        width: 100%;
    }

    .container-question .container-question-type.add .component-dropdown >>> > button
    {
        animation-duration: 0.25s;
        animation-direction: alternate;
        animation-iteration-count: 6;
        animation-name: componentDropdownHighlight;
    }

    @keyframes componentDropdownHighlight {
        from {
            box-shadow: none;
        }
        to {
            box-shadow: 0 0 0 2px #29B1CC;
        }
    }

    .container-question .container-mandatory-field
    {
        margin-top: 1em;
    }

    .container-question .col-right
    {
        flex: 1;
    }

    .container-question .col-right input::-webkit-input-placeholder
    {
        font-weight: 300;
        opacity: 0.5;
    }

    .container-question .col-right input::-moz-placeholder
    {
        font-weight: 300;
        opacity: 0.5;
    }

    .container-question .col-right input:-ms-input-placeholder
    {
        font-weight: 300;
        opacity: 0.5;
    }

    .container-question .col-right input::-ms-input-placeholder
    {
        font-weight: 300;
        opacity: 0.5;
    }

    .container-question .col-right input::placeholder
    {
        font-weight: 300;
        opacity: 0.5;
    }

    .container-question .col-right .container-row-question input
    {
        background-color: transparent;
        border: none;
        border-bottom: 1px solid #d0d0d0;
        font-weight: 300;
        padding: 0.5em 0;
        width: 100%;
    }

    .container-question .col-right .container-row-question-placeholder
    {
        margin-top: 0.5em;
    }

    .container-question .col-right .container-row-question-placeholder input
    {
        background-color: transparent;
        border: none;
        border-bottom: 1px dashed #d0d0d0;
        font-weight: 300;
        padding: 0.5em 0;
        width: 100%;
    }

    .container-options-added
    {
        background-color: #f0f0f0;
        border-radius: 4px 4px 0 0;
        display: inline-block;
        font-size: 0.9em;
        list-style-position: inside;
        margin-top: 0.5em;
        padding: 1em;
    }

    .button-add-option-other
    {
        background-color: transparent;
        border: none;
        color: #00687a;
        font-size: 0.9em;
        margin: 0;
        margin-top: 0.5em;
        padding: 0;
        text-decoration: underline;
    }

    .container-question .container-field-options .container-controls-add
    {
        display: flex;

        width: 100%;
    }

    .container-question .container-field-options .container-controls-add input
    {
        flex: 1;

        background-color: #fff;
        border: none;
        border-radius: 3px;
        font-size: 0.95em;
        margin-right: 0.5em;
        padding: 0.5em;
    }

    .container-question .container-field-options .container-controls-add button
    {
        background-color: #29B1CC;
        border: none;
        border-radius: 4px;
        color: #fff;
        font-size: 0.95em;
        padding: 0.5em 1em;
    }

    .container-question .container-option-item
    {
        display: flex;
        align-items: center;
        justify-content: space-between;

        border-bottom: 1px dotted #ccc;
        margin: 0.5em 0;
        padding: 0.5em 0;
    }

    .container-question .container-option-item .drag-icon
    {
        margin-right: 1em;
    }

    .container-question .container-option-item .item-value
    {
        flex: 1;
    }

    .container-question .container-option-item span
    {
        vertical-align: middle;
    }

    .container-question .container-option-item button
    {
        background-color: transparent;
        border: none;
        margin-left: 0.5em;
        padding: 0;
        vertical-align: middle;
    }

    .container-buttons
    {

    }

    @media (max-width: 767px) {

    }

    @media (min-width: 768px) {

    }

    @media (min-width: 992px) {

    }

    @media (max-width: 1199px) {

        .container-question
        {

        }

        .container-question .col-right
        {
            margin-top: 1em;
        }

        .container-question .col-right .container-row-question
        {

        }

        .container-question .container-field-options
        {
            margin-top: 1em;
        }

    }

    @media (min-width: 1200px) {

        .container-question
        {
            display: flex;
        }

        .container-question .col-right
        {

        }

        .container-question .col-right .container-row-question
        {
            margin-top: 1em;
        }

        .container-question .container-field-options
        {
            margin-top: 0.5em;
        }

        .container-options-added
        {
            max-width: 50%;
            min-width: 50%;
        }

    }
</style>
