import ComponentViewTabs from "@/components/ui/ComponentViewTabs"

export default {
    components: {
        ComponentViewTabs
    },//components
    data() {
        return {}
    },//data
    computed: {

        viewTabs() {

            let labelFormFields = ''

            if(this.$mq.match(/lg/)) {
                labelFormFields = 'Campos do formulário'
            } else {
                labelFormFields = 'Formulário'
            }

            return [
                {
                    label: 'Geral',
                    to: '/business/main'
                },
                {
                    label: 'Usuários',
                    to: '/business/users'
                },
                // {
                //     label: 'Histórico',
                //     to: '/business/log'
                // },
                {
                    label: labelFormFields,
                    to: '/business/form'
                },
            ]

        }

    },
    methods: {},//methods
    watch: {},//watch
    beforeRouteEnter(to, from, next) {
        next(
            vm => {
            }
        )
    },//beforeRouteEnter
    beforeRouteUpdate(to, from, next) {
        next()
    },//beforeRouteUpdate
    beforeRouteLeave(to, from, next) {
        next()
    },//beforeRouteLeave
    created() {},//created
    mounted() {}//mounted
}
