<template>
    <div class="component-personal-profile">
        <div class="container-profile-picture">
            <transition name="fade">
                <div class="container-message-image-rotation" v-if="hasSelectedImageForUpload">
                    Caso a imagem esteja rotacionada, ela será corrigida após você salvar.
                </div>
            </transition>
            <ComponentImageUpload :icon="require('@/assets/img/ico/ico_profile.svg')" label="+ Adicionar<br>foto do perfil" :src="pictureFileUrl" @change="componentImageUploadChange"></ComponentImageUpload>
            <button type="button" class="button-delete" @click.stop="buttonDeleteProfilePicture" v-if="pictureFileUrl">
                <img src="~@/assets/img/ico/ico_circle_delete_color.svg" alt="">
            </button>
        </div>
        <div class="container-group-input">
            <label ref="labelName">Nome<span class="required-mark">*</span></label>
            <ComponentInputText placeholder="nome" type="text" :validation="{ required: true }" v-model="model.name"></ComponentInputText>
        </div>
        <ComponentInfoIcon :target="$refs.labelName" :text="'Este nome é somente para identificação interna no sistema, NÃO aparece para seus ' + $store.getters['Companies/customerTreatmentPlural'].toLowerCase()" />
        <div class="container-group-input">
            <label>E-mail<span class="required-mark" v-if="!emailIsReadOnly">*</span></label>
            <div class="container-email-read-only" v-if="emailIsReadOnly === true">
                <b>{{ model.email }}</b>
            </div>
            <ComponentInputText placeholder="e-mail" type="text" :validation="{ regex: /^[^@]+@[^\.]+\..+$/, required: true }" v-model="model.email" v-else></ComponentInputText>
        </div>
        <div class="container-group-input">
            <label ref="labelPhone">Telefone</label>
            <ComponentInputText :maskPattern="['(##) ####-####', '(##) #####-####']" placeholder="(00) 00000-0000" :validation="{ regex: /\([0-9]{2}\) [0-9]{4,5}-[0-9]{4}/ }" type="text" v-model="model.phone"></ComponentInputText>
        </div>
        <ComponentInfoIcon :target="$refs.labelPhone" :text="'Este telefone é somente para identificação interna no sistema, NÃO aparece para seus ' + $store.getters['Companies/customerTreatmentPlural'].toLowerCase()" />
    </div>
</template>

<script>

    import ComponentCheckbox from "@/components/ui/ComponentCheckbox"
    import ComponentImageUpload from "@/components/ui/ComponentImageUpload"
    import ComponentInfoIcon from "@/components/ui/ComponentInfoIcon"
    import ComponentInputText from "@/components/ui/ComponentInputText"

    export default {
        name: 'ComponentPersonalProfile',
        props: [
            'email',
            'emailIsReadOnly',
            'name',
            'phone',
            'pictureFileUrl',
            'user'
        ],
        mixins: [],
        components: {
            ComponentCheckbox,
            ComponentImageUpload,
            ComponentInfoIcon,
            ComponentInputText
        },//components
        data() {
            return {

                model: {
                    name: null,
                    email: null,
                    pictureFile: null,
                    phone: null
                },

                hasSelectedImageForUpload: false,

                validation: {}

            }
        },//data
        computed: {

        },//computed
        methods: {

            updateProfile(mode) {

                const companyId = this.user.company_id
                const userId = this.user.id

                let data = {
                    'user[name]': this.model.name,
                    'user[phone]': this.model.phone ? this.model.phone : '',
                }

                if(this.model.pictureFile) {
                    data['user[attachment_attributes][attachment]'] = this.model.pictureFile
                }

                if(this.user.image_id) {
                    data['user[attachment_attributes][id]'] = this.user.image_id
                }

                if(mode === 'deleteImage') {
                    data['user[attachment_attributes][_destroy]'] = true
                }

                this.$emit('requestingUpdate')

                this.axios
                    .patch(`api/v1/companies/${companyId}/users/${userId}`, data)
                    .then(
                        response => {

                            const user = response.data

                            this.$store.dispatch('App/setUser', user)

                            this.$eventbus.$emit(
                                'ComponentToast/add',
                                {
                                    autoHide: true,
                                    content: `Perfil alterado com sucesso`,
                                    icon: null,
                                    showCloseButton: false,
                                    type: 'success'
                                }
                            )

                            this.$emit('updated')

                        }
                    )
                    .catch(
                        error => {

                            this.$eventbus.$emit(
                                'ComponentToast/add',
                                {
                                    autoHide: true,
                                    content: 'Erro ao alterar o perfil',
                                    icon: null,
                                    showCloseButton: false,
                                    type: 'error'
                                }
                            )

                        }
                    )
                    .finally(
                        () => {
                            this.$emit('requestingDone')
                        }
                    )

            },

            //Events

            buttonDeleteProfilePicture(e) {
                this.updateProfile('deleteImage')
            },

            componentImageUploadChange(obj) {

                this.model.pictureFile = obj.fileBase64

                this.hasSelectedImageForUpload = true

            }

        },//methods
        watch: {},//watch
        created() {},//created
        mounted() {

            if(this.email) {
                this.model.email = this.email
            }

            if(this.name) {
                this.model.name = this.name
            }

            if(this.phone) {
                this.model.phone = this.phone
            }

        },//mounted
        updated() {},//updated
        destroyed() {},//destroyed
    }
</script>

<style scoped>
    .component-personal-profile
    {
        text-align: center;
    }

    .container-profile-picture
    {
        padding: 0.5em 0;
    }

    .container-profile-picture .button-delete
    {
        background-color: transparent;
        border: none;
        margin: 0;
        margin-left: 0.25em;
        padding: 0;
        vertical-align: top;
    }

    .container-group-input
    {
        font-size: 0.9em;
        margin: 0 auto 0.5em;
    }

    .container-group-input .component-input-text
    {
        width: 100%;
    }

    .container-message-image-rotation
    {
        background-color: #f5f5f5;
        border-radius: 4px;
        font-size: 0.8rem;
        margin-bottom: 1em;
        padding: 1em;
    }

    @media (max-width: 767px) {

    }

    @media (min-width: 768px) {

    }

    @media (min-width: 992px) {

    }

    @media (max-width: 1199px) {

        .container-email-read-only *
        {
            font-weight: 800;
        }

        .container-group-input
        {
            width: 100%;
        }

    }

    @media (min-width: 1200px) {

        .container-group-input
        {
            width: 100%;
        }

    }
</style>
