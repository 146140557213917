<template>
    <div class="component-first-content-box">
        <div class="container-icon" v-if="icon_">
            <img :src="icon_" alt="">
        </div>
        <div class="container-content" v-html="content"></div>
        <div class="container-buttons">
            <button class="btn-default btn-primary" tag="button" @click="buttonClick">{{ button.label }}</button>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'ComponentFirstContentBox',
        props: [
            'icon',
            'content',
            'button'
        ],
        mixins: [],
        components: {},//components
        data() {
            return {}
        },//data
        computed: {

            icon_() {

                let ret = false

                if(this.icon) {
                    ret = this.icon
                }

                return ret

            },

        },//computed
        methods: {

            buttonClick(e) {
                this.button.clickHandler(e)
            }

        },//methods
        watch: {},//watch
        created() {},//created
        mounted() {},//mounted
        updated() {},//updated
        destroyed() {},//destroyed
    }
</script>

<style scoped>
    .component-first-content-box
    {
        background-color: #fff;
        border-radius: 4px;
        box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.2);
        box-sizing: border-box;
        max-width: 460px;
        padding: 2.5em 3.5em;
        text-align: center;
    }

    .container-icon
    {
        margin-right: 1em;
    }

    .container-icon img
    {
        height: 6em;
    }

    .container-content
    {
        margin: 1.5em 0 2em;
    }

    @media (max-width: 767px) {

    }

    @media (min-width: 768px) {

    }

    @media (min-width: 992px) {

    }

    @media (max-width: 1199px) {

    }

    @media (min-width: 1200px) {

    }
</style>
