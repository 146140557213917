<template>
    <section id="view-personal-profile">

        <ComponentViewTabs class="component-view-tabs" :tabs="viewTabs"></ComponentViewTabs>

        <template v-if="requesting">
            <ComponentLoading></ComponentLoading>
        </template>
        <div class="container-view-content" v-show="!requesting">
            <ComponentPersonalProfile
                :email="user.email"
                :emailIsReadOnly="true"
                :key="JSON.stringify(user)"
                :name="user.name"
                :phone="user.phone"
                :pictureFileUrl="user.image_url"
                ref="componentPersonalProfile"
                :user="user"
                @requestingDone="componentPersonalProfileRequestingDone"
                @requestingUpdate="componentPersonalProfileRequestingUpdate"
                @updated="componentPersonalProfileUpdated"
                v-if="user" />
            <div class="container-buttons">
                <button class="btn-default btn-primary" @click="buttonSaveClick">Salvar</button>
            </div>
        </div>

    </section>
</template>

<script>
    import MixinView from '@/mixins/MixinView.js'
    import MixinPersonalProfile from '@/mixins/PersonalProfile/MixinPersonalProfile.js'

    import ComponentLoading from "@/components/ui/ComponentLoading"
    import ComponentPersonalProfile from "@/components/ComponentPersonalProfile"

    export default {
        name: 'PersonalProfile',
        props: [],
        mixins: [MixinView, MixinPersonalProfile],
        components: {
            ComponentLoading,
            ComponentPersonalProfile
        },//components
        data() {
            return {

                model: {},

                requesting: false,

                user: null

            }
        },//data
        computed: {

        },//computed
        methods: {

            loadUser() {

                const companyId = this.$store.getters['App/companyId']
                const userId = this.$store.getters['App/userId']

                this.requesting = true

                this.$fetch.get(`/api/v1/companies/${companyId}/users/${userId}`)
                    .then(
                        response => {

                            const data = response.data

                            this.user = data

                        }
                    )
                    .catch(
                        error => {

                            this.$eventbus.$emit(
                                'ComponentToast/add',
                                {
                                    autoHide: true,
                                    content: 'Erro ao carregar usuário',
                                    icon: null,
                                    showCloseButton: false,
                                    type: 'error'
                                }
                            )

                        }
                    )
                    .finally(
                        () => {
                            this.requesting = false
                        }
                    )

            },

            //Events

            buttonSaveClick(e) {

                const isValid = this.$helpers.validateFields(this.$refs.componentPersonalProfile.$children)
                const password = this.$refs.componentPersonalProfile.model.password
                const passwordConfirmation = this.$refs.componentPersonalProfile.model.passwordConfirmation

                if(password !== passwordConfirmation) {

                    this.$eventbus.$emit(
                        'ComponentToast/add',
                        {
                            autoHide: true,
                            content: 'O campo CONFIRME A NOVA SENHA deve ser igual ao campo NOVA SENHA',
                            icon: null,
                            showCloseButton: false,
                            type: 'error'
                        }
                    )

                    return false

                }

                if(isValid) {

                    this.$refs.componentPersonalProfile.updateProfile()

                } else {

                    this.$eventbus.$emit(
                        'ComponentToast/add',
                        {
                            autoHide: true,
                            content: 'Preencha corretamente todos os campos para prosseguir (verifique os obrigatórios)',
                            icon: null,
                            showCloseButton: false,
                            type: 'error'
                        }
                    )

                }

            },

            componentPersonalProfileRequestingDone() {
                this.loadUser()
            },

            componentPersonalProfileRequestingUpdate() {
                this.requesting = true
            },

            componentPersonalProfileUpdated() {
            }

        },//methods
        watch: {},//watch
        created() {},//created
        mounted() {
            this.loadUser()
        },//mounted
        updated() {},//updated
        destroyed() {},//destroyed
        beforeRouteEnter(to, from, next) {
            next(
                vm => {
                }
            )
        },//beforeRouteEnter
        beforeRouteUpdate(to, from, next) {
            next()
        },//beforeRouteUpdate
        beforeRouteLeave(to, from, next) {
            next()
        }//beforeRouteLeave

    }
</script>

<style scoped>
    #view-personal-profile
    {

    }

    .component-view-tabs
    {
        margin-bottom: 1.5em;
    }

    .container-requesting
    {
        display: flex;
        align-items: center;
        justify-content: center;

        font-size: 1.5em;
        opacity: 0.5;
        padding: 2em 1em;
    }

    .container-requesting img
    {
        height: 1.25em;
        margin-right: 0.5em;
    }

    .container-view-content
    {
        background-color: #fff;
        border-radius: 5px;
        box-sizing: border-box;
        margin: 0 auto;
        padding: 1em;
    }

    .container-buttons
    {
        margin-top: 1em;
        text-align: right;
    }

    @media (max-width: 767px) {

    }

    @media (min-width: 768px) and (max-width: 1199px) {

    }

    @media (max-width: 1199px) {

        .container-view-content
        {
            width: 100%;
        }

    }

    @media (min-width: 1200px) {

        .container-view-content
        {
            width: 400px;
        }

    }
</style>
