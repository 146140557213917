<template>
    <section id="view-business-users-add-update">

        <ComponentViewTabs class="component-view-tabs" :tabs="this.viewTabs"></ComponentViewTabs>

        <template v-if="requesting">
            <ComponentLoading></ComponentLoading>
        </template>
        <template v-else>

            <div class="container-view-header">
                <div class="col-left">
                    <h1>{{ title }}</h1>
                </div>
            </div>

<!--<div class="container-billing-admin" v-if="user && user.billing_admin">-->
<!--  Este é um usuário ADMINISTRADOR-->
<!--</div>-->

            <div class="container-view-content">
                <fieldset :disabled="requesting" ref="containerForm">
                    <div>
                        <div class="col-left">
                            <div class="container-group-input">
                                <label>E-mail<span class="required-mark" v-if="canEditFields">*</span></label>
                                <ComponentInputText placeholder="e-mail" ref="inputEmail" type="text" :validation="{ regex: /^[^@]+@[^\.]+\..+$/, required: true }" v-model="model.email" v-if="canEditFields"></ComponentInputText>
                                <div v-else>
                                    <span>{{ model.email }}</span>
                                </div>
                            </div>
                            <div class="container-group-input">
                                <label>Nome<span class="required-mark" v-if="canEditFields">*</span></label>
                                <ComponentInputText placeholder="nome" ref="inputName" type="text" :validation="{ required: true }" v-model="model.name" v-if="canEditFields"></ComponentInputText>
                                <div v-else>
                                    <span>{{ model.name }}</span>
                                </div>
                            </div>
                            <div class="container-group-input" v-if="canEditFields">
                                <label>Senha<span class="required-mark">*</span></label>
                                <ComponentInputText placeholder="senha" ref="inputPassword" type="password" :validation="{ required: true }" showPasswordButton="true" v-model="model.password"></ComponentInputText>
                            </div>
                            <div class="container-group-input" v-if="canEditFields">
                                <label>Confirme a senha<span class="required-mark">*</span></label>
                                <ComponentInputText placeholder="senha" ref="inputPasswordConfirmation" type="password" :validation="{ required: true }" showPasswordButton="true" v-model="model.passwordConfirmation"></ComponentInputText>
                            </div>
                            <div class="container-group-input" v-if="canEditFields">
                                <label>Telefone</label>
                                <ComponentInputText :maskPattern="['(##) ####-####', '(##) #####-####']" placeholder="(00) 00000-0000" ref="inputPhone" :validation="{ regex: /\([0-9]{2}\) [0-9]{4,5}-[0-9]{4}/ }" type="text" v-model="model.phone"></ComponentInputText>
                            </div>
                            <div class="container-group-input">
                                <label>Perfil</label>
                                <ComponentDropdown class="dropdown-account-type" :items="dropdownAccountTypeItems" :selectedIndex="dropdownAccountTypeSelectedIndex" @change="dropdownAccountTypeChange"></ComponentDropdown>
                            </div>
                        </div>
                        <div class="col-right col-image-upload" v-if="canEditFields">
                            <label>Foto do perfil</label>
                            <ComponentImageUpload class="component-image-upload" :icon="require('@/assets/img/ico/ico_profile.svg')" label="+ Adicionar" :src="user ? user.image_url : ''" @change="componentImageUploadChange"></ComponentImageUpload>
                            <button type="button" class="button-delete" @click="buttonDeleteLogo" v-if="hasProfilePicture">
                                <img src="~@/assets/img/ico/ico_circle_delete_color.svg" alt="">
                            </button>
                        </div>
                    </div>
                </fieldset>
                <hr>
                <div class="container-buttons">
                    <button class="btn-default btn-primary btn-save" :disabled="requesting" @click="buttonSaveClick">
                        <template v-if="requesting">
                            <img class="icon-circle-segment-animated" src="~@/assets/img/ico/ico_circle_segment_white.svg" alt="">
                            <span>Aguarde</span>
                        </template>
                        <span v-else>Salvar</span>
                    </button>
                    <router-link class="btn-default btn-terciary" :disabled="requesting" tag="button" :to="{ name: 'BusinessUsers' }">
                        <span>Cancelar</span>
                    </router-link>
                </div>
            </div>

        </template>

    </section>
</template>

<script>
    import MixinView from '@/mixins/MixinView.js'
    import MixinBusiness from '@/mixins/Business/MixinBusiness'

    import ComponentDropdown from "@/components/ui/ComponentDropdown"
    import ComponentImageUpload from "@/components/ui/ComponentImageUpload"
    import ComponentInputText from "@/components/ui/ComponentInputText"
    import ComponentLoading from "@/components/ui/ComponentLoading"
    import ComponentRadioGroup from "@/components/ui/ComponentRadioGroup"

    export default {
        name: 'BusinessUsersAddUpdate',
        props: [],
        mixins: [MixinView, MixinBusiness],
        components: {
            ComponentDropdown,
            ComponentImageUpload,
            ComponentInputText,
            ComponentLoading,
            ComponentRadioGroup
        },//components
        data() {
            return {

                companyId: null,

                model: {
                    accountType: null,
                    email: null,
                    name: null,
                    password: null,
                    passwordConfirmation: null,
                    phone: null,
                    pictureFile: null
                },

                requesting: false,

                user: null,

                viewMode: null

            }
        },//data
        computed: {

            dropdownAccountTypeItems() {

              const signedInUser = this.signedInUser

              let items = [
                {
                  icon: null,
                  label: 'Limitado',
                  value: 'limited',
                }
              ]

              if(signedInUser) {

                const accountType = signedInUser.account_type

                if(accountType !== 'limited') {
                    items.push(
                        {
                          icon: null,
                          label: 'Padrão',
                          value: 'standard',
                        }
                    )
                }

                if(accountType === 'system_admin') {
                    items.push(
                        {
                          icon: null,
                          label: 'Administrador',
                          value: 'system_admin',
                        }
                    )
                }

              }

              return items

            },

            dropdownAccountTypeSelectedIndex() {

              const user = this.user

              let ret = 0

              if(user) {
                ret = this._.findIndex(this.dropdownAccountTypeItems, { value: user.account_type })
              }

              return ret

            },
            
            canEditFields() {
                
                const signedInUser = this.signedInUser
                const accountType = signedInUser.account_type
                const viewMode = this.viewMode
              
                let ret = true
                
                if(accountType !== 'system_admin') {
                    
                    if(accountType === 'standard' && viewMode === 'edit') {
                        ret = false
                    }
                    
                }

                return ret
                
            },

            hasProfilePicture() {

                if(this.user) {

                    if(this.user.image_url) {
                        return true
                    } else {
                        return false
                    }

                } else {
                    return false
                }

            },

            signedInUser() {
              return this.$store.getters['App/user']
            },

            title() {

                let ret = 'Novo usuário'

                if(this.viewMode === 'edit') {
                    ret = 'Editar usuário'
                }

                return ret

            },

        },//computed
        methods: {

            loadUser(userId) {

                const companyId = this.$store.getters['App/companyId']

                this.requesting = true

                this.$fetch.get(`/api/v1/companies/${companyId}/users/${userId}`)
                    .then(
                        response => {

                            const data = response.data
                            const th = this

                            this.user = data

                            this.companyId = data.company_id

                            this.model.email = data.email
                            this.model.name = data.name

                            if(data.phone) {
                                this.model.phone = data.phone
                            }

                        }
                    )
                    .catch(
                        error => {

                            this.$eventbus.$emit(
                                'ComponentToast/add',
                                {
                                    autoHide: true,
                                    content: 'Erro ao carregar usuário',
                                    icon: null,
                                    showCloseButton: false,
                                    type: 'error'
                                }
                            )


                            this.$router.replace( { name: 'BusinessUsers' }, () => {},  () => {} )

                        }
                    )
                    .finally(
                        () => {
                            this.requesting = false
                        }
                    )

            },

            //Events

            buttonDeleteLogo(e) {

            },

            buttonSaveClick(e) {

                const companyId = this.companyId
                const userId = this.$route.params.id

                let requestConfig = {}

                requestConfig.data = {
                    'user[account_type]': this.model.accountType.value,
                    'user[email]': this.model.email,
                    'user[name]': this.model.name,
                    'user[phone]': this.model.phone
                }

                if(this.model.password) {
                    requestConfig.data['user[password]'] = this.model.password
                    requestConfig.data['user[password_confirmation]'] = this.model.passwordConfirmation
                }

                if(this.model.pictureFile) {
                    requestConfig.data['user[attachment_attributes][attachment]'] = this.model.pictureFile
                }

                if(this.viewMode === 'add') {

                    requestConfig.method = 'post'
                    requestConfig.url = `api/v1/companies/${companyId}/users`

                } else {

                    requestConfig.method = 'patch'
                    requestConfig.url = `api/v1/companies/${companyId}/users/${userId}`

                }

                let isValid = null

                if(this.canEditFields) {
                    isValid = this.$helpers.validateFields(
                        [
                            this.$refs.inputEmail,
                            this.$refs.inputName,
                            this.$refs.inputPassword,
                            this.$refs.inputPasswordConfirmation,
                            this.$refs.inputPhone
                        ]
                    )
                } else {
                    isValid = true
                }

                if(isValid) {

                    this.requesting = true

                    this.axios
                        .request(requestConfig)
                        .then(
                            response => {

                                this.$eventbus.$emit(
                                    'ComponentToast/add',
                                    {
                                        autoHide: true,
                                        content: `Usuário criado/alterado com sucesso`,
                                        icon: null,
                                        showCloseButton: false,
                                        type: 'success'
                                    }
                                )

                                this.$router.replace({ name: 'BusinessUsers' }, () => {}, () => {})

                            }
                        )
                        .catch(
                            error => {

                                const data = error.response.data

                                this.$eventbus.$emit(
                                    'ComponentToast/add',
                                    {
                                        autoHide: true,
                                        content: 'Não foi possível criar/alterar o usuário',
                                        icon: null,
                                        showCloseButton: false,
                                        type: 'error'
                                    }
                                )

                                this._.forEach(
                                    data,
                                    (item, index, array) => {

                                        this.$eventbus.$emit(
                                            'ComponentToast/add',
                                            {
                                                autoHide: true,
                                                content: `Motivo: [${index}] ${item}`,
                                                icon: null,
                                                showCloseButton: false,
                                                type: 'error'
                                            }
                                        )

                                    }
                                )

                            }
                        )
                        .finally(
                            () => {
                                this.requesting = false
                            }
                        )

                } else {

                    this.$eventbus.$emit(
                        'ComponentToast/add',
                        {
                            autoHide: true,
                            content: 'Preencha corretamente todos os campos para salvar (verifique os obrigatórios)',
                            icon: null,
                            showCloseButton: false,
                            type: 'error'
                        }
                    )

                }

            },

            componentImageUploadChange(obj) {
                this.model.pictureFile = obj.fileBase64
            },

            dropdownAccountTypeChange(item) {
                this.model.accountType = item
            }

        },//methods
        watch: {},//watch
        created() {},//created
        beforeMount() {

            const route = this.$route
            const path = route.path

            const user = this.$store.getters['App/user']

            let conditionsAccountType = null

            if(path.match(/edit\/.*/)) {

                this.viewMode = 'edit'

                if(user.account_type === 'limited') {
                    conditionsAccountType = {
                        id: parseInt(this.$route.params.id),
                    }
                }
                else if(user.account_type === 'standard') {
                    conditionsAccountType = {
                        account_type: user.account_type,
                        company_id: user.company_id,
                    }
                }
                else {
                    conditionsAccountType = {}
                }

                this.$helpers.setRouteMetaRulesConditions(
                    {
                        conditions: conditionsAccountType,
                        vm: this
                    }
                )

            } else {
                this.viewMode = 'add'
            }

        },//beforeMount
        mounted() {

            const route = this.$route
            const userId = route.params.id

            if(this.viewMode === 'edit') {
                this.loadUser(userId)
            } else {
                this.companyId = this.$store.getters['App/companyId']
            }

        },//mounted
        updated() {},//updated
        destroyed() {},//destroyed
        beforeRouteEnter(to, from, next) {
            next(
                vm => {
                }
            )
        },//beforeRouteEnter
        beforeRouteUpdate(to, from, next) {
            next()
        },//beforeRouteUpdate
        beforeRouteLeave(to, from, next) {
            next()
        }//beforeRouteLeave

    }
</script>

<style scoped>
    #view-business-users-add-update
    {

    }

    .container-requesting
    {
        display: flex;
        align-items: center;
        justify-content: center;

        font-size: 1.5em;
        opacity: 0.5;
        padding: 2em 1em;
    }

    .container-requesting img
    {
        height: 1.25em;
        margin-right: 0.5em;
    }

    hr
    {
        margin: 1.5em 0;
    }

    .component-view-tabs
    {
        margin-bottom: 1.5em;
    }

    .container-view-header
    {
        margin-bottom: 1em;
    }

    .container-billing-admin
    {
      background-color: rgba(255, 255, 255, 0.5);
      border-radius: 5px;
      box-sizing: border-box;
      color: #29B1CC;
      margin: 0 auto 1em;
      padding: 1em;
    }

    .container-view-content
    {
        background-color: #fff;
        border-radius: 5px;
        margin-bottom: 2em;
        padding: 1.5em;
    }

    fieldset > div
    {
        display: flex;
    }

    fieldset .col-left
    {
        flex: 1;
    }

    fieldset .col-right
    {
        flex: 1;
    }

    .container-group-input
    {
        font-size: 0.9em;
        margin-bottom: 1em;
        width: 100%;
    }

    .container-group-input .component-input-text
    {
        width: 100%;
    }

    .col-image-upload
    {

    }

    .col-image-upload label
    {
        display: block;
        font-size: 0.9em;
        margin-bottom: 0.5em;
    }

    .col-image-upload .button-delete
    {
        background-color: transparent;
        border: none;
        margin: 0;
        margin-left: 0.25em;
        padding: 0;
        vertical-align: top;
    }

    .container-buttons
    {

    }

    .dropdown-account-type
    {
      width: 15em;
    }

    @media (max-width: 767px) {

      .dropdown-account-type
      {
        width: 100%;
      }

    }

    @media (min-width: 768px) and (max-width: 1199px) {

    }

    @media (max-width: 1199px) {

        fieldset > div
        {
            flex-direction: column-reverse;
        }

        fieldset .col-right
        {
            text-align: center;
        }

    }

    @media (min-width: 1200px) {

        fieldset .col-left
        {
            margin-right: 2em;
        }

        .component-radio-group-can-view-notes >>> button
        {
            display: inline-block;
            margin-right: 1em;
        }

    }
</style>
