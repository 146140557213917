import ComponentViewTabs from "@/components/ui/ComponentViewTabs"

export default {
    components: {
        ComponentViewTabs
    },//components
    data() {
        return {}
    },//data
    computed: {

        viewTabs() {

            let labelPersonalData = 'Dados pessoais'
            let labelPassword = 'Senha'

            return [
                {
                    label: labelPersonalData,
                    to: '/personal-profile/personal-data'
                },
                {
                    label: labelPassword,
                    to: '/personal-profile/password'
                }
            ]

        }

    },
    methods: {},//methods
    watch: {},//watch
    beforeRouteEnter(to, from, next) {
        next(
            vm => {
            }
        )
    },//beforeRouteEnter
    beforeRouteUpdate(to, from, next) {
        next()
    },//beforeRouteUpdate
    beforeRouteLeave(to, from, next) {
        next()
    },//beforeRouteLeave
    created() {},//created
    mounted() {}//mounted
}
