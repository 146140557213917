<template>
    <div class="component-image-upload">
        <div class="container-image">
            <span class="image-preview" ref="imagePreview" v-if="fileBase64 || src">
                <img :src="fileBase64 || src" alt="">
            </span>
            <span class="icon" v-else>
                <img :src="icon" alt="">
            </span>
            <input type="file" accept=".jpg, .jpeg, .png" @change="inputFileChange">
        </div>
        <div class="container-label" v-html="label" v-if="fileBase64 === null && !src"></div>
    </div>
</template>

<script>
    export default {
        name: 'ComponentImageUpload',
        props: [
            'icon',
            'label',
            'src'
        ],
        mixins: [],
        components: {},//components
        data() {
            return {
                exifData: null,
                fileBase64: null,
                file: null
            }
        },//data
        computed: {},//computed
        methods: {

            //Events

            inputFileChange(e) {

                this.file = e.currentTarget
                this.$helpers.getInputFileAsBase64(this.file)
                    .then(
                        (base64) => {

                            this.fileBase64 = base64

                            this.$emit(
                                'change',
                                {
                                    file: this.file,
                                    fileBase64: this.fileBase64,
                                }
                            )

                        }
                    )
                    .catch(
                        () => {}
                    )

            }

        },//methods
        watch: {},//watch
        created() {},//created
        mounted() {},//mounted
        updated() {},//updated
        destroyed() {},//destroyed
    }
</script>

<style scoped>
    .component-image-upload
    {
        display: inline-block;
        position: relative;
        text-align: center;
    }

    .container-image input
    {
        cursor: pointer;
        height: 100%;
        left: 0;
        opacity: 0;
        position: absolute;
        top: 0;
        width: 100%;
    }

    .container-image .image-preview
    {
        display: inline-block;
        margin-bottom: 0.5em;
    }

    .container-image .image-preview img
    {
        border: 8px solid #fff;
        max-height: 72px;
        max-width: 200px;
        outline: 1px dashed #999;
    }

    .container-image .icon
    {
        display: inline-block;
        line-height: 0;
        margin-bottom: 0.25em;
    }

    .container-image .icon img
    {
        height: 58px;
        width: 58px;
    }

    .container-label
    {
        line-height: 100%;
    }

    @media (max-width: 767px) {

    }

    @media (min-width: 768px) {

    }

    @media (min-width: 992px) {

    }

    @media (max-width: 1199px) {

    }

    @media (min-width: 1200px) {

    }
</style>
