<template>
    <div class="component-info-icon">
        <button class="button-icon-question" :disabled="this.stShow" ref="containerIconQuestion" type="button" @click="containerIconQuestionClick">
            <img src="@/assets/img/ico/ico_question_mark_blue.svg" alt="">
        </button>
        <transition name="fade">
            <div class="container-content" v-if="show">
                <div class="container-text" v-html="text"></div>
                <div class="container-progress" v-if="this.stShow">
                    <div></div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
    export default {
        name: 'ComponentInfoIcon',
        directives: {},
        props: [
            'duration',
            'target',
            'text'
        ],
        mixins: [],
        components: {},//components
        data() {
            return {
                stShow: null,
                show: false
            }
        },//data
        computed: {},//computed
        methods: {

            appendButton(target) {
                if(target) {
                    target.appendChild(this.$refs.containerIconQuestion)
                }
            },

            //Events

            containerIconQuestionClick(e) {

                if(!this.show) {
                    this.show = true
                } else {
                    this.show = false
                }

            }

        },//methods
        watch: {

            target(value, oldValue) {
                this.appendButton(value)
            },

            show(value, oldValue) {

                if(value) {

                    if(this.duration) {

                        this.stShow = setTimeout(
                            () => {

                                this.show = false

                                this.stShow = null

                            },
                            parseInt(this.duration)
                        )

                    }

                }

            }

        },//watch
        created() {},//created
        mounted() {},//mounted
        updated() {},//updated
        destroyed() {},//destroyed
    }
</script>

<style scoped>
    .component-info-icon
    {

    }

    .button-icon-question
    {
        background-color: transparent;
        border: none;
        cursor: pointer;
        margin: 0;
        margin-left: 0.35em;
        padding: 0;
        vertical-align: middle;
    }

    .button-icon-question img
    {
        height: 1.1em;
    }

    .container-content
    {
        background-color: #29B1CC;
        border-radius: 3px;
        color: #fff;
        font-size: 0.7em;
        line-height: 140%;
        margin-bottom: 0.5em;
        padding: 0.5rem;
        text-align: left;
    }

    .container-content .container-progress
    {
        background-color: rgba(0, 0, 0, 0.2);
    }

    @media (max-width: 767px) {

    }

    @media (min-width: 768px) {

    }

    @media (min-width: 992px) {

    }

    @media (max-width: 1199px) {

    }

    @media (min-width: 1200px) {

    }
</style>
