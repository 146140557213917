<template>
    <div class="component-toast">
        <div :class="['toast', item.type]" :key="item.uuid" v-for="(item, index) in toastsReverse">
            <div class="container-icon" v-if="item.icon">
                <img :src="item.icon" alt="">
            </div>
            <div class="container-content" v-html="item.content"></div>
            <div class="container-button" v-if="item.showCloseButton">
                <button type="button" @click="buttonClose($event, item.uuid)">
                    <img src="~@/assets/img/ico/ico_delete.svg" alt="">
                </button>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'ComponentToast',
        props: [],
        mixins: [],
        components: {},//components
        data() {
            return {
                toasts: []
            }
        },//data
        computed: {

            toastsReverse() {
                return this.toasts.reverse()
            }

        },//computed
        methods: {

            add(obj) {

                const autoHide = obj.autoHide
                const autoHideDelay = obj.autoHideDelay || 5000
                const content = obj.content
                const icon = obj.icon
                const showCloseButton = obj.showCloseButton
                const type = obj.type
                const uuid = this.$helpers.uuidv4()

                this.toasts.push(
                    {
                        content,
                        icon,
                        showCloseButton,
                        type,
                        uuid
                    }
                )

                if(autoHide) {

                    setTimeout(
                        () => {
                            this.remove(uuid)
                        },
                        autoHideDelay
                    )

                }
            },

            remove(uuid) {
                this.toasts = this.toasts.filter(value => value.uuid !== uuid)
            },

            //Events

            buttonClose(e, uuid) {
                this.remove(uuid)
            }

        },//methods
        watch: {},//watch
        created() {

            this.$eventbus.$on(
                'ComponentToast/add',
                (obj) => {
                    this.add(obj)
                }
            )

            this.$eventbus.$on(
                'ComponentToast/remove',
                (uuid) => {
                    this.remove(uuid)
                }
            )

        },//created
        mounted() {},//mounted
        updated() {},//updated
        destroyed() {},//destroyed
    }
</script>

<style scoped>
    .component-toast
    {
        bottom: 0;
        left: 0;
        position: fixed;
        width: 100%;
        z-index: 10000;
    }

    .toast
    {
        display: flex;
        align-items: center;
        justify-content: space-between;

        background-color: #fff;
        border-radius: 4px;
        margin: 3px;
        padding: 1.25em;
        position: relative;

        animation-duration: 0.35s;
        animation-name: slideUp;
    }

    .toast.error
    {
        background-color: #D73542;
        color: #fff;
    }

    .toast.success
    {
        background-color: #28A345;
        color: #fff;
    }

    .toast.warning
    {
        background-color: #F9BD24;
        color: #000;
    }

    .container-icon
    {
        line-height: 100%;
        margin-right: 0.5em;
    }

    .container-icon img
    {
        height: 1.2em;
        width: 1.2em;
    }

    .container-content
    {
        flex: 1;

        line-height: 100%;
    }

    .container-button
    {

    }

    .container-button button
    {
        background-color: transparent;
        border: none;
        line-height: 100%;
    }

    .container-button button img
    {
        height: 0.8em;
        opacity: 0.55;
        width: 0.8em;
    }

    @keyframes slideUp {
        from {
            opacity: 0;
            top: 6px;
        }
        to {
            opacity: 1;
            top: 0;
        }
    }

    @media (max-width: 767px) {

        .container-content
        {
            flex: 1;

            line-height: 125%;
        }

    }

    @media (min-width: 768px) {

    }

    @media (min-width: 992px) {

    }

    @media (max-width: 1199px) {

    }

    @media (min-width: 1200px) {

    }
</style>
