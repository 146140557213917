<template>
    <div class="component-data-table">

        <template v-if="requesting">
            <ComponentLoading></ComponentLoading>
        </template>

        <template v-if="!requesting && rowsRaw">

            <div class="container-list-options">
                <div class="container-search-field">
                    <ComponentInputText :icon="require('@/assets/img/ico/ico_search.svg')" placeholder="Procurar na tabela" type="text" v-model="searchTerm"></ComponentInputText>
                </div>
                <div class="container-buttons" v-if="!hideButtonEditTable && $mq.match(/lg/)">
                    <button class="button-edit-table" type="button" @click="buttonEditColumnsClick" title="Configurar colunas da tabela">
                        <img src="~@/assets/img/ico/ico_edit_table_black.svg" alt="">
                    </button>
                </div>
            </div>

            <div class="container-table">
                <table>
                    <thead>
                        <tr>
                            <th :class="{ active: sort.columnId === item.id }" :key="index" v-for="(item, index) in _.filter(selectedColumns, { show: true })">
                                <button type="button" @click="buttonHeaderColumnClick($event, item)" title="Ordenar esta coluna">
                                    <span>{{ item.alias }}</span>
                                    <span class="icon-sort" v-if="sort.columnId === item.id">
                                        <span v-if="sort.order === 'desc'">&uarr;</span>
                                        <span v-else>&darr;</span>
                                    </span>
                                </button>
                            </th>
                            <th class="col-actions"></th>
                        </tr>
                    </thead>
                    <tbody>
                    <tr :class="[{ clickable: getRowClickCondition(rowsPaged[rowIndex]) && data.rowClick }]" :key="$helpers.getObjectHash(row)" :title="data.rowClickTooltip" v-for="(row, rowIndex) in rowsPaged" @click="trClick($event, data.rowClick, rowsPaged[rowIndex])">
                        <td :key="columnIndex" v-for="(column, columnIndex) in _.filter(selectedColumns, { show: true })">
                            {{ row[column.id] }}
                        </td>
                        <td class="col-actions">
                            <template v-for="(action, index) in actions">
                                <button type="button" :key="index" :title="action.tooltip" @click.stop="buttonActionClick($event, action, rowsPaged[rowIndex])" v-if="getActionShowCondition(action, rowsPaged[rowIndex])">
                                    <img :src="action.icon" alt="">
                                </button>
                            </template>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <div class="container-controls">
                <div class="col-left">
                    <div class="container-records-info">
                        <b>Total:</b> {{ totalRecords }} {{ totalRecords > 1 ? 'registros' : 'registro' }}
                    </div>
                </div>
                <div class="col-right">
                    <div class="container-pagination">
                        <template v-if="pages < 15 && $mq.match(/md|lg/)">
                            <button :class="{active: n === page}" type="button" :key="`page_${n}`" v-for="n in pages" @click="buttonPageNumberClick($event, n)">{{n}}</button>
                        </template>
                        <template v-else>
                            <label>Página:</label>
                            <span class="container-select">
                                <select v-model="page">
                                    <option :key="`page_${n}`" v-for="n in pages" :value="n">{{n}}</option>
                                </select>
                            </span>
                        </template>
                    </div>
                </div>
            </div>

        </template>

        <template v-if="!requesting && !rowsRaw">
            Erro ao carregar os dados
        </template>

        <ComponentModal class="component-modal-edit-columns" ref="modalEditColumns">
            <template v-slot:title>
                <img src="~@/assets/img/ico/ico_edit_table_black.svg" alt="">
                <span>Editar tabela</span>
            </template>
            <template v-slot:content>
                <div class="container-text-select-columns">Selecione as colunas que você deseja exibir:</div>
                <div class="container-checkbox-item" :key="index" v-for="(column, index) in columns">
                    <ComponentCheckbox :checked="selectedColumns[index].show === true" :label="column.alias" :ref="'checkboxItem_' + index" @input="checkboxColumnInput($event, column, index)"></ComponentCheckbox>
                </div>
                <div class="container-button-back-to-initial">
                    <hr>
                    <button @click="buttonTableToInitialStateClick">Retornar tabela ao estado inicial</button>
                </div>
            </template>
            <template v-slot:footer>
                <button type="button" class="btn-default btn-primary" @click="modalEditColumnsButtonCloseClick">Fechar</button>
            </template>
        </ComponentModal>

    </div>
</template>

<script>
    import ComponentCheckbox from "@/components/ui/ComponentCheckbox"
    import ComponentInputText from "@/components/ui/ComponentInputText"
    import ComponentLoading from "@/components/ui/ComponentLoading"
    import ComponentModal from "@/components/ui/ComponentModal"

    export default {
        name: 'ComponentDataTable',
        props: [
            'data',
            'hideButtonEditTable',
            'name'
        ],
        mixins: [],
        components: {
            ComponentCheckbox,
            ComponentInputText,
            ComponentLoading,
            ComponentModal
        },//components
        data() {
            return {
                
                page: 1,
              
                rowsPerPage: 40,
                
                searchTerm: '',
                
                selectedColumns: {},
                
                sort: {
                    columnId: '',
                    order: 'asc'
                },
                
                timestamp: null,

            }
        },//data
        computed: {

            actions() {
                return this.data.actions
            },

            columns() {
                return this.data.columns
            },

            columnsIds() {
                return this.columns.map(item => item.id)
            },

            firstRow() {
                return this.rows[0]
            },

            pages() {
                return Math.ceil(this.rowsFilteredSearch.length / this.rowsPerPage);
            },

            requesting() {
                return this.data.requesting
            },

            rows() {

                let rowsOutput = []

                this.rowsRaw.forEach(
                    (row, rowIndex, array) => {

                        const columns = this.columns

                        let newRow = {}

                        Object.keys(row).forEach(
                            (key, keyIndex, array) => {

                                const column = this._.find(columns, {id: key})
                                
                                if(column) {
                                    newRow[key] = this.applyColumnContent(row[column.id], column.id)
                                } else {
                                    newRow[key] = row[key];
                                }

                            }
                        )

                        rowsOutput.push(newRow)

                    }
                )

                rowsOutput = this._.orderBy(
                    rowsOutput,
                    [this.sort.columnId],
                    [this.sort.order]
                )  

                return rowsOutput

            },

            rowsFilteredSearch() {

                return this.rows.filter(
                    (row, rowIndex, array) => {

                        row = this.$helpers.removeDiacritics(this._.values(row).join('|')).toLowerCase()

                        return row.indexOf(this.searchTerm.toLowerCase()) >= 0

                    }
                )

            },

            rowsPaged() {
                return this.rowsFilteredSearch.slice(((this.page - 1) * this.rowsPerPage), ((this.page - 1) * this.rowsPerPage) + this.rowsPerPage)
            },

            rowsRaw() {
                return this.data.data
            },

            totalRecords() {

                let ret = 0

                if(this.rowsRaw) {
                    ret = this.rowsRaw.length
                }

                return ret

            }

        },//computed
        methods: {

            applyColumnContent(columnContent, columnId) {

                const column = this._.find(this.columns, { id: columnId })

                if(column.customContent) {
                    return column.customContent(columnContent)
                } else {
                    return columnContent
                }

            },

            filterRow(row) {

                const searchTerm = this.$helpers.removeDiacritics(this.searchTerm).toLowerCase()
                const selectedColumns = this.selectedColumns

                let count = 0
                let selectedColumnsIds = []

                if(selectedColumns && Array.isArray(selectedColumns)) {
                    selectedColumnsIds = selectedColumns.map(item => item.id)
                }

                if(this.searchTerm === '') {
                    count++
                }

                selectedColumnsIds.forEach(
                    (columnId, index, array) => {

                        const columnValue = this.$helpers.removeDiacritics(String(row[columnId])).toLowerCase()

                        if(columnValue.indexOf(searchTerm) >= 0) {
                            count++
                        }

                    }
                )

                return count > 0

            },

            getActionShowCondition(item, row) {

                const showCondition = item.showCondition

                let ret = true

                if(showCondition !== undefined) {

                    if(typeof showCondition === 'object') {

                        if(showCondition.rawCondition) {
                            if(!eval(showCondition.rawCondition)) {
                                ret = false
                            }
                        } else {

                            const action = showCondition.action
                            const subject = showCondition.subject

                            ret = this.$can(action, this.$caslSubject(subject, row))

                        }

                    } else {

                        ret = showCondition

                    }

                }

                return ret

            },

            getRowClickCondition(row) {

                let ret = true

                const rowClickCondition = this.data.rowClickCondition

                if(rowClickCondition !== undefined) {

                    if(typeof rowClickCondition === 'object') {

                        if(rowClickCondition.rawCondition) {
                            if(!eval(rowClickCondition.rawCondition)) {
                                ret = false
                            }
                        } else {
                            ret = this.$can(rowClickCondition.action, this.$caslSubject(rowClickCondition.subject, row))
                        }

                    } else {
                        ret = rowClickCondition
                    }

                }

                return ret

            },

            search() {

                this.rows.filter(
                    (row, rowIndex, array) => {

                        row = this._.values(row)

                        row = row.map(
                            item => {
                                if(typeof item === 'string') {
                                    return this.$helpers.removeDiacritics(item).toLowerCase()
                                } else {
                                    return ''
                                }
                            }
                        )

                        return row.indexOf(this.searchTerm) >= 0

                    }
                );

            },

            setInitiallyVisibleColumns() {

                const dataTableName = this.name
                const lsUiConfig = localStorage.getItem('ordexa/ui-config')
                const routePath = this.$route.path
                const uiConfig = JSON.parse(lsUiConfig)

                try {

                    if(this.columns.map( item => item.alias ).join() !== uiConfig.datatables[routePath][dataTableName].columns.map( item => item.alias ).join()) {

                        this.$helpers.removeUiConfig(
                            'datatables',
                            routePath,
                            dataTableName
                        )

                    }

                    if(uiConfig.datatables[routePath][dataTableName].columns) {
                        this.selectedColumns = this._.cloneDeep(uiConfig.datatables[routePath][dataTableName].columns)
                    }

                }
                catch(error) {
                    this.selectedColumns = this._.cloneDeep(this.columns)
                }

            },

            setSort(item) {

                if(this.sort.columnId === item.id) {

                    if(this.sort.order === 'asc') {
                        this.sort.order = 'desc'
                    } else {
                        this.sort.order = 'asc'
                    }

                } else {

                    this.sort.columnId = item.id

                    this.sort.order = 'asc'

                }

            },

            //Events

            buttonActionClick(e, action, row) {
                action.handler(e, action, row)
            },

            buttonEditColumnsClick(e) {
                this.$refs.modalEditColumns.show = true
            },

            buttonHeaderColumnClick(e, item) {
                this.setSort(item)
            },

            buttonPageNumberClick(e, page) {
                this.page = page;
            },

            buttonTableToInitialStateClick(e) {

                const dataTableName = this.name
                const routePath = this.$route.path

                this.$helpers.removeUiConfig(
                    'datatables',
                    routePath,
                    dataTableName
                )

                this.$emit('tableToInitialState')

            },

            checkboxColumnInput(e, column, index) {

                const dataTableName = this.name
                const routePath = this.$route.path

                if(e) {
                    this.selectedColumns[index].show = true
                } else {
                    this.selectedColumns[index].show = false
                }

                if(this._.filter(this.selectedColumns, { show: true }).length < 1) {

                    this.$eventbus.$emit(
                        'ComponentToast/add',
                        {
                            autoHide: true,
                            content: 'Pelo menos uma coluna deve permanecer selecionada na tabela',
                            icon: null,
                            showCloseButton: false,
                            type: 'error'
                        }
                    )


                    this.$nextTick(
                        () => {
                            this.selectedColumns[index].show = true
                        }
                    )

                } else {

                    this.$helpers.setUiConfig(
                        'datatables',
                        routePath,
                        dataTableName,
                        {
                            columns: this.selectedColumns
                        }
                    )

                }

            },

            modalEditColumnsButtonCloseClick(e) {
                this.$refs.modalEditColumns.show = false
            },

            trClick(e, rowClick, row) {

                const rowClickCondition = this.getRowClickCondition(row)

                if(rowClick && rowClickCondition) {
                    rowClick(e, row)
                }

            }

        },//methods
        watch: {

            searchTerm(value, oldValue) {
                this.page = 1;
            },

        },//watch
        created() {},//created
        beforeMount() {},//beforeMount
        mounted() {

            this.setInitiallyVisibleColumns()

            this.sort.columnId = this.selectedColumns[0].id

            this.timestamp = Date.now()

        },//mounted
        updated() {},//updated
        destroyed() {},//destroyed
    }
</script>

<style scoped>
    .component-data-table
    {
        width: 100%;
    }

    table
    {
        border: none;
        border-collapse: collapse;
        width: 100%;
    }

    thead
    {

    }

    th
    {
        background-color: #fff;
        border-bottom: 1px solid #eee;
        color: #555;
        font-size: 0.9em;
        padding: 0.75rem;
        text-align: left;
    }

    th:first-child
    {
        border-radius: 4px 0 0 0;
    }

    th:last-child
    {
        border-radius: 0 4px 0 0;
    }

    th button
    {
        background-color: transparent;
        border: none;
        font-weight: 600;
        margin: 0;
        padding: 0;
    }

    th button .icon-sort
    {
        font-weight: 600;
        margin-left: 0.25em;
    }

    th.active button
    {
        color: #29B1CC;
    }

    tbody
    {
    }

    td
    {
        background-color: #fff;
        border-bottom: 1px solid #eee;
        font-size: 0.85em;
        padding: 0.75rem;
    }

    tbody tr.clickable:hover td
    {
        background-color: #d7f2f7;
        cursor: pointer;
    }

    .col-actions
    {
        text-align: center;
        white-space: nowrap;
        width: 1px;
    }

    .col-actions button
    {
        background-color: transparent;
        border: none;
        margin: 0 0 0 0.5em;
        padding: 0 0.75em;
    }

    .col-actions button:first-child
    {
        margin: 0;
    }

    .container-list-options
    {
        display: flex;
        margin-bottom: 1em;
    }

    .container-list-options .container-search-field
    {
        flex: 1;
    }

    .container-list-options .container-search-field .component-input-text
    {
        width: 100%;
    }

    .container-list-options .container-buttons
    {

    }

    .container-list-options .container-buttons button
    {
        display: inline-flex;
        align-items: center;
        justify-content: center;

        background-color: #fff;
        border: 1px solid #fff;
        border-radius: 4px;
        height: 35px;
        padding: 0 0.75em;
    }

    .container-list-options .container-buttons button img
    {
        height: 1em;
    }

    .container-controls
    {
        display: flex;
        align-items: center;
        justify-content: space-between;

        margin-top: 0.5em;
    }

    .container-controls > div
    {
        
    }

    .container-controls .col-left
    {

    }

    .container-controls .col-right
    {
        text-align: right;
    }

    .container-controls .container-records-info
    {
        font-size: 0.8em;
    }

    .container-controls .container-pagination
    {
        
    }

    .container-controls .container-pagination button
    {
        background-color: rgba(0, 0, 0, 0.1);
        border: none;
        border-radius: 3px;
        margin-right: 0.5em;
        min-height: 2em;
        min-width: 2em;
    }

    .container-controls .container-pagination button.active
    {
        background-color: #29B1CC;
        color: #fff;
    }

    .container-controls .container-pagination button:last-child
    {
        margin-right: 0;
    }

    .container-controls .container-pagination label
    {
        font-size: 0.85em;
        font-weight: 500;
        margin-right: 0.5em;
    }

    .container-controls .container-pagination .container-select
    {
        background-color: #fff;
        border: 1px solid #ddd;
        border-radius: 3px;
        display: inline-block;
        padding: 0.25em;
    }

    .container-controls .container-pagination .container-select select
    {
        background-color: transparent;
        border: none;
        outline: none;
    }

    .component-modal-edit-columns .container-text-select-columns
    {
        margin-bottom: 1em;
    }

    .component-modal-edit-columns .container-checkbox-item
    {
        font-size: 1.1em;
        line-height: 200%;
    }

    .container-requesting
    {
        display: flex;
        align-items: center;
        justify-content: center;

        font-size: 1.5em;
        opacity: 0.5;
        padding: 1em;
    }

    .container-requesting img
    {
        height: 1.25em;
        margin-right: 0.5em;
    }

    .container-button-back-to-initial
    {

    }

    .container-button-back-to-initial button
    {
        background-color: transparent;
        border: none;
        font-size: 0.9em;
        margin: 0;
        opacity: 0.75;
        padding: 0;
        text-decoration: underline;
    }

    @media (max-width: 767px) {

    }

    @media (min-width: 768px) {

    }

    @media (min-width: 991px) {

        .container-controls .col-left
        {
            flex: 1;
        }

        .container-controls .col-right
        {
            flex: 1;
        }

    }

    @media (min-width: 992px) {

    }

    @media (max-width: 1199px) {

        td
        {
            word-break: break-all;
        }

    }

    @media (min-width: 1200px) {

        .container-list-options .container-buttons
        {
            margin-left: 0.5em;
        }

        .container-list-options .container-buttons button img
        {

        }

        .container-controls .col-left
        {
            flex: 1;
        }

        .container-controls .col-right
        {
            flex: 2;
        }

    }
</style>
