export default {
    data() {
        return {}
    },//data
    computed: {},
    methods: {

        checkRulesAndRedirect(to, next) {

            const user = this.$store.getters['App/user']
            const rules = this.$helpers.processRules(user.rules)
            const subject = to.meta.rules.subject

            let subjectConditions = {};

            rules.map(
                (rule, ruleIndex, ruleArray) => {
                    if(rule.subject.indexOf(subject) >= 0) {
                        this._.merge(subjectConditions, rule.conditions);
                    }
                }
            )

            let rulesConditions = null

            if(to.meta.rules.conditions) {

                if(Object.keys(subjectConditions).some(
                    item => {
                        return Object.keys(to.meta.rules.conditions).includes(item)
                    }
                )) {
                    rulesConditions = to.meta.rules.conditions
                }

            }

            const subjectAndConditions = rulesConditions ? this.$caslSubject(to.meta.rules.subject, rulesConditions) : to.meta.rules.subject

            if (
                !this.$can('manage', subjectAndConditions) &&
                !this.$can('read', subjectAndConditions) &&
                !this.$can('show', subjectAndConditions)
            ) {

                this.$eventbus.$emit(
                    'ComponentToast/add',
                    {
                        autoHide: true,
                        content: 'Você não tem permissão para acessar esta tela ou o registro especificado',
                        icon: null,
                        showCloseButton: false,
                        type: 'error'
                    }
                )

                if(next) {
                    next({ name: 'AccessDenied' })
                } else {
                    this.$router.replace({ name: 'AccessDenied' })
                }

            } else {
                if(next) {
                    next()
                }
            }

        },

    },//methods
    watch: {},//watch
    beforeRouteEnter(to, from, next) {
        next(
            vm => {
                if(to.meta) {
                    if (to.meta.rules) {
                        vm.checkRulesAndRedirect(to)
                    }
                }
            }
        )
    },//beforeRouteEnter
    beforeRouteUpdate(to, from, next) {
        if(to.meta) {
            if(to.meta.rules) {
                this.checkRulesAndRedirect(to, next)
            } else {
                next()
            }
        } else {
            next()
        }
    },//beforeRouteUpdate
    beforeRouteLeave(to, from, next) {
        next()
    },//beforeRouteLeave
    created() {},//created
    beforeMount() {

        const lsUser = localStorage.getItem('ordexa/user')

        if(lsUser) {

            const user = JSON.parse(lsUser)

            this.$store.dispatch('App/setUser', user)

            this.$ability.update(this.$helpers.processRules(user.rules))

        }

    },//beforeMount
    mounted() {}//mounted
}
