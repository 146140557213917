<template>
    <div class="component-print" v-if="show">
        <div class="container-dialog">
            <header>
                <div>
                    <div class="container-title">VISUALIZAR IMPRESSÃO</div>
                    <div class="container-select-paper-size" v-if="$mq.match(/lg/)">
                        <span>Gerar no tamanho: </span>
                        <ComponentDropdown :items="dropdownPaperSizeItems" :selectedIndex="0" @change="dropdownPaperSizeChange"></ComponentDropdown>
                    </div>
                    <div class="container-buttons" v-if="$mq.match(/lg/)">
                        <button type="button" @click="buttonEditHeaderFooterClick">EDITAR CABEÇALHO E RODAPÉ</button>
                        <a :href="docUrl" target="_blank">
                            <img src="~@/assets/img/ico/ico_new_window.svg" alt="">
                        </a>
                    </div>
                </div>
                <button class="button-close" type="button" @click="buttonCloseClick">
                    <img src="~@/assets/img/ico/ico_exit_button.svg" alt="">
                </button>
            </header>
            <div class="container-main" v-if="$mq.match(/lg/)">

                <div class="container-loading" v-if="pdf.loading">
                    Carregando documento
                </div>
                <template v-if="selectedPaperSize">

                    <object :class="{ hidden: pdf.loading }" :data="docUrl" type="application/pdf" @load="objectPdfLoad">
                        <iframe :src="docUrl" @load="objectPdfLoad">
                            <div>Seu navegador não suporta a visualização de PDF. <a :href="docUrl">Clique aqui</a> para fazer o download.</div>
                        </iframe>
                    </object>

                </template>

            </div>
            <div class="container-main-mobile" v-else>

                <div class="container-select-paper-size">
                    <span>Tamanho:</span>
                    <ComponentDropdown :items="dropdownPaperSizeItems" :selectedIndex="0" @change="dropdownPaperSizeChange"></ComponentDropdown>
                </div>
                <div class="container-button-open">
                    <button class="btn-default btn-primary" @click="buttonOpenPdfClick">Abrir PDF</button>
                </div>

                <div class="container-button-edit-header-footer">
                    <button type="button" @click="buttonEditHeaderFooterClick">
                        <span>EDITAR CABEÇALHO E RODAPÉ</span>
                    </button>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
    import ComponentDropdown from "@/components/ui/ComponentDropdown"

    export default {
        name: 'ComponentPrint',
        props: [],
        mixins: [],
        components: {
            ComponentDropdown
        },//components
        data() {
            return {

                baseUrl: null,

                companyId: null,

                companyHash: null,

                content: null,

                dropdownPaperSizeItems: [
                    {
                        icon: null,
                        label: 'A5',
                        value: 'a5',
                    },
                    {
                        icon: null,
                        label: 'A4',
                        value: 'a4',
                    },
                ],

                noteId: null,

                noteHash: null,

                pdf: {
                    loading: false
                },

                selectedPaperSize: null,

                show: false

            }
        },//data
        computed: {

            company() {
                return this.$store.getters['Companies/company']
            },

            docUrl() {

                let ret = this.baseUrl

                if(this.companyHash) {
                    ret = ret.replace('{companyHash}', this.companyHash)
                }

                if(this.noteHash) {
                    ret = ret.replace('{noteHash}', this.noteHash)
                }

                if(this.selectedPaperSize) {
                    ret = ret.replace('{pageSize}', this.selectedPaperSize.label)
                }

                return ret

            }

        },//computed
        methods: {

            close() {

                this.baseUrl = null
                this.noteId = null

                this.show = false

            },

            open() {
                this.show = true
            },

            //Events

            buttonCloseClick(e) {
                this.close()
            },

            buttonEditHeaderFooterClick(e) {

                this.close()

                this.$router.push({ name: 'PrintLayout' }, () => {}, () => {})

            },

            buttonOpenPdfClick(e) {
                window.open(this.docUrl, '_blank')
            },

            dropdownPaperSizeChange(item) {
                this.selectedPaperSize = item
            },

            objectPdfLoad(e) {
                this.pdf.loading = false
            }

        },//methods
        watch: {

            selectedPaperSize(value, oldValue) {

                if(this.$mq.match(/lg/)) {
                    this.pdf.loading = true
                }

            }

        },//watch
        created() {

            this.$eventbus.$on(
                'ComponentPrint/open',
                obj => {

                    this.baseUrl = obj.baseUrl

                    if(obj.companyHash) {
                        this.companyHash = obj.companyHash
                    }

                    if(obj.noteHash) {
                        this.noteHash = obj.noteHash
                    }

                    this.open()

                }
            )

            this.$eventbus.$on(
                'ComponentToast/close',
                () => {
                    this.close()
                }
            )

        },//created
        mounted() {},//mounted
        updated() {},//updated
        destroyed() {},//destroyed
    }
</script>

<style scoped>
    @media screen {

        .component-print
        {
            display: flex;
            align-items: center;
            justify-content: center;

            background-color: rgba(0, 0, 0, 0.5);
            height: 100%;
            left: 0;
            position: fixed;
            top: 0;
            width: 100%;
            z-index: 10000;
        }

        .container-dialog
        {
            display: flex;
            flex-direction: column;

            background-color: #fff;
            border-radius: 5px;
            box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.35);
            overflow: hidden;
        }

        header
        {
            display: flex;
            align-items: center;
            justify-content: space-between;

            background-color: #f0f0f0;
            padding: 1rem;
        }

        header :disabled
        {
            opacity: 0.15;
        }

        header > div
        {
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: space-between;

            margin-right: 2em;
        }

        header .container-title
        {
            font-weight: 400;
        }

        header .container-select-paper-size
        {

        }

        header .container-select-paper-size span
        {
            font-size: 0.9em;
        }

        header .container-buttons
        {

        }

        header .container-buttons button
        {
            background-color: transparent;
            border: 1px solid #111;
            border-radius: 3px;
            font-size: 0.7em;
            margin: 0;
            margin-left: 1em;
            padding: 0.35em 0.7em;
            vertical-align: middle;
        }

        header .container-buttons a img,
        header .container-buttons button img
        {
            height: 1.3em;
        }

        header .container-buttons a
        {
            margin-left: 1.5em;
            vertical-align: middle;
        }

        header .button-close
        {
            background-color: transparent;
            border: none;
            margin: 0;
            padding: 0;
        }

        header .button-close img
        {
            height: 1.4em;
        }

        .container-main
        {
            flex: 1;
            display: flex;

            background-color: #555;
            width: 100%;
        }

        .container-main object
        {
            flex: 1;
            display: flex;
        }

        .container-main object.hidden
        {
            height: 0;
            max-width: 0;
            max-height: 0;
            opacity: 0;
            width: 0;
        }

        .container-main iframe
        {
            flex: 1;
        }

        .container-main .container-loading
        {
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: center;

            color: #fff;
            font-size: 1.5em;
            font-weight: 100;
            opacity: 0.5;
        }

        .container-main-mobile
        {
            text-align: center;
        }

        .container-main-mobile .container-button-edit-header-footer
        {
            border-top: 1px solid #ddd;
            padding: 0.75em 0;
        }

        .container-main-mobile .container-button-edit-header-footer button
        {
            font-size: 0.8em;
        }

        .container-main-mobile .container-button-edit-header-footer button span
        {
            color: #29B1CC;
            text-decoration: underline;
        }

        .container-main-mobile .container-select-paper-size
        {
            margin-bottom: 1em;
            padding: 2em 0 0;
        }

        .container-main-mobile .container-select-paper-size span
        {
            margin-right: 0.5em;
        }

        .container-main-mobile .container-button-open
        {
            padding: 0 0 2em;
        }

        .container-paper
        {
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            background-color: #fff;
            box-shadow: 0 3px 18px 0 rgba(0, 0, 0, 0.35);
            margin: 0 auto;
        }

        .container-paper.a5
        {
            height: 630px;
            width: 444px;
        }

        .container-paper.a4
        {
            height: 945px;
            width: 666px;
        }

        .container-paper .container-header
        {
            display: flex;

            padding: 1.2rem;
        }

        .container-paper .container-header .container-image
        {
            margin-right: 0.75em;
            min-height: 0;
            min-width: 0;
            width: 32%;
        }

        .container-paper .container-header .container-image img
        {
            max-height: 1.5cm;
            max-width: 4cm;
            width: 100%;
        }

        .container-paper .container-header .container-text
        {
            flex: 1;

            font-size: 0.87em;
            min-height: 0;
            padding: 0;
            text-align: right;
        }

        .container-paper .container-content
        {
            display: flex;
            flex-direction: column;
            justify-content: center;

            font-size: 0.92em;
            min-height: 0;
            padding: 1.2rem;
        }

        .container-paper .container-footer
        {
            padding: 1.2rem;
        }

        .container-paper .container-footer .container-text
        {
            flex: 1;

            font-size: 0.87em;
            min-height: 0;
            padding: 0;
            text-align: center;
        }

        .table-print
        {
            display: none;
        }

        @media (max-width: 767px) {

        }

        @media (min-width: 768px) {

        }

        @media (min-width: 992px) {

        }

        @media (max-width: 1199px) {

            .container-dialog
            {
                min-width: 88vw;
            }

        }

        @media (min-width: 1200px) {

            .container-dialog
            {
                min-width: 960px;
                max-height: 80vh;
                min-height: 600px;
            }

        }

    }

    @media print {

        .component-print
        {
            height: 100%;
            line-height: 120% !important;
            width: 100%;
        }

        .component-print *
        {
            overflow: visible;
        }

        .container-dialog
        {
            display: none !important;
        }

        .table-print
        {
            height: 100%;
            width: 100%;
        }

        .table-print thead td
        {
            vertical-align: middle;
        }

        .table-print thead img
        {
            float: left;
            max-height: 3cm;
            max-width: 36%;
            vertical-align: top;
        }

        .table-print thead .container-print-header
        {
            float: right;
            font-size: 11pt;
            min-height: 0;
            text-align: right;
            vertical-align: top;
        }

        .table-print thead .container-print-content
        {
            min-height: 0;
        }

        .table-print tfoot td
        {
            text-align: center;
        }

        .table-print .container-print-footer
        {
            font-size: 10pt;
            min-height: 0;
        }

    }
</style>
