<template>
    <div class="component-modal" v-if="show">
        <div class="container-dialog">
            <header>
                <span>
                    <slot name="title"></slot>
                </span>
                <button type="button" @click="buttonTimesClick">
                    <img src="~@/assets/img/ico/ico_exit_button.svg" alt="">
                </button>
            </header>
            <main>
                <slot name="content"></slot>
            </main>
            <footer>
                <slot name="footer"></slot>
            </footer>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'ComponentModal',
        props: [],
        mixins: [],
        components: {},//components
        data() {
            return {
                show: false
            }
        },//data
        computed: {},//computed
        methods: {

            buttonTimesClick(e) {
                this.show = false
            }

        },//methods
        watch: {},//watch
        created() {},//created
        mounted() {},//mounted
        updated() {},//updated
        destroyed() {},//destroyed
    }
</script>

<style scoped>
    .component-modal
    {
        display: flex;
        align-items: center;
        justify-content: center;

        background-color: rgba(0, 0, 0, 0.5);
        height: 100%;
        left: 0;
        position: fixed;
        top: 0;
        width: 100%;
        z-index: 10000;
    }

    .container-dialog
    {
        background-color: #fff;
        border-radius: 5px;
        box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.35);
    }

    .container-dialog header
    {
        display: flex;
        justify-content: space-between;

        padding: 1rem;
    }

    .container-dialog header span
    {
        font-size: 1.1em;
        font-weight: 400;
    }

    .container-dialog header span img
    {
        height: 1em;
        margin-right: 0.5em;
        vertical-align: middle;
    }

    .container-dialog header span span
    {
        vertical-align: middle;
    }

    .container-dialog header button
    {
        background-color: transparent;
        border: none;
        margin: 0;
        padding: 0;
    }

    .container-dialog header button img
    {
        height: 1.5em;
    }

    .container-dialog main
    {
        flex: 1;
        padding: 1em;
    }

    .container-dialog footer
    {
        padding: 1em;
        text-align: right;
    }

    .container-dialog footer button
    {
        font-size: 0.9em;
        margin-left: 0.55em;
    }

    @media (max-width: 767px) {

    }

    @media (min-width: 768px) {

    }

    @media (min-width: 992px) {

    }

    @media (max-width: 1199px) {

        .container-dialog
        {
            max-width: 90%;
        }

    }

    @media (min-width: 1200px) {

        .container-dialog
        {
            min-width: 450px;
        }

    }
</style>
