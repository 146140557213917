<template>
    <section id="view-password-change">

        <ComponentViewTabs class="component-view-tabs" :tabs="viewTabs"></ComponentViewTabs>

        <div class="container-view-content">
            <ComponentPersonalProfilePasswordChange
                ref="componentPersonalProfilePasswordChange"
                :user="user"
                @requestingDone="componentPersonalProfilePasswordChangeRequestingDone"
                @requestingUpdate="componentPersonalProfilePasswordChangeRequestingUpdate"
                @updated="componentPersonalProfilePasswordChangeUpdated"/>
            <div class="container-buttons">
                <button class="btn-default btn-primary" :disabled="requesting" @click="buttonSaveClick">Salvar</button>
            </div>
        </div>

    </section>
</template>

<script>
    import MixinView from '@/mixins/MixinView.js'
    import MixinPersonalProfile from '@/mixins/PersonalProfile/MixinPersonalProfile.js'

    import ComponentLoading from "@/components/ui/ComponentLoading"
    import ComponentPersonalProfilePasswordChange from "@/components/ComponentPersonalProfilePasswordChange"

    export default {
        name: 'PasswordChange',
        props: [],
        mixins: [MixinView, MixinPersonalProfile],
        components: {
            ComponentLoading,
            ComponentPersonalProfilePasswordChange
        },//components
        data() {
            return {

                model: {},

                requesting: false,

            }
        },//data
        computed: {

            user() {
                return this.$store.getters['App/user']
            }

        },//computed
        methods: {

            //Events

            buttonSaveClick(e) {

                const password = this.$refs.componentPersonalProfilePasswordChange.model.password
                const passwordConfirmation = this.$refs.componentPersonalProfilePasswordChange.model.passwordConfirmation

                if(password && passwordConfirmation) {

                    if(password !== passwordConfirmation) {

                        this.$eventbus.$emit(
                            'ComponentToast/add',
                            {
                                autoHide: true,
                                content: 'O campo CONFIRME A NOVA SENHA deve ser igual ao campo NOVA SENHA',
                                icon: null,
                                showCloseButton: false,
                                type: 'error'
                            }
                        )

                        return false

                    } else {
                        this.$refs.componentPersonalProfilePasswordChange.updatePassword()
                    }

                }

            },

            componentPersonalProfilePasswordChangeRequestingDone() {
                this.requesting = false
            },

            componentPersonalProfilePasswordChangeRequestingUpdate() {
                this.requesting = true
            },

            componentPersonalProfilePasswordChangeUpdated() {

                this.model = {}

            },

        },//methods
        watch: {},//watch
        created() {},//created
        mounted() {},//mounted
        updated() {},//updated
        destroyed() {},//destroyed
        beforeRouteEnter(to, from, next) {
            next(
                vm => {
                }
            )
        },//beforeRouteEnter
        beforeRouteUpdate(to, from, next) {
            next()
        },//beforeRouteUpdate
        beforeRouteLeave(to, from, next) {
            next()
        }//beforeRouteLeave

    }
</script>

<style scoped>
    #view-password-change
    {

    }

    .component-view-tabs
    {
        margin-bottom: 1.5em;
    }

    .container-view-content
    {
        background-color: #fff;
        border-radius: 5px;
        box-sizing: border-box;
        margin: 0 auto;
        padding: 1em;
    }

    .container-buttons
    {
        margin-top: 1em;
        text-align: right;
    }

    @media (max-width: 767px) {

    }

    @media (min-width: 768px) and (max-width: 1199px) {

    }

    @media (max-width: 1199px) {

        .container-view-content
        {
            width: 100%;
        }

    }

    @media (min-width: 1200px) {

        .container-view-content
        {
            width: 400px;
        }

    }
</style>
