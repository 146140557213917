//State
const state = {

    headers: {},

    session: {},

    user: {}

}

//Getters
const getters = {

    companyId(state) {
        return state.user.company_id
    },

    headers(state) {
        return state.headers
    },

    rulesProcessed(state) {

        const helpers = require('@/helpers').default

        return helpers.processRules(state.user.rules)

    },

    session(state) {
        return state.session
    },

    user(state) {
        return state.user
    },

    userId(state) {
        return state.user.id
    },

}

//Actions
const actions = {

    setHeaders(context, obj) {

        const accessToken = obj["access-token"]
        const client = obj["client"]
        const contentType = obj["content-type"]
        const tokenType = obj["token-type"]
        const expiry = obj["expiry"]
        const uid = obj["uid"]

        localStorage.setItem('ordexa/headers/access-token', accessToken)
        localStorage.setItem('ordexa/headers/client', client)
        localStorage.setItem('ordexa/headers/content-type', contentType)
        localStorage.setItem('ordexa/headers/expiry', expiry)
        localStorage.setItem('ordexa/headers/token-type', tokenType)
        localStorage.setItem('ordexa/headers/uid', uid)

        context.commit('SET_HEADERS', obj)

    },

    setSession(context, obj) {

        const authTime = obj["authTime"]
        const expireAt = obj["expireAt"]

        sessionStorage.setItem('ordexa/session/auth-time', authTime)
        sessionStorage.setItem('ordexa/session/expire-at', expireAt)

        context.commit('SET_SESSION', obj)

    },

    setUser(context, obj) {

        localStorage.setItem('ordexa/user', JSON.stringify(obj))
        localStorage.setItem('ordexa/user/attachment-url', obj.image_url)
        localStorage.setItem('ordexa/user/email', obj.email)
        localStorage.setItem('ordexa/user/name', obj.name)

        context.commit('SET_USER', obj)

    },

    logout(context) {

        localStorage.removeItem('ordexa/company/id')
        localStorage.removeItem('ordexa/company/hash')

        localStorage.removeItem('ordexa/headers/access-token')
        localStorage.removeItem('ordexa/headers/client')
        localStorage.removeItem('ordexa/headers/content-type')
        localStorage.removeItem('ordexa/headers/expiry')
        localStorage.removeItem('ordexa/headers/token-type')
        localStorage.removeItem('ordexa/headers/uid')

        localStorage.removeItem('ordexa/user')
        localStorage.removeItem('ordexa/user/attachment-url')
        localStorage.removeItem('ordexa/user/email')
        localStorage.removeItem('ordexa/user/name')

        sessionStorage.removeItem('ordexa/session/auth-time')
        sessionStorage.removeItem('ordexa/session/expire-at')

        context.commit('LOGOUT')

    }

}

//Mutations
const mutations = {

    LOGOUT(state) {
        state.headers = {}
        state.session = {}
        state.user = {}
    },

    SET_HEADERS(state, obj) {
        state.headers = obj
    },

    SET_SESSION(state, obj) {
        state.session = obj
    },

    SET_USER(state, obj) {
        state.user = obj
    }

}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
