<template>
    <div class="component-checkbox-group">
        <div :key="index" v-for="(option, index) in items">
            <ComponentCheckbox :checked="isChecked(option)" :label="option" :item="option" @input="componentCheckboxInput($event, index, option)"></ComponentCheckbox>
        </div>
    </div>
</template>

<script>
    import ComponentCheckbox from "@/components/ui/ComponentCheckbox"

    export default {
        name: 'ComponentCheckboxGroup',
        props: [
            'items',
            'value'
        ],
        mixins: [],
        components: {
            ComponentCheckbox
        },//components
        data() {
            return {

                model: []

            }
        },//data
        computed: {},//computed
        methods: {

            componentCheckboxInput(value, index, option) {

                if(value) {

                    this.model.push(
                        {
                            index,
                            label: option
                        }
                    )

                } else {

                    const itemIndex = this.model.indexOf(option)

                    this.model.splice(itemIndex, 1)

                }

            },

            isChecked(option) {

                const value = this.value

                let ret = false

                if(value) {
                    if(value.indexOf(option) >= 0) {
                        ret = true
                    }
                }

                return ret

            },

            setChecked() {

                if(this.value) {
                    this.model = this._.cloneDeep(this.value)
                }

            },

        },//methods
        watch: {

            model: {
                deep: true,
                handler: function(value, oldValue) {
                    this.$emit('change', value)
                }
            },

            value(value, oldValue) {
                this.setChecked()
            }

        },//watch
        created() {},//created
        mounted() {
            this.setChecked()
        },//mounted
        updated() {},//updated
        destroyed() {},//destroyed
    }
</script>

<style scoped>
    .component-checkbox-group
    {

    }

    .component-checkbox-group > div
    {
        margin-bottom: 0.5em;
    }

    @media (max-width: 767px) {

    }

    @media (min-width: 768px) {

    }

    @media (min-width: 992px) {

    }

    @media (max-width: 1199px) {

    }

    @media (min-width: 1200px) {

    }
</style>
