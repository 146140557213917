export default {
    computed: {},
    methods: {

        checkSubscriptionStatus(companyId) {
            return this.$fetch.get(`/api/v1/companies/${companyId}/subscription_status`);
        },

        async setAuth(response) {

            const redirectTo = this.$route.params.redirectTo

            const sessionDurationMs = 43200000
            const timestampMsNow = this.$moment().valueOf()

            const user = response.data.data
            const headers = response.headers
            const session = {
                authTime: timestampMsNow,
                expireAt: timestampMsNow + sessionDurationMs
            }

            const company = user.company

            this.$store.dispatch('Companies/setCompany', company)

            this.$store.dispatch('App/setHeaders', headers)

            this.$store.dispatch('App/setSession', session)

            this.$store.dispatch('App/setUser', user)

            this.$ability.update(this.$helpers.processRules(user.rules))

            const { data: subscriptionStatus } = await this.checkSubscriptionStatus(company.id);

            if (subscriptionStatus.attributes.suspended) {
                this.$router.push({
                        name: 'SubscriptionSuspended',
                        query: {
                            a: user.account_type === 'system_admin' ? '68ed1c7decda36085c86df62823cde17' : 'd63c7ede8cb1e1c8db5e51c63fd47cff',
                        }
                    },
                    () => {},
                    () => {}
                );
            } else {

                if (user.onboarding_completed) {

                    if (redirectTo) {
                        this.$router.replace({ path: redirectTo }, () => {}, () => {})
                    } else {
                        this.$router.replace({ name: 'Customers' }, () => {}, () => {})
                    }

                } else {
                    this.$router.replace({ name: 'OnboardingWelcome' }, () => {}, () => {})
                }

            }

        },

    }, //methods
    watch: {}, //watch
    beforeRouteEnter(to, from, next) {
        next(
            vm => {}
        )
    }, //beforeRouteEnter
    beforeRouteUpdate(to, from, next) {
        next()
    }, //beforeRouteUpdate
    beforeRouteLeave(to, from, next) {
        next()
    }, //beforeRouteLeave
    created() {}, //created
    beforeMount() {}, //beforeMount
    mounted() {} //mounted
}