import ComponentViewTabs from "@/components/ui/ComponentViewTabs"

export default {
    components: {
        ComponentViewTabs
    },//components
    data() {
        return {}
    },//data
    computed: {

        viewTabs() {

            let labelNoteTemplates = ''
            let labelPrintLayout = ''

            if(this.$mq.match(/lg/)) {
                labelNoteTemplates = 'Modelos de anotação'
                labelPrintLayout = 'Layout de impressão'
            } else {
                labelNoteTemplates = 'Modelos'
                labelPrintLayout = 'Impressão'
            }

            return [
                {
                    label: labelNoteTemplates,
                    to: '/note-templates'
                },
                {
                    label: labelPrintLayout,
                    to: '/print-layout'
                }
            ]

        }

    },
    methods: {},//methods
    watch: {},//watch
    beforeRouteEnter(to, from, next) {
        next(
            vm => {
            }
        )
    },//beforeRouteEnter
    beforeRouteUpdate(to, from, next) {
        next()
    },//beforeRouteUpdate
    beforeRouteLeave(to, from, next) {
        next()
    },//beforeRouteLeave
    created() {},//created
    mounted() {}//mounted
}
