export default {
    computed: {},
    methods: {},//methods
    watch: {},//watch
    beforeRouteEnter(to, from, next) {
        next(
            vm => {

            }
        )
    },//beforeRouteEnter
    beforeRouteUpdate(to, from, next) {
        next()
    },//beforeRouteUpdate
    beforeRouteLeave(to, from, next) {
        next()
    },//beforeRouteLeave
    created() {},//created
    beforeMount() {},//beforeMount
    mounted() {}//mounted
}
