<template>
    <div class="component-radio-group">
        <button type="button" @click="click($event, index, item)" :key="index" v-for="(item, index) in items">
            <span :class="['radio', { checked: index === selected.index }]">
                <span></span>
            </span>
            <span class="label">{{ item }}</span>
        </button>
    </div>
</template>

<script>
    export default {
        name: 'ComponentRadioGroup',
        props: [
            'items',
            'value'
        ],
        mixins: [],
        components: {},//components
        data() {
            return {

                selected: {
                    index: null,
                    value: null
                }

            }
        },//data
        computed: {},//computed
        methods: {

            setSelected() {

                if(this.value) {

                    if(this.items.indexOf(this.value) >= 0) {
                        this.selected.index = this.items.indexOf(this.value)
                        this.selected.value = this.value
                    }

                }

            },

            click(e, index, item) {
                this.selected.index = index
                this.selected.value = item
            }

        },//methods
        watch: {

            selected: {
                deep: true,
                handler: function(value, oldValue) {
                    this.$emit('change', this.selected)
                }
            },

            value(value, oldValue) {
                this.setSelected()
            }

        },//watch
        created() {},//created
        mounted() {
            this.setSelected()
        },//mounted
        updated() {},//updated
        destroyed() {},//destroyed
    }
</script>

<style scoped>
    .component-radio-group
    {

    }

    button
    {
        background-color: transparent;
        border: none;
        display: block;
        margin: 0;
        margin-bottom: 0.5em;
        padding: 0;
    }

    button:focus .radio
    {
        box-shadow: 0 0 2px 2px rgba(41, 177, 204, 0.4);
    }

    .radio
    {
        display: inline-flex;
        align-items: center;
        justify-content: center;

        background-color: #fff;
        border: 1px solid #d0d0d0;
        border-radius: 100px;
        height: 14px;
        margin-right: 0.35em;
        vertical-align: middle;
        width: 14px;
    }

    .radio.checked
    {
        background-color: #29B1CC;
        border-color: #29B1CC;
    }

    .radio.checked span
    {
        background-color: #fff;
        border-radius: 100px;
        height: 6px;
        width: 6px;
    }

    .label
    {
        font-weight: 300;
        vertical-align: middle;
    }

    @media (max-width: 767px) {

    }

    @media (min-width: 768px) {

    }

    @media (min-width: 992px) {

    }

    @media (max-width: 1199px) {

    }

    @media (min-width: 1200px) {

    }
</style>
