<template>
    <div class="component-view-tabs">
        <router-link active-class="active" :key="index" tag="button" :to="item.to" v-for="(item, index) in tabs">{{ item.label }}</router-link>
    </div>
</template>

<script>
    export default {
        name: 'ComponentViewTabs',
        props: ['tabs'],
        mixins: [],
        components: {},//components
        data() {
            return {}
        },//data
        computed: {},//computed
        methods: {},//methods
        watch: {},//watch
        created() {},//created
        mounted() {},//mounted
        updated() {},//updated
        destroyed() {},//destroyed
    }
</script>

<style scoped>
    .component-view-tabs
    {
        border-bottom: 1px solid #999;
    }

    button
    {
        background-color: transparent;
        border: none;
        border-bottom: 3px solid transparent;
        font-size: 1.1em;
        font-weight: 300;
        margin-right: 1.5em;
        opacity: 0.5;
        padding: 0.5em 0;
    }

    .active
    {
        border-bottom-color: #29B1CC;
        opacity: 1;
    }

    @media (max-width: 767px) {

    }

    @media (min-width: 768px) {

    }

    @media (min-width: 992px) {

    }

    @media (max-width: 1199px) {

    }

    @media (min-width: 1200px) {

    }
</style>
