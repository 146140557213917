<template>
    <section id="view-business-form">

        <ComponentViewTabs class="component-view-tabs" :tabs="viewTabs"></ComponentViewTabs>

        <div class="container-view-content">

            <div class="container-view-header">
                <div class="col-left">
                    <h1>Campos de cadastro do {{ $store.getters['Companies/customerTreatmentSingular'].toLowerCase() }}</h1>
                </div>
                <div class="col-right">
                    <button type="button" class="btn-default btn-primary" @click="buttonGoToCustomersClick">Ir para {{ $store.getters['Companies/customerTreatmentPlural'].toLowerCase() }}</button>
                </div>
            </div>

            <p>Configure os campos que você quer nos formulários de cadastro dos {{ $store.getters['Companies/customerTreatmentPlural'].toLowerCase() }}. Quando você adiciona ou altera perguntas, ele já altera o cadastro de todos os {{ $store.getters['Companies/customerTreatmentPlural'].toLowerCase() }}.</p>

            <ComponentFormBuilder></ComponentFormBuilder>

        </div>

    </section>
</template>

<script>
    import MixinView from '@/mixins/MixinView.js'
    import MixinBusiness from '@/mixins/Business/MixinBusiness'

    import ComponentFormBuilder from "@/components/FormBuilder/ComponentFormBuilder"

    export default {
        name: 'BusinessForm',
        props: [],
        mixins: [MixinView, MixinBusiness],
        components: {
            ComponentFormBuilder
        },//components
        data() {
            return {}
        },//data
        computed: {},//computed
        methods: {

            //Events

            buttonGoToCustomersClick(e) {
                this.$router.push({ name: 'Customers' }, () => {}, (error) => {})
            },

        },//methods
        watch: {},//watch
        created() {},//created
        mounted() {},//mounted
        updated() {},//updated
        destroyed() {},//destroyed
        beforeRouteEnter(to, from, next) {
            next(
                vm => {
                }
            )
        },//beforeRouteEnter
        beforeRouteUpdate(to, from, next) {
            next()
        },//beforeRouteUpdate
        beforeRouteLeave(to, from, next) {
            next()
        }//beforeRouteLeave

    }
</script>

<style scoped>
    #view-business-form
    {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: flex-start;
    }

    .component-view-tabs
    {
        margin-bottom: 1.5em;
        width: 100%;
    }

    .container-view-content
    {
        flex: 1;

        padding-bottom: 2em;
    }

    .container-view-content hr
    {
        border: none;
        border-bottom: 1px solid #d0d0d0;
        margin: 1.5em 0;
    }

    .container-view-content p
    {
        margin-bottom: 1.5em;
    }

    .container-view-header
    {
        width: 100%;
    }

    .container-view-header .col-right button
    {
        font-size: 0.9em;
    }

    .container-buttons
    {
        margin-top: 1em;
        text-align: right;
    }

    @media (max-width: 767px) {

    }

    @media (min-width: 768px) and (max-width: 1199px) {

    }

    @media (min-width: 992px) {

        .container-view-content
        {
            display: flex;
            align-items: center;
            flex-direction: column;
        }

    }

    @media (max-width: 1199px) {

        .container-view-header
        {
            align-items: flex-end;
            flex-direction: column-reverse;
        }

        .container-view-header > div
        {
            margin: 0;
            text-align: left;
            width: 100%;
        }

        .container-view-header .col-right
        {
            margin-bottom: 1em;
            text-align: right;
        }

    }

    @media (min-width: 1200px) {

    }
</style>
