<template>
    <div class="component-form-builder-item">

        <div :class="['container-item', { active: itemAddUpdate.show }, { disabled }]">
            <button type="button" class="button-drag" :disabled="disabled" title="Mover este campo para outra posição">
                <img src="~@/assets/img/ico/ico_drag_item_black.svg" alt="">
            </button>
            <div class="question-index">
                <span>{{ index + 1 }}</span>
            </div>
            <div class="answer-type">
                <img :src="answerTypeInfos[item.answer_type].icon" alt="">
            </div>
            <hr v-if="$mq.match(/xs|sm|md/)">
            <div class="container-question-infos">
                <div>{{ item.question }}</div>
                <div>Tipo: {{ answerTypeInfos[item.answer_type].label }}</div>
            </div>
            <hr v-if="$mq.match(/xs|sm|md/)">
            <div class="container-actions">
                <button type="button" :disabled="disabled" title="Editar este campo" @click="buttonEditClick" v-if="!itemAddUpdate.show">
                    <img src="~@/assets/img/ico/ico_edit.svg" alt="">
                    <span>Editar</span>
                </button>
                <button type="button" :disabled="disabled" title="Excluir este campo" @click="buttonDeleteClick" v-if="!itemAddUpdate.show">
                    <img src="~@/assets/img/ico/ico_trash.svg" alt="">
                    <span>Excluir</span>
                </button>
            </div>
        </div>
        <div class="container-component-item-add-update">
            <ComponentFormBuilderItemAddUpdate :id="item.id" :item="item" @cancel="componentFormBuilderItemAddUpdateCancel" @questionAdded="componentFormBuilderItemAddQuestionAdded" v-if="itemAddUpdate.show"></ComponentFormBuilderItemAddUpdate>
        </div>

        <ComponentModal class="component-modal-confirm-delete-item" ref="modalConfirmDelete">
            <template v-slot:title>
                <span>Excluir pergunta</span>
            </template>
            <template v-slot:content>
                <p>Você realmente deseja excluir esta pergunta?</p>
            </template>
            <template v-slot:footer>
                <button type="button" class="btn-default btn-terciary" @click="modalconfirmDeleteButtonCancel">Cancelar</button>
                <button type="button" class="btn-default btn-primary-red" @click="modalconfirmDeleteButtonDelete">Excluir</button>
            </template>
        </ComponentModal>

    </div>
</template>

<script>

    import ComponentFormBuilderItemAddUpdate from "@/components/FormBuilder/ComponentFormBuilderItemAddUpdate"
    import ComponentModal from "@/components/ui/ComponentModal"

    export default {
        name: 'ComponentFormBuilderItem',
        props: ['disabled', 'index', 'item'],
        mixins: [],
        components: {
            ComponentFormBuilderItemAddUpdate,
            ComponentModal
        },//components
        data() {
            return {

                answerTypeInfos: {
                    short_text: {
                        icon: require('@/assets/img/ico/ico_type_input.svg'),
                        label: 'Resposta curta'
                    },
                    text: {
                        icon: require('@/assets/img/ico/ico_type_paragraph.svg'),
                        label: 'Parágrafo'
                    },
                    multiple_choice: {
                        icon: require('@/assets/img/ico/ico_type_radio.svg'),
                        label: 'Múltipla escolha'
                    },
                    multiple_response: {
                        icon: require('@/assets/img/ico/ico_type_checkbox.svg'),
                        label: 'Caixas de seleção'
                    },
                    selectbox: {
                        icon: require('@/assets/img/ico/ico_type_select.svg'),
                        label: 'Lista suspensa'
                    },
                    rg: {
                        icon: require('@/assets/img/ico/ico_type_rg.svg'),
                        label: 'RG'
                    },
                    cpf: {
                        icon: require('@/assets/img/ico/ico_type_rg.svg'),
                        label: 'CPF'
                    },
                    date: {
                        icon: require('@/assets/img/ico/ico_type_date.svg'),
                        label: 'Data'
                    },
                    time: {
                        icon: require('@/assets/img/ico/ico_type_time.svg'),
                        label: 'Hora'
                    },
                    phone: {
                        icon: require('@/assets/img/ico/ico_type_phone.svg'),
                        label: 'Telefone'
                    }
                },

                itemAddUpdate: {
                    show: false
                }
                
            }
        },//data
        computed: {

            user() {
                return this.$store.getters['App/user']
            }

        },//computed
        methods: {

            deleteQuestion(id) {

                const companyId = this.user.company_id

                this.requesting = true

                this.axios
                    .delete(`api/v1/companies/${companyId}/customer_fields/${id}`)
                    .then(
                        response => {

                            this.$eventbus.$emit(
                                'ComponentToast/add',
                                {
                                    autoHide: true,
                                    content: `Pergunta excluída com sucesso`,
                                    icon: null,
                                    showCloseButton: false,
                                    type: 'success'
                                }
                            )

                            this.$emit('deleted', this.item.id)

                        }
                    )
                    .catch(
                        error => {

                            this.$eventbus.$emit(
                                'ComponentToast/add',
                                {
                                    autoHide: true,
                                    content: 'Erro ao excluir pergunta',
                                    icon: null,
                                    showCloseButton: false,
                                    type: 'error'
                                }
                            )

                        }
                    )
                    .finally(
                        () => {
                            this.requesting = false
                        }
                    )

            },

            //Events

            componentFormBuilderItemAddUpdateCancel() {

                this.itemAddUpdate.show = false

                this.$emit('cancel')

            },

            componentFormBuilderItemAddQuestionAdded(response) {
                this.$emit('questionAdded', response)
            },

            buttonEditClick(e) {

                this.itemAddUpdate.show = true

                this.$emit(
                    'editing',
                    {
                        index: this.index,
                        item: this.item
                    }
                )

            },

            buttonDeleteClick(e) {
                this.$refs.modalConfirmDelete.show = true
            },

            modalconfirmDeleteButtonCancel(e) {
                this.$refs.modalConfirmDelete.show = false
            },

            modalconfirmDeleteButtonDelete(e) {

                this.$refs.modalConfirmDelete.show = false

                this.deleteQuestion(this.item.id)

            }

        },//methods
        watch: {},//watch
        created() {},//created
        mounted() {},//mounted
        updated() {},//updated
        destroyed() {},//destroyed
    }
</script>

<style scoped>
    .component-form-builder-item
    {

    }

    .container-item
    {
        background-color: #fff;
        border-radius: 5px;
        padding: 0.75em;
    }

    .container-item.disabled
    {
        opacity: 0.4;
    }

    .container-item .answer-type img
    {
        max-height: 1em;
        max-width: 1em;
    }

    .button-drag
    {
        background-color: transparent;
        border: none;
        margin-right: 0.75em;
    }

    .button-drag img
    {
        opacity: 0.3;
    }

    .question-index
    {
        display: inline-flex;
        align-items: center;
        justify-content: center;

        background-color: #29B1CC;
        border: 1px solid #29B1CC;
        border-radius: 100px;
        color: #fff;
        font-size: 1.2em;
        font-weight: 500;
        height: 2.5rem;
        margin-right: 0.5em;
        width: 2.5rem;
    }

    .answer-type
    {
        display: inline-flex;
        align-items: center;
        justify-content: center;

        background-color: #fff;
        border: 1px solid #a9a9a9;
        border-radius: 100px;
        color: #fff;
        font-size: 1.3em;
        font-weight: 500;
        height: 2.5rem;
        width: 2.5rem;
    }

    .container-question-infos
    {
        flex: 1;
    }

    .container-question-infos :first-child
    {
        font-weight: 400;
    }

    .container-question-infos :last-child
    {
        font-size: 0.8em;
    }

    .container-actions
    {

    }

    .container-actions button
    {
        display: inline-flex;
        align-items: center;

        background-color: transparent;
        border: none;
        margin: 0;
        padding: 0 0.75em;
    }

    .container-actions button img
    {
        height: 1.1em;
        margin-right: 0.35em;
    }

    .container-actions button:first-child img
    {
        opacity: 0.5;
    }

    .container-component-item-add-update
    {
        margin-top: 0.25em;
    }

    /* Item active */
    .container-item.active
    {
        background-color: #29B1CC;
    }

    .container-item.active .question-index
    {
        background-color: #fff;
        border-color: #fff;
        color: #000;
    }

    .container-item.active .answer-type
    {
        border-color: #fff;
    }

    .container-item.active .container-question-infos
    {
        color: #fff;
    }

    @media (max-width: 767px) {

    }

    @media (min-width: 768px) {

    }

    @media (min-width: 992px) {

    }

    @media (max-width: 1199px) {

        .container-item
        {

        }

        .answer-type
        {
            float: right;
        }

    }

    @media (min-width: 1200px) {

        .container-item
        {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        .answer-type
        {
            margin-right: 0.75em;
        }

    }
</style>
