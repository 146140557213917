<template>
    <section id="view-onboarding-personal-profile">

        <ComponentLoading v-if="requestingLoadUser"/>
        <template v-else>

            <h1>Seu perfil pessoal</h1>
            <ComponentPersonalProfile
                    :email="user.email"
                    :emailIsReadOnly="true"
                    :key="JSON.stringify(user)"
                    :name="user.name"
                    :passwordIsRequired="true"
                    :phone="user.phone"
                    :pictureFileUrl="user.image_url"
                    ref="componentPersonalProfile"
                    :user="user"
                    @requestingDone="componentPersonalProfileRequestingDone"
                    @requestingUpdate="componentPersonalProfileRequestingUpdate"
                    @updated="componentPersonalProfileUpdated"
                    v-if="user" />
            <div class="container-buttons">
                <router-link class="btn-default btn-secondary btn-back" :disabled="requestingUpdateUser" tag="button" :to="{ name: 'OnboardingYourBusiness' }">
                    <img src="~@/assets/img/ico/ico_arrow-white.svg" alt="">
                    <span v-if="$mq.match(/lg/)">VOLTAR</span>
                </router-link>
                <button class="btn-default btn-primary btn-continue" :disabled="requestingUpdateUser" type="button" @click="buttonContinueClick">

                    <template v-if="requestingUpdateUser">
                        <img class="icon-circle-segment-animated" src="~@/assets/img/ico/ico_circle_segment_white.svg" alt="">
                        <span>AGUARDE</span>
                    </template>
                    <template v-else>
                        <span v-if="$mq.match(/lg/)">CONTINUAR</span>
                        <img src="~@/assets/img/ico/ico_arrow-white.svg" alt="">
                    </template>

                </button>
            </div>

        </template>
    </section>
</template>

<script>
    import MixinView from '@/mixins/MixinView.js'

    import ComponentLoading from "@/components/ui/ComponentLoading"
    import ComponentPersonalProfile from "@/components/ComponentPersonalProfile"

    export default {
        name: 'OnboardingPersonalProfile',
        props: [],
        mixins: [MixinView],
        components: {
            ComponentLoading,
            ComponentPersonalProfile
        },//components
        data() {
            return {

                model: {},

                requestingLoadUser: false,

                requestingUpdateUser: false,

                user: {
                    email: null,
                    name: null,
                    phone: null,
                    image_url: null,
                }

            }
        },//data
        computed: {

            company() {
                return this.$store.getters['Companies/company']
            },

        },//computed
        methods: {

            loadUser() {

                const companyId = this.$store.getters['App/companyId']
                const userId = this.$store.getters['App/userId']

                this.requestingLoadUser = true

                this.$fetch.get(`/api/v1/companies/${companyId}/users/${userId}`)
                    .then(
                        response => {

                            const data = response.data

                            this.user = data

                        }
                    )
                    .catch(
                        error => {

                            this.$eventbus.$emit(
                                'ComponentToast/add',
                                {
                                    autoHide: true,
                                    content: 'Erro ao carregar usuário',
                                    icon: null,
                                    showCloseButton: false,
                                    type: 'error'
                                }
                            )

                        }
                    )
                    .finally(
                        () => {
                            this.requestingLoadUser = false
                        }
                    )

            },

            //Events

            buttonContinueClick(e) {

                const isValid = this.$helpers.validateFields(this.$refs.componentPersonalProfile.$children)

                this.model = this._.cloneDeep(this.$refs.componentPersonalProfile.model)

                if(isValid) {

                    const companyId = this.user.company_id
                    const userId = this.user.id

                    let data = {
                        'user[name]': this.model.name,
                        'user[phone]': this.model.phone ? this.model.phone : '',
                    }

                    if(this.model.pictureFile) {
                        data['user[attachment_attributes][attachment]'] = this.model.pictureFile
                    }

                    this.requestingUpdateUser = true

                    this.axios
                        .patch(`api/v1/companies/${companyId}/users/${userId}`, data)
                        .then(
                            response => {
                                this.$router.push({ name: 'OnboardingPasswordChange' }, () => {}, () => {})
                            }
                        )
                        .catch(
                            error => {

                                this.$eventbus.$emit(
                                    'ComponentToast/add',
                                    {
                                        autoHide: true,
                                        content: 'Erro ao alterar o perfil',
                                        icon: null,
                                        showCloseButton: false,
                                        type: 'error'
                                    }
                                )

                            }
                        )
                        .finally(
                            () => {
                                this.requestingUpdateUser = false
                            }
                        )

                } else {

                    this.$eventbus.$emit(
                        'ComponentToast/add',
                        {
                            autoHide: true,
                            content: 'Preencha corretamente todos os campos para prosseguir (verifique os obrigatórios)',
                            icon: null,
                            showCloseButton: false,
                            type: 'error'
                        }
                    )

                }

            },

            componentPersonalProfileRequestingDone() {
                this.loadUser()
            },

            componentPersonalProfileRequestingUpdate() {
                this.requestingUpdateUser = true
            },

            componentPersonalProfileUpdated() {
                this.requestingUpdateUser = false
            }

        },//methods
        watch: {},//watch
        created() {},//created
        mounted() {

            this.loadUser()

        },//mounted
        updated() {},//updated
        destroyed() {},//destroyed
        beforeRouteEnter(to, from, next) {
            next(
                vm => {
                }
            )
        },//beforeRouteEnter
        beforeRouteUpdate(to, from, next) {
            next()
        },//beforeRouteUpdate
        beforeRouteLeave(to, from, next) {
            next()
        }//beforeRouteLeave

    }
</script>

<style scoped>
    #view-onboarding-personal-profile
    {
        text-align: center;
    }

    h1
    {
        font-size: 1.5em;
        font-weight: 400;
        margin-bottom: 0.5em;
        margin-top: 0;
    }

    .component-personal-profile
    {
        margin: 0 auto;
        width: 400px;
    }

    .container-buttons
    {
        display: flex;
        justify-content: space-between;
    }

    .container-buttons button
    {
        font-size: 0.9em;
        padding-left: 3em;
        padding-right: 3em;
    }

    .container-buttons button img
    {
        vertical-align: middle;
    }

    .container-buttons button span
    {
        vertical-align: middle;
    }

    .btn-back img
    {
        margin-right: 0.5em;
        transform: rotate(180deg);
    }

    .btn-continue img
    {
        margin-left: 0.5em;
    }

    @media (max-width: 767px) {

        .component-personal-profile
        {
            width: 100%;
        }

    }

    @media (min-width: 768px) and (max-width: 1199px) {

    }

    @media (max-width: 1199px) {

    }

    @media (min-width: 1200px) {

    }
</style>
