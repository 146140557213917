<template>
    <section id="view-login">
        <div class="container-fields">
            <span class="container-environment-id" v-if="env">{{ env }}</span>
            <div class="container-logo">
                <img src="~@/assets/img/logo_ordexa.svg" alt="">
            </div>
            <div class="container-text-do-login">Faça seu login</div>
            <div class="container-group-input">
                <label>E-mail</label>
                <ComponentInputText :icon="require('@/assets/img/ico/ico_envelope.svg')" placeholder="seu e-mail" type="email" id="email" showPasswordButton="false" v-model="model.user" @keyupEnter="inputKeyupEnter"></ComponentInputText>
            </div>
            <div class="container-group-input">
                <label>Senha</label>
                <ComponentInputText :icon="require('@/assets/img/ico/ico_key.svg')" placeholder="sua senha" autocomplete="true" type="password" id="password" showPasswordButton="true" v-model="model.password" @keyupEnter="inputKeyupEnter"></ComponentInputText>
                <div class="container-link-forgot-password">
                    <a :href="resetPasswordUrl">Esqueci a senha</a>
                </div>
            </div>
            <div class="container-button">
                <button type="button" class="btn-default btn-primary" :disabled="requestingLogin" @click="btnSignInClick" id="login-button">
                    <template v-if="requestingLogin">
                        <img class="icon-circle-segment-animated" src="~@/assets/img/ico/ico_circle_segment_white.svg" alt="">
                        <span>Aguarde</span>
                    </template>
                    <span v-else>Entrar</span>
                </button>
            </div>
            <div class="container-help-link">
                Problemas com o acesso? <br v-if="$mq.match('sm')"><a target="_blank" href="https://ordexa.com.br/guia-pratico">Ajuda</a>
            </div>
        </div>
    </section>
</template>

<script>
import MixinAuth from '@/mixins/MixinAuth.js'
import MixinView from '@/mixins/MixinView.js'

import ComponentInputText from "@/components/ui/ComponentInputText"

export default {
    name: 'Login',
    props: [],
    mixins: [MixinView, MixinAuth],
    components: {
        ComponentInputText
    },//components
    data() {
        return {

            model: {
                user: '',
                password: ''
            },

            resetPasswordUrl: `${process.env.VUE_APP_API_BASE_URL}/users/password/new`,

            requestingLogin: false,

        }
    },//data
    computed: {

        env() {

            const vueAppEnv = process.env.VUE_APP_ENV

            let ret = false

            if(vueAppEnv) {

                if(vueAppEnv !== 'production') {
                    ret = vueAppEnv
                }

            } else {
                ret = 'not set'
            }

            return ret

        },

    },//computed
    methods: {

        checkParamAction() {

          const action = this.$route.params.action

          const messages = {
            'SESSION_EXPIRED:DO_LOGIN_AGAIN': 'Sua sessão expirou. Por favor, faça o login novamente.'
          }

          if(messages[action]) {

            this.$eventbus.$emit(
                'ComponentToast/add',
                {
                  autoHide: true,
                  autoHideDelay: 10000,
                  content: messages[action],
                  icon: null,
                  showCloseButton: false,
                  type: 'error'
                }
            )

          }

        },

        checkQueryAction() {

            const action = this.$route.query.action

            if(action === 'new_password_requested') {

                this.$eventbus.$emit(
                    'ComponentToast/add',
                    {
                        autoHide: true,
                        autoHideDelay: 10000,
                        content: 'As instruções para criação de uma nova senha foram enviadas ao seu email cadastrado. Confira o link no seu email.',
                        icon: null,
                        showCloseButton: false,
                        type: 'success'
                    }
                )

            }

            if(action === 'changed_password') {

                this.$eventbus.$emit(
                    'ComponentToast/add',
                    {
                        autoHide: true,
                        autoHideDelay: 10000,
                        content: 'Nova senha criada com sucesso. Utilize sua nova senha para acessar o Ordexa.',
                        icon: null,
                        showCloseButton: false,
                        type: 'success'
                    }
                )

            }

        },

        async signIn() {

            this.requestingLogin = true

            this.axios.post(
                '/api/v1/auth/sign_in',
                {
                    'user[email]': this.model.user,
                    'user[password]': this.model.password
                }
            )
                .then(
                    response => {
                        this.setAuth(response);
                    }
                )
                .catch(
                    error => {

                        if(error.response) {

                            const data = error.response.data

                            if(data.errors) {

                                data.errors.forEach(
                                    (item, index, array) => {

                                        this.$eventbus.$emit(
                                            'ComponentToast/add',
                                            {
                                                autoHide: true,
                                                content: item,
                                                icon: null,
                                                showCloseButton: false,
                                                type: 'error'
                                            }
                                        )

                                    }
                                )

                            } else {

                                this.$eventbus.$emit(
                                    'ComponentToast/add',
                                    {
                                        autoHide: true,
                                        content: 'Erro ao autenticar. Tente novamente.',
                                        icon: null,
                                        showCloseButton: false,
                                        type: 'error'
                                    }
                                )

                            }

                        }

                        this.requestingLogin = false;

                    }
                )

        },

        //Events

        btnSignInClick(e) {
            this.signIn()
        },

        inputKeyupEnter(e) {
            this.signIn()
        }

    },//methods
    watch: {},//watch
    created() {},//created
    beforeMount() {},//beforeMount
    mounted() {

        this.checkParamAction()

        this.checkQueryAction()

    },//mounted
    updated() {},//updated
    destroyed() {},//destroyed
    beforeRouteEnter(to, from, next) {
        next(
            vm => {
            }
        )
    },//beforeRouteEnter
    beforeRouteUpdate(to, from, next) {
        next()
    },//beforeRouteUpdate
    beforeRouteLeave(to, from, next) {
        next()
    }//beforeRouteLeave

}
</script>

<style scoped>
#view-login
{

}

.container-fields
{

}

.container-text-do-login
{
    font-size: 1.05em;
    font-weight: 600;
}

.container-group-input
{
    font-size: 0.9em;
    margin: 0 auto 0.8em;
    width: 100%;
}

.container-group-input .component-input-text
{
    width: 100%;
}

.container-link-forgot-password
{
    margin-top: 0.5em;
    text-align: right;
}

.container-button
{
    margin-bottom: 2em;
}

.container-button
{
    margin-bottom: 1.5em;
}

.container-button button
{
    box-shadow: 0 3px 12px 0 rgba(41, 177, 204, 0.5);
    font-size: 0.9em;
    padding-left: 3em;
    padding-right: 3em;
}

.container-button button img
{
    margin-right: 0.5em;
}

.container-environment-id
{
    display: inline-flex;
    align-items: center;
    justify-content: center;

    background-color: #fd0;
    border-radius: 100px;
    cursor: pointer;
    font-size: 0.675em;
    font-weight: bold;
    letter-spacing: 0.015em;
    height: 2em;
    line-height: 100%;
    margin-bottom: 1em;
    margin-right: 1em;
    padding: 0 1em;
    text-align: center;
    text-transform: uppercase;
}

.container-help-link
{
    font-size: 0.9em;
}

@media (max-width: 767px) {

}

@media (min-width: 768px) and (max-width: 1199px) {

}

@media (max-width: 1199px) {

    .container-logo
    {
        margin-bottom: 1.5em;
    }

    .container-text-do-login
    {
        margin-bottom: 1.5em;
    }

    .container-button
    {
        margin-top: 2em;
    }

    .container-button button
    {
        width: 100%;
    }

    .container-help-link
    {
        text-align: center;
    }

}

@media (min-width: 1200px) {

    #view-login
    {
        width: 350px;
    }

    .container-logo
    {
        margin-bottom: 2em;
    }

    .container-text-do-login
    {
        margin-bottom: 3em;
    }

}
</style>
