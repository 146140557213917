<template>
    <div :class="['component-form-builder', { 'first-content': !hasItems }]">

        <template v-if="requesting">
            <ComponentLoading />
        </template>
        <template v-else>

            <template v-if="!hasItems && !itemAddUpdateNewQuestion.isAdding">
                <ComponentFirstContentBox :button="{ label: 'ADICIONAR A PRIMEIRA PERGUNTA', clickHandler: this.componentFirstContentButtonClick }" :content="`<p>Você ainda não possui perguntas cadastradas. Comece agora o formulário personalizado da sua ${$store.getters['Companies/companyTreatmentSingular'].toLowerCase()}.</p>`" :icon="require('@/assets/img/ico/ico_add_question.svg')"></ComponentFirstContentBox>
            </template>

            <div @click="componentFormBuilderItemCustomerNameClick">
                <ComponentFormBuilderItem :disabled="true" :index="0" :item="itemCustomerName"></ComponentFormBuilderItem>
            </div>

            <template v-if="hasItems">
                <draggable :disabled="itemAddUpdateNewQuestion.isAdding || isEditing" handle=".button-drag" @update="draggableItemsUpdate" v-model="items">
                    <ComponentFormBuilderItem :disabled="itemAddUpdateNewQuestion.isAdding || (isEditing && itemEditing.index !== index)" :key="item.id" :index="index + 1" :item="item" v-for="(item, index) in items" @cancel="componentFormBuilderItemEditingCancel" @deleted="componentFormBuilderItemDeleted" @editing="componentFormBuilderItemEditing" @questionAdded="componentFormBuilderItemEditingQuestionAdded"></ComponentFormBuilderItem>
                </draggable>
            </template>

            <div class="container-component-item-add-update-new-question" v-if="itemAddUpdateNewQuestion.show">
                <ComponentFormBuilderItemAddUpdate :id="null" :item="null" @cancel="componentFormBuilderItemAddingCancel" @questionAdded="componentFormBuilderItemAddingQuestionAdded"></ComponentFormBuilderItemAddUpdate>
            </div>

            <div class="container-auto-save-message">
                Os campos do formulário são salvos automaticamente sempre que você adiciona ou edita as perguntas.
            </div>

            <div class="container-buttons" v-if="hasItems && !itemAddUpdateNewQuestion.isAdding && !isEditing">
                <button type="button" class="btn-default btn-primary" @click="buttonAddQuestionClick">Adicionar pergunta</button>
            </div>

        </template>

    </div>
</template>

<script>

    import draggable from 'vuedraggable'

    import ComponentFormBuilderItem from "@/components/FormBuilder/ComponentFormBuilderItem"
    import ComponentFormBuilderItemAddUpdate from "@/components/FormBuilder/ComponentFormBuilderItemAddUpdate"
    import ComponentFirstContentBox from "@/components/ui/ComponentFirstContentBox"
    import ComponentLoading from "@/components/ui/ComponentLoading"

    export default {
        name: 'ComponentFormBuilder',
        props: [],
        mixins: [],
        components: {
            ComponentFirstContentBox,
            ComponentFormBuilderItem,
            ComponentFormBuilderItemAddUpdate,
            ComponentLoading,
            draggable
        },//components
        data() {
            return {

                company: null,

                customerFieldsOrder: null,

                items: [],

                itemsRaw: [],

                itemAddUpdateNewQuestion: {
                    isAdding: false,
                    show: false
                },

                itemEditing: {
                    index: null,
                    item: null
                },

                requesting: false

            }
        },//data
        computed: {

            hasItems() {
                return this.itemsRaw.length > 0
            },

            isEditing() {
                return this.itemEditing.index !== null && this.itemEditing.item !== null
            },

            itemCustomerName() {
                return {
                    id: null,
                    question: 'Nome',
                    answer_type: 'short_text',
                    mandatory: null,
                    company_id: null,
                    created_at: null,
                    updated_at: null,
                    options: []
                }
            },

            user() {
                return this.$store.getters['App/user']
            }

        },//computed
        methods: {

            clearItems() {
                this.items = []
            },

            loadQuestions() {

                this.requesting = true

                this.$store.dispatch('Companies/loadCompany')
                    .then(
                        responseCompany => {

                            const companyId = this.user.company_id

                            this.company = responseCompany.data
                            this.customerFieldsOrder = responseCompany.data.customer_fields_order

                            this.$fetch.get(`/api/v1/companies/${companyId}/customer_fields`)
                                .then(
                                    response => {

                                        this.itemsRaw = response.data

                                        this.clearItems()

                                        this.customerFieldsOrder.map(
                                            (item, index, array) => {

                                                const foundItem = this._.find(this.itemsRaw, { id: item })

                                                if(foundItem) {
                                                    this.items.push(foundItem)
                                                }

                                            }
                                        )

                                    }
                                )
                                .catch(
                                    error => {

                                        this.$eventbus.$emit(
                                            'ComponentToast/add',
                                            {
                                                autoHide: true,
                                                content: `Erro ao carregar as perguntas da ${this.$store.getters['Companies/companyTreatmentSingular'].toLowerCase()}`,
                                                icon: null,
                                                showCloseButton: false,
                                                type: 'error'
                                            }
                                        )

                                    }
                                )
                                .finally(
                                    () => {
                                        this.requesting = false
                                    }
                                )

                        }
                    )
                    .catch(
                        error => {}
                    )

            },

            resetItemAdding() {

                this.itemAddUpdateNewQuestion.isAdding = false
                this.itemAddUpdateNewQuestion.show = false

            },

            resetItemEditing() {

                this.itemEditing.index = null
                this.itemEditing.item = null

            },

            updateCompany() {

                const companyId = this.user.company_id
                const companyTreatment = this.company.company_treatment
                const customerTreatment = this.company.customer_treatment

                const customerFieldsOrder = this.items.map( item => item.id )

                this.requesting = true

                this.axios
                    .patch(
                        `api/v1/companies/${companyId}`,
                        {
                            'company[company_treatment]': companyTreatment,
                            'company[customer_treatment]': customerTreatment,
                            'company[customer_fields_order]': JSON.stringify(customerFieldsOrder)
                        }
                    )
                    .then(
                        response => {
                            this.loadQuestions()
                        }
                    )
                    .catch(
                        error => {

                            this.$eventbus.$emit(
                                'ComponentToast/add',
                                {
                                    autoHide: true,
                                    content: 'Erro ao salvar a ordem das perguntas',
                                    icon: null,
                                    showCloseButton: false,
                                    type: 'error'
                                }
                            )

                        }
                    )
                    .finally(
                        () => {
                            this.requesting = false
                        }
                    )

            },

            //Events

            buttonAddQuestionClick(e) {

                this.itemAddUpdateNewQuestion.show = true

                this.itemAddUpdateNewQuestion.isAdding = true

                this.$nextTick(
                    () => {
                        this.$eventbus.$emit('ContainerRouterView/scrollTo', { left: 0, top: 9999999999999, behavior: 'smooth' })
                    }
                )

            },

            componentFirstContentButtonClick(e) {

                this.itemAddUpdateNewQuestion.show = true

                this.itemAddUpdateNewQuestion.isAdding = true

            },

            componentFormBuilderItemDeleted(id) {
                this.loadQuestions()
            },

            componentFormBuilderItemEditingCancel() {
                this.resetItemEditing()
            },

            componentFormBuilderItemEditing(obj) {

                const index = obj.index
                const item = obj.item

                this.itemEditing.index = index
                this.itemEditing.item = item

            },

            componentFormBuilderItemEditingQuestionAdded(response) {

                this.$eventbus.$emit(
                    'ComponentToast/add',
                    {
                        autoHide: true,
                        content: `Pergunta alterada com sucesso`,
                        icon: null,
                        showCloseButton: false,
                        type: 'success'
                    }
                )

                this.resetItemEditing()

                this.loadQuestions()

            },

            componentFormBuilderItemAddingCancel() {

                this.itemAddUpdateNewQuestion.show = false

                this.itemAddUpdateNewQuestion.isAdding = false

            },

            componentFormBuilderItemAddingQuestionAdded(response) {

                this.$eventbus.$emit(
                    'ComponentToast/add',
                    {
                        autoHide: true,
                        content: `Pergunta criada com sucesso`,
                        icon: null,
                        showCloseButton: false,
                        type: 'success'
                    }
                )

                this.resetItemAdding()

                this.loadQuestions()

            },

            componentFormBuilderItemCustomerNameClick(e) {

                this.$eventbus.$emit(
                    'ComponentToast/add',
                    {
                        autoHide: true,
                        content: 'Esse é um campo fixo e não pode ser editado. Ele será útil para você mais tarde.',
                        icon: null,
                        showCloseButton: false,
                        type: 'warning'
                    }
                )

            },

            draggableItemsUpdate(e) {

                this.updateCompany()

            }

        },//methods
        watch: {},//watch
        created() {},//created
        mounted() {
            this.loadQuestions()
        },//mounted
        updated() {},//updated
        destroyed() {},//destroyed
    }
</script>

<style scoped>
    .component-form-builder
    {
        width: 100%;
    }

    .component-form-builder.first-content
    {
        flex: 1;
        display: flex;
        align-items: center;
    }

    .component-loading
    {
        margin: 0 auto;
    }

    .container-requesting
    {
        display: flex;
        align-items: center;
        justify-content: center;

        font-size: 1.5em;
        opacity: 0.5;
        padding: 2em 1em;
    }

    .container-requesting img
    {
        height: 1.25em;
        margin-right: 0.5em;
    }

    .component-form-builder-item
    {
        margin-bottom: 1em;
    }

    .component-first-content-box
    {
        margin: 2em auto;
    }

    .container-auto-save-message
    {
        border: 2px solid #29B1CC;
        border-radius: 4px;
        color: #29B1CC;
        font-size: 0.875em;
        font-weight: 400;
        padding: 0.5em 0;
        text-align: center;
    }

    .container-buttons
    {
        display: flex;
        justify-content: flex-end;

        margin-top: 1em;
        text-align: right;
    }

    .container-buttons > :first-child:not(:last-child)
    {
        margin-right: 0.75em;
    }

    .container-component-item-add-update-new-question
    {
        align-self: flex-start;

        margin-bottom: 1em;
        margin-top: 1em;
        width: 100%;
    }

    .container-component-item-add-update-new-question .component-form-builder-item-add-update
    {
        animation-duration: 1s;
        animation-direction: alternate;
        animation-iteration-count: infinite;
        animation-name: highlightItemNewQuestion;
    }

    @keyframes highlightItemNewQuestion {
        from {
            box-shadow: 0 0 6px 0 rgba(41, 177, 204, 0)
        }
        to {
            box-shadow: 0 0 6px 6px rgba(41, 177, 204, 0.35)
        }
    }

    @media (max-width: 767px) {

        .container-buttons button
        {
            font-size: 0.825em;
            padding-bottom: 1em;
            padding-top: 1em;
        }

    }

    @media (min-width: 768px) {

    }

    @media (min-width: 992px) {

    }

    @media (max-width: 1199px) {

    }

    @media (min-width: 1200px) {

    }
</style>
