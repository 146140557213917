<template>
    <section id="view-business-log">

        <ComponentViewTabs class="component-view-tabs" :tabs="this.viewTabs"></ComponentViewTabs>

    </section>
</template>

<script>
    import MixinView from '@/mixins/MixinView.js'
    import MixinBusiness from '@/mixins/Business/MixinBusiness'

    export default {
        name: 'BusinessLog',
        props: [],
        mixins: [MixinView, MixinBusiness],
        components: {},//components
        data() {
            return {}
        },//data
        computed: {},//computed
        methods: {},//methods
        watch: {},//watch
        created() {},//created
        mounted() {},//mounted
        updated() {},//updated
        destroyed() {},//destroyed
        beforeRouteEnter(to, from, next) {
            next(
                vm => {
                }
            )
        },//beforeRouteEnter
        beforeRouteUpdate(to, from, next) {
            next()
        },//beforeRouteUpdate
        beforeRouteLeave(to, from, next) {
            next()
        }//beforeRouteLeave

    }
</script>

<style scoped>
    #view-business-log
    {

    }

    @media (max-width: 767px) {

    }

    @media (min-width: 768px) and (max-width: 1199px) {

    }

    @media (max-width: 1199px) {

    }

    @media (min-width: 1200px) {

    }
</style>
