<template>
    <div class="component-form-fill">

            <div class="container-header">
                <div class="col-left">
                    <div class="title">{{ title }}</div>
                </div>
                <div class="col-right">
                    <button type="button" class="btn-default btn-primary" @click="buttonGoToBusinessFormClick">Ir para edição do formulário</button>
<!--<ComponentImageUpload :icon="require('@/assets/img/ico/ico_profile.svg')" label="+ Adicionar<br>imagem" :src="pictureFileUrl" @change="componentImageUploadChange"></ComponentImageUpload>-->
                </div>
            </div>
            <hr>
            <div class="container-items">
                <template v-if="requesting">
                    <ComponentLoading label="Carregando formulário"></ComponentLoading>
                </template>
                <template v-else>
                    <div class="container-no-form-items" v-if="!hasItems">Você precisa cadastrar os <b>campos de formulário</b> antes de cadastrar {{ $store.getters['Companies/customerTreatmentPlural'].toLowerCase() }}. <router-link :to="{ name: 'BusinessForm' }">Clique aqui</router-link> e monte seu formulário agora.</div>
                    <template v-else>
                        <div :class="['component-form-fill-item', { invalid: !nameIsValid && saveCount > 0 }]">
                            <div class="container-question-text">
                                <span>1. Nome</span>
                                <span class="required-mark">*</span>
                                <span class="invalid-mark" v-if="!nameIsValid && saveCount > 0">
                                    <img src="~@/assets/img/ico/ico_exclamation_triangle_red.svg" alt="">
                                </span>
                            </div>
                            <div class="container-question-controls">
                                <ComponentInputText class="full-width" placeholder="nome" type="text" :validation="{ required: true }" v-model="model.customerName"></ComponentInputText>
                            </div>
                        </div>
                        <ComponentFormFillItem :key="item.id" :id="item.id" :index="index" :item="item" ref="componentFormFillItem" :saveCount="saveCount" :value="getAnswerValue(item.id)" v-for="(item, index) in items" @modelChange="componentFormFillItemModelChange" />
                    </template>
                </template>
            </div>
            <hr>
            <div class="container-buttons">
                <button class="btn-default btn-primary btn-save" :disabled="requesting || !hasItems" @click="buttonSaveClick">
                    <template v-if="requesting">
                        <img class="icon-circle-segment-animated" src="~@/assets/img/ico/ico_circle_segment_white.svg" alt="">
                        <span>Aguarde</span>
                    </template>
                    <span v-else>Salvar</span>
                </button>
                <button class="btn-default btn-terciary" :disabled="requesting" type="button" @click="buttonCancelClick">
                    <span>Cancelar</span>
                </button>
            </div>

    </div>
</template>

<script>

    import ComponentImageUpload from "@/components/ui/ComponentImageUpload"

    import ComponentFormFillItem from "@/components/FormFill/ComponentFormFillItem"
    import ComponentInputText from "@/components/ui/ComponentInputText"
    import ComponentLoading from "@/components/ui/ComponentLoading"

    export default {
        name: 'ComponentFormFill',
        props: [
            'companyId',
            'customer',
            'mode',
            'title'
        ],
        mixins: [],
        components: {
            ComponentFormFillItem,
            ComponentImageUpload,
            ComponentInputText,
            ComponentLoading
        },//components
        data() {
            return {

                company: null,

                customerFieldsOrder: null,

                items: [],

                itemsRaw: [],

                model: {
                    customerName: null,
                    pictureFile: null,
                    answers: {}
                },

                pictureFileUrl: null,

                requesting: false,

                saveCount: 0

            }
        },//data
        computed: {

            hasItems() {
                return this.itemsRaw.length > 0
            },

            nameIsValid() {

                let ret = false

                if(this.model.customerName) {
                    ret = true
                }

                return ret

            },

            user() {
                return this.$store.getters['App/user']
            }

        },//computed
        methods: {

            clearItems() {
                this.items = []
            },

            fillModel() {
                this.model.customerName = this._.cloneDeep(this.customer.name)
                this.model.pictureFile = this._.cloneDeep(this.customer.image_url)
                this.model.answers = this._.cloneDeep(this.customer.fields_values)
            },

            getAnswerValue(id) {

                let ret = null

                if(this.customer) {

                    let answers = this.customer.fields_values

                    if(answers) {

                        if(answers[id]) {
                            ret = answers[id]
                        }

                    }

                }

                return ret

            },

            loadQuestions() {

                return new Promise(
                    (resolve, reject) => {

                        this.requesting = true

                        this.$store.dispatch('Companies/loadCustomerFields')
                            .then(
                                responseLoadCustomerFields => {

                                    this.customerFieldsOrder = responseLoadCustomerFields.responseCompany.data.customer_fields_order
                                    this.itemsRaw = responseLoadCustomerFields.responseCustomerFields.data

                                    this.clearItems()

                                    this.customerFieldsOrder.map(
                                        (item, index, array) => {

                                            const foundItem = this._.find(this.itemsRaw, {id: item})

                                            if (foundItem) {
                                                this.items.push(foundItem)
                                            }

                                        }
                                    )

                                    resolve()

                                }
                            )
                            .catch(
                                error => {
                                    reject(error)
                                }
                            )
                            .finally(
                                () => {
                                    this.requesting = false
                                }
                            )

                    }
                )

            },

            //Events

            buttonCancelClick(e) {
                this.$router.go(-1)
            },

            buttonGoToBusinessFormClick(e) {
                this.$router.push({ name: 'BusinessForm' }, () => {}, (error) => {})
            },

            buttonSaveClick(e) {

                const companyId = this.user.company_id
                const isValid = this.$refs.componentFormFillItem.filter( item => !item.isValid ).length < 1

                let requestConfig = {}

                if(this.model.customerName && isValid) {

                    requestConfig.data = {
                        'customer[name]': this.model.customerName,
                        'customer[fields_values]': JSON.stringify(this.model.answers)
                    }

                    if(this.mode === 'add') {

                        requestConfig.method = 'post'
                        requestConfig.url = `api/v1/customers`

                    } else {

                        const customerId = this.customer.id

                        requestConfig.method = 'patch'
                        requestConfig.url = `api/v1/customers/${customerId}`

                    }

                    this.requesting = true

                    this.axios
                        .request(requestConfig)
                        .then(
                            response => {

                                this.$eventbus.$emit(
                                    'ComponentToast/add',
                                    {
                                        autoHide: true,
                                        content: `${this.$store.getters['Companies/customerTreatmentSingular']} criado/alterado com sucesso`,
                                        icon: null,
                                        showCloseButton: false,
                                        type: 'success'
                                    }
                                )

                                this.$emit('customerCreated', response.data)

                            }
                        )
                        .catch(
                            error => {

                                const data = error.response.data

                                this.$eventbus.$emit(
                                    'ComponentToast/add',
                                    {
                                        autoHide: true,
                                        content: `Não foi possível cadastrar o ${this.$store.getters['Companies/customerTreatmentSingular'].toLowerCase()}`,
                                        icon: null,
                                        showCloseButton: false,
                                        type: 'error'
                                    }
                                )

                            }
                        )
                        .finally(
                            () => {
                                this.requesting = false
                            }
                        )

                } else {

                    this.$eventbus.$emit(
                        'ComponentToast/add',
                        {
                            autoHide: true,
                            content: 'Preencha corretamente todos os campos para prosseguir (verifique os obrigatórios)',
                            icon: null,
                            showCloseButton: false,
                            type: 'error'
                        }
                    )

                }

                this.saveCount++

            },

            componentImageUploadChange(obj) {
                this.model.pictureFile = obj.fileBase64
            },


            componentFormFillItemModelChange(obj) {

                if(this.model.answers) {

                    const answerItem = this.model.answers[obj.id]

                    this.model.answers[obj.id] = obj.value

                }

            }

        },//methods
        watch: {

            customer(value, oldValue) {

                if(value) {
                    this.fillModel()
                }

            }

        },//watch
        created() {},//created
        mounted() {

            this.loadQuestions()
                .then(
                    () => {
                        this.fillModel()
                    }
                )
                .catch(
                    error => {

                    }
                )

        },//mounted
        updated() {},//updated
        destroyed() {},//destroyed
    }
</script>

<style scoped>
    .component-form-fill
    {

    }

    .component-loading
    {
        min-height: 160px;
    }

    .container-header
    {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
    }

    .container-header > div
    {
        flex: 1;
    }

    .container-header .col-right
    {
        text-align: right;
    }

    .container-header .col-right button
    {
        font-size: 0.9em;
    }

    .container-header .container-group-input
    {
        width: 100%;
    }

    .container-header .container-group-input .component-input-text
    {
        width: 100%;
    }

    .container-header .component-image-upload
    {

    }

    .container-header .title
    {
        font-size: 2em;
        margin-bottom: 0.5em;
    }

    .container-no-form-items
    {
        font-size: 1.2em;
        line-height: 140%;
        text-align: center;
    }

    .component-form-fill-item
    {
        margin-bottom: 1.5em;
    }

    .component-form-fill-item.invalid .container-question-text
    {
        color: #e00;
    }

    .container-question-text
    {
        color: #2794af;
        font-size: 1.15em;
        font-weight: 400;
        margin-bottom: 0.5em;

        transition: 0.15s all;
    }

    .container-question-controls
    {

    }

    .container-question-controls .component-input-text.full-width
    {
        width: 100%;
    }

    .invalid-mark
    {
        display: inline-flex;
        align-items: center;
        justify-content: center;

        font-weight: bold;
        margin-left: 0.25em;
        vertical-align: middle;

        animation-duration: 2s;
        animation-direction: alternate;
        animation-iteration-count: infinite;
        animation-name: invalidMark;
    }

    .invalid-mark img
    {
        height: 1.05em;
    }

    @keyframes invalidMark {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }

    @media (max-width: 767px) {

    }

    @media (min-width: 768px) {

    }

    @media (max-width: 991px) {

        .container-header .col-right
        {
            text-align: left;
        }

    }

    @media (min-width: 992px) {

    }

    @media (max-width: 1199px) {

        .container-header
        {
            display: block;
        }

    }

    @media (min-width: 1200px) {

        .container-no-form-items
        {
            padding: 4em 8em;
        }

    }
</style>
