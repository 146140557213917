import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import VueAxios from 'vue-axios'
import router from './router'

import Modules from '@/store/index'

//Use
Vue.use(VueAxios, axios)
Vue.use(Vuex)

export default new Vuex.Store(
    {
        modules: Modules
    }
)
