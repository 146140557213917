<template>
    <component
        :disabled="disabled"
        :is="selectedEditor"
        v-model="model" />
</template>

<script>

    import ComponentTinyMCE5 from "@/components/Editor/ComponentTinyMCE5"

    export default {
        name: 'ComponentEditor',
        props: [
            'disabled',
            'value'
        ],
        mixins: [],
        components: {
            ComponentTinyMCE5,
        },//components
        data() {
            return {
                model: null,
            }
        },//data
        computed: {

            selectedEditor() {
                return ComponentTinyMCE5
            }

        },//computed
        methods: {},//methods
        watch: {

            model(value, oldValue) {
                this.$emit('input', value)
            },

            value(value_, oldValue) {
                this.model = value_
            },

        },//watch
        created() {},//created
        mounted() {
            this.model = this.value
        },//mounted
        updated() {},//updated
        destroyed() {},//destroyed
    }
</script>

<style scoped>
    @media (max-width: 767px) {

    }

    @media (min-width: 768px) {

    }

    @media (min-width: 992px) {

    }

    @media (max-width: 1199px) {

    }

    @media (min-width: 1200px) {

    }
</style>
