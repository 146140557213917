<template>
    <section id="view-business-users">

        <ComponentViewTabs class="component-view-tabs" :tabs="this.viewTabs"></ComponentViewTabs>

        <template v-if="tableUsers.requesting">
            <ComponentLoading></ComponentLoading>
        </template>
        <template v-else>

            <div class="container-view-header">
                <div class="col-left">
                    <h1>Usuários</h1>
                </div>
                <div class="col-right">
                    <router-link class="btn-default btn-primary btn-add-user" tag="button" title="Novo usuário" to="/business/users/add" v-if="$can('create', 'User')">
                        <img src="~@/assets/img/ico/ico_add_client_white.svg" alt="">
                        <span v-if="$mq.match(/lg/)">Novo usuário</span>
                    </router-link>
                </div>
            </div>

            <ComponentDataTable
                :data="tableUsers"
                name="TableUsers"
                @tableToInitialState="tableUsersTableToInitialState" />

            <ComponentModal class="component-modal-confirm-delete" ref="modalConfirmDelete">
                <template v-slot:title>
                    <span>Excluir usuário</span>
                </template>
                <template v-slot:content>
                    <p>Tem certeza de que deseja excluir este usuário?</p>
                </template>
                <template v-slot:footer>
                    <button type="button" class="btn-default btn-terciary" @click="modalConfirmDeleteButtonCancel">Cancelar</button>
                    <button type="button" class="btn-default btn-primary-red" @click="modalConfirmDeleteButtonDelete">Excluir</button>
                </template>
            </ComponentModal>

        </template>

    </section>
</template>

<script>
    import MixinView from '@/mixins/MixinView.js'
    import MixinBusiness from '@/mixins/Business/MixinBusiness'

    import ComponentDataTable from "@/components/ui/ComponentDataTable"
    import ComponentLoading from "@/components/ui/ComponentLoading"
    import ComponentModal from "@/components/ui/ComponentModal"

    export default {
        name: 'BusinessUsers',
        props: [],
        mixins: [MixinView, MixinBusiness],
        components: {
            ComponentDataTable,
            ComponentLoading,
            ComponentModal
        },//components
        data() {
            return {

                modalConfirmDelete: {
                    id: null
                },

                tableUsersData: {
                    data: null,
                    requesting: false
                },

            }
        },//data
        computed: {

            isUserLimited() {
                return this.user.account_type === 'limited'
            },

            isUserStandard() {
                return this.user.account_type === 'standard'
            },

            tableUsers() {

                let deleteShowCondition = null

                let editShowCondition = null

                let rowClickCondition = null

                if(this.isUserLimited) {

                    deleteShowCondition = false

                    editShowCondition = false

                    rowClickCondition = false

                }
                else if(this.isUserStandard) {

                    deleteShowCondition = {
                        action: 'delete',
                        subject: 'User',
                        rawCondition: `row.id !== ${this.user.id}`
                    }

                    editShowCondition = {
                        action: 'edit',
                        subject: 'User',
                        rawCondition: `row.id !== ${this.user.id}`
                    }

                    rowClickCondition = editShowCondition

                } else {

                    deleteShowCondition = {
                        action: 'delete',
                        subject: 'User'
                    }

                    editShowCondition = {
                        action: 'edit',
                        subject: 'User'
                    }

                    rowClickCondition = editShowCondition

                }

                return {
                    actions: [
                        {
                            icon: require('@/assets/img/ico/ico_edit.svg'),
                            handler: (e, action, row) => {
                                this.buttonUserEditClick(e, row.id)
                            },
                            showCondition: editShowCondition,
                            tooltip: 'Editar usuário'
                        },
                        {
                            icon: require('@/assets/img/ico/ico_delete_color.svg'),
                            handler: (e, action, row) => {
                                this.buttonUserDeleteClick(e, row.id)
                            },
                            showCondition: deleteShowCondition,
                            tooltip: 'Excluir usuário'
                        }
                    ],
                    columns: [
                        {
                            alias: 'E-mail',
                            id: 'email',
                            show: true
                        },
                        {
                            alias: 'Nome',
                            id: 'name',
                            show: true
                        },
                        {
                            alias: 'Criado em',
                            customContent: (content) => {
                                return this.$moment(content).format('DD/MM/YYYY HH:mm')
                            },
                            id: 'created_at',
                            show: false
                        }
                    ],
                    data: this.tableUsersData.data,
                    requesting: this.tableUsersData.requesting,
                    rowClick: (e, row) => {
                        this.buttonUserRowClick(e, row.id)
                    },
                    rowClickCondition,
                    rowClickTooltip: 'Editar usuário'
                }
            },

            user() {
                return this.$store.getters['App/user']
            }

        },//computed
        methods: {

            deleteUser(id) {

                const companyId = this.user.company_id

                this.tableUsersData.requesting = true

                this.axios
                    .delete(`api/v1/companies/${companyId}/users/${id}`)
                    .then(
                        response => {

                            this.$eventbus.$emit(
                                'ComponentToast/add',
                                {
                                    autoHide: true,
                                    content: `Usuário excluído com sucesso`,
                                    icon: null,
                                    showCloseButton: false,
                                    type: 'success'
                                }
                            )

                            this.loadUsers()

                        }
                    )
                    .catch(
                        error => {

                            this.$eventbus.$emit(
                                'ComponentToast/add',
                                {
                                    autoHide: true,
                                    content: 'Erro ao excluir o usuário',
                                    icon: null,
                                    showCloseButton: false,
                                    type: 'error'
                                }
                            )

                        }
                    )
                    .finally(
                        () => {
                            this.tableUsersData.requesting = false
                        }
                    )

            },

            loadUsers() {

                const companyId = this.user.company_id

                this.tableUsersData.requesting = true

                this.$fetch.get(`/api/v1/companies/${companyId}/users`)
                    .then(
                        response => {
                            this.tableUsersData.data = response.data
                        }
                    )
                    .catch(
                        error => {

                            this.$eventbus.$emit(
                                'ComponentToast/add',
                                {
                                    autoHide: true,
                                    content: 'Erro ao carregar usuários',
                                    icon: null,
                                    showCloseButton: false,
                                    type: 'error'
                                }
                            )

                        }
                    )
                    .finally(
                        () => {
                            this.tableUsersData.requesting = false
                        }
                    )

            },

            //Events

            buttonUserEditClick(e, id) {
                this.$router.push(`/business/users/edit/${id}`)
            },

            buttonUserDeleteClick(e, id) {

                this.modalConfirmDelete.id = id

                this.$refs.modalConfirmDelete.show = true

            },

            buttonUserRowClick(e, id) {
                this.$router.push({ path: `/business/users/edit/${id}` }, ()=>{}, ()=>{})
            },

            modalConfirmDeleteButtonCancel(e) {

                this.$refs.modalConfirmDelete.show = false

                this.modalConfirmDelete.id = null

            },

            modalConfirmDeleteButtonDelete(e) {

                this.$refs.modalConfirmDelete.show = false

                this.deleteUser(this.modalConfirmDelete.id)

                this.modalConfirmDelete.id = null

            },

            tableUsersTableToInitialState() {
                this.loadUsers()
            }

        },//methods
        watch: {},//watch
        created() {},//created
        mounted() {
            this.loadUsers()
        },//mounted
        updated() {},//updated
        destroyed() {},//destroyed
        beforeRouteEnter(to, from, next) {
            next(
                vm => {
                }
            )
        },//beforeRouteEnter
        beforeRouteUpdate(to, from, next) {
            next()
        },//beforeRouteUpdate
        beforeRouteLeave(to, from, next) {
            next()
        }//beforeRouteLeave

    }
</script>

<style scoped>
    #view-business-users
    {

    }

    .component-view-tabs
    {
        margin-bottom: 1.5em;
    }

    .container-view-header
    {
        margin-bottom: 1.25em;
    }

    .btn-add-user img
    {
        height: 1.25em;
        margin-right: 0.5em;
        vertical-align: middle;
    }

    .btn-add-user span
    {
        vertical-align: middle;
    }

    @media (max-width: 767px) {

    }

    @media (min-width: 768px) and (max-width: 1199px) {

    }

    @media (max-width: 1199px) {

        .btn-add-user
        {
            text-align: center;
        }

        .btn-add-user img
        {
            margin-right: 0;
        }

    }

    @media (min-width: 1200px) {

        .btn-add-user
        {
            width: 15rem;
        }

    }
</style>
