<template>
    <section id="layout-default">
        <div class="side-menu" v-if="$mq.match(/lg/)">
            <header>
                <router-link to="/customers"><img src="~@/assets/img/logo_ordexa-white.svg" alt=""></router-link>
            </header>
            <nav>
                <router-link tag="button" :to="{ name: 'Customers' }">
                    <img src="~@/assets/img/ico/ico_clients.svg" alt="">
                    <span>{{ $store.getters['Companies/customerTreatmentPlural'] }}</span>
                </router-link>
                <button type="button" @click="buttonModelsClick">
                    <img src="~@/assets/img/ico/ico_models.svg" alt="">
                    <span>Modelos</span>
                </button>
                <div class="container-submenu">
                    <router-link tag="button" :to="{ name: 'NoteTemplates' }">Modelos de anotação</router-link>
                    <router-link tag="button" :to="{ name: 'PrintLayout' }">Layout de impressão</router-link>
                </div>
                <button type="button" @click="buttonCompanyClick">
                    <img src="~@/assets/img/ico/ico_company.svg" alt="">
                    <span>{{ $store.getters['Companies/companyTreatmentSingular'] }}</span>
                </button>
                <div class="container-submenu">
                    <router-link tag="button" :to="{ name: 'BusinessMain' }">Geral</router-link>
                    <router-link tag="button" :to="{ name: 'BusinessUsers' }">Usuários do Ordexa</router-link>
                    <router-link tag="button" :to="{ name: 'BusinessForm' }">Campos do formulário</router-link>
                </div>
            </nav>
            <footer>
                <div>
                    <button type="button" class="btn-help" @click="accessHelp">
                        <img src="~@/assets/img/ico/ico_help_white.svg" alt="">
                        <span>Ajuda</span>
                    </button>
                </div>
                <div>&copy; Elegant Tech</div>
            </footer>
        </div>
        <div class="container-group-main">
            <header>
                <div class="col-left">
                    <template v-if="$mq.match('xs|sm|md')">
                        <button type="button" class="btn-hamburguer-menu" @click.stop="buttonHamburguerMenuClick">
                            <img src="~@/assets/img/ico/ico_hamburger_menu.svg" alt="">
                        </button>
                        <router-link to="/customers">
                            <img class="logo-ordexa" src="~@/assets/img/logo_ordexa.svg" alt="">
                        </router-link>
                    </template>
                </div>
                <div class="col-right">
                    <span class="container-environment-id" @click="containerEnvironmentIdClick" v-if="env">
                        <span v-if="$mq.match('xs|sm')">{{ env.split('')[0] }}</span>
                        <span v-else>{{ env }}</span>
                    </span>
                    <button type="button" class="btn-user" ref="buttonUser" @click.stop="buttonUserClick">
                        <span class="profile-picture">
                            <img :src="profilePictureUrl" alt="">
                        </span>
                        <span class="user-name" v-if="$mq.match(/lg/)">{{ userName }}</span>
                        <img src="~@/assets/img/ico/ico_arrow_down.svg" alt="">
                    </button>
                    <div class="container-user-menu" ref="containerUserMenu" v-click-outside="containerUserMenuClickOutside">
                        <button type="button" @click="buttonProfileClick">
                            <span class="container-icon"><img src="~@/assets/img/ico/ico_profile_dark.svg" alt=""></span>
                            <span class="label">Ver perfil</span>
                        </button>

                        <a :href="reportsLinkUrl" target="_blank" v-if="isUserSystemAdmin || isUserStandard">
                            <span class="container-icon"><img src="~@/assets/img/ico/ico_reports_dark.svg" alt=""></span>
                            <span class="label">Relatórios</span>
                        </a>

                        <button type="button" @click="buttonReportsClick" v-else>
                            <span class="container-icon"><img src="~@/assets/img/ico/ico_reports_dark.svg" alt=""></span>
                            <span class="label">Relatórios</span>
                        </button>

                        <a :href="planAndMembershipLinkUrl" target="_blank" v-if="isUserSystemAdmin || isUserStandard">
                            <span class="container-icon"><img src="~@/assets/img/ico/ico_payment_cards_dark.svg" alt=""></span>
                            <span class="label">Plano e Assinatura</span>
                        </a>

                        <button type="button" @click="buttonLogoutClick">
                            <span class="container-icon"><img src="~@/assets/img/ico/ico_sign_out_dark.svg" alt=""></span>
                            <span class="label">Sair</span>
                        </button>
                    </div>
                </div>
            </header>
            <div class="container-router-view" ref="containerRouterView" @scroll="containerRouterViewScroll">
                <div class="container-mobile-menu" v-if="$mq.match(/xs|sm|md/) && mobileMenu.show" @click="containerMobileMenuClick" v-click-outside="containerMobileMenuClickOutside">
                    <nav>
                        <router-link tag="button" :to="{ name: 'Customers' }">
                            <img src="~@/assets/img/ico/ico_clients.svg" alt="">
                            <span>{{ $store.getters['Companies/customerTreatmentPlural'] }}</span>
                        </router-link>
                        <button type="button" @click.stop="buttonModelsClick">
                            <img src="~@/assets/img/ico/ico_models.svg" alt="">
                            <span>Modelos</span>
                        </button>
                        <div class="container-submenu">
                            <router-link tag="button" :to="{ name: 'NoteTemplates' }">Modelos de anotação</router-link>
                            <router-link tag="button" :to="{ name: 'PrintLayout' }">Layout de impressão</router-link>
                        </div>
                        <button type="button" @click.stop="buttonCompanyClick">
                            <img src="~@/assets/img/ico/ico_company.svg" alt="">
                            <span>{{ $store.getters['Companies/companyTreatmentSingular'] }}</span>
                        </button>
                        <div class="container-submenu">
                            <router-link tag="button" :to="{ name: 'BusinessMain' }">Geral</router-link>
                            <router-link tag="button" :to="{ name: 'BusinessUsers' }">Usuários do Ordexa</router-link>
                            <router-link tag="button" :to="{ name: 'BusinessForm' }">Campos do formulário</router-link>
                        </div>
                    </nav>
                </div>
                <transition name="fade" mode="out-in">
                    <router-view></router-view>
                </transition>
            </div>
        </div>
    </section>
</template>

<script>
    import MixinLayout from '@/mixins/MixinLayout.js'

    export default {
        name: 'LayoutDefault',
        props: [],
        mixins: [MixinLayout],
        components: {},//components
        data() {
            return {

                mobileMenu: {
                    show: false
                }

            }
        },//data
        computed: {
          
            accountType() {
              return 
            },

            companyId() {
              return this.$store.getters['App/companyId']
            },

            company() {
                return this.$store.getters['Companies/company']
            },

            env() {

                const vueAppEnv = process.env.VUE_APP_ENV

                let ret = false

                if(vueAppEnv) {

                    if(vueAppEnv !== 'production') {
                        ret = vueAppEnv
                    }

                } else {
                    ret = 'not set'
                }

                return ret

            },

            isUserStandard() {
              return this.user.account_type === 'standard'
            },

            isUserSystemAdmin() {
              return this.user.account_type === 'system_admin'
            },

            planAndMembershipLinkUrl() {
                return process.env.VUE_APP_API_BASE_URL + '/billing/plan'
            },

            reportsLinkUrl() {
                return process.env.VUE_APP_API_BASE_URL + '/reports/index'
            },

            profilePictureUrl() {

                if(this.user.image_url) {
                    return this.user.image_url
                } else {
                    return require('@/assets/img/ico/ico_profile.svg')
                }

            },

            user() {
                return this.$store.getters['App/user']
            },

            userName() {

                try {
                    return this.user.name.split(' ')[0]
                }
                catch(error) {
                    return ''
                }

            }

        },//computed
        methods: {

            accessHelp() {
              window.open("https://ordexa.com.br/guia-pratico", "_blank");    
            },

            closeUserMenu(e) {

                const elButton = this.$refs.buttonUser
                const elMenu = elButton.nextSibling

                elButton.classList.remove('active')
                elMenu.classList.remove('active')

            },

            openCloseSubmenu(e) {

                const elButton = e.currentTarget
                const elSubmenu = elButton.nextSibling

                if(elSubmenu.classList.contains('active')) {
                    elButton.classList.remove('active')
                    elSubmenu.classList.remove('active')
                } else {
                    elButton.classList.add('active')
                    elSubmenu.classList.add('active')
                }

            },

            openCloseUserMenu(e) {

                const elButton = this.$refs.buttonUser
                const elMenu = elButton.nextSibling

                if(elMenu.classList.contains('active')) {
                    elButton.classList.remove('active')
                    elMenu.classList.remove('active')
                } else {
                    elButton.classList.add('active')
                    elMenu.classList.add('active')
                }

                elMenu.style.left = (elButton.getBoundingClientRect().right - elMenu.getBoundingClientRect().width) + 'px'
                elMenu.style.top = (elButton.getBoundingClientRect().height + elButton.getBoundingClientRect().top) + 12 + 'px'

            },

            //Events

            buttonHamburguerMenuClick(e) {
                this.closeUserMenu()
                this.mobileMenu.show = !this.mobileMenu.show
            },

            buttonModelsClick(e) {
                this.openCloseSubmenu(e)
            },

            buttonCompanyClick(e) {
                this.openCloseSubmenu(e)
            },

            buttonUserClick(e) {
                this.mobileMenu.show = false
                this.openCloseUserMenu()
            },

            buttonProfileClick(e) {
                this.openCloseUserMenu(e)
                this.$router.push({ name: 'PersonalProfile' }, () => {}, (error) => {})
            },

            buttonLogoutClick(e) {

                this.$store.dispatch('App/logout')

                this.$router.replace({ name: 'Login' }, () => {}, () => {})

                this.$eventbus.$emit(
                    'ComponentToast/add',
                    {
                        autoHide: true,
                        content: 'Sessão finalizada com segurança',
                        icon: null,
                        showCloseButton: false,
                        type: 'success'
                    }
                )

            },

            buttonReportsClick(e) {

                this.$eventbus.$emit(
                    'ComponentToast/add',
                    {
                        autoHide: true,
                        content: 'Você não tem acesso aos relatórios. Solicite este acesso a um usuário administrador.',
                        icon: null,
                        showCloseButton: false,
                        type: 'warning'
                    }
                )

            },

            containerEnvironmentIdClick(e) {
                e.currentTarget.style.display = 'none'
            },

            containerMobileMenuClick(e) {
                this.mobileMenu.show = false
            },

            containerMobileMenuClickOutside(e) {
                this.mobileMenu.show = false
            },

            containerRouterViewScroll(e) {

                this.$eventbus.$emit(
                    'ContainerRouterView/scroll',
                    {
                        e,
                        containerRouterView: this.$refs.containerRouterView,
                        scrollTop: this.$refs.containerRouterView.scrollTop
                    }
                )

            },

            containerUserMenuClickOutside(e) {
                this.closeUserMenu()
            },

        },//methods
        watch: {

            '$route': function(value, oldValue) {
                this.mobileMenu.show = false
            }

        },//watch
        created() {

            this.$eventbus.$on(
                'ContainerRouterView/scrollTo',
                obj => {
                    this.$refs.containerRouterView.scrollTo(obj)
                }
            )

        },//created
        mounted() {},//mounted
        updated() {},//updated
        destroyed() {},//destroyed
        beforeRouteEnter(to, from, next) {
            next(
                vm => {
                }
            )
        },//beforeRouteEnter
        beforeRouteUpdate(to, from, next) {
            next()
        },//beforeRouteUpdate
        beforeRouteLeave(to, from, next) {
            next()
        }//beforeRouteLeave

    }
</script>

<style scoped>
    #layout-default
    {
        display: flex;

        height: 100vh;
        overflow: hidden;
        width: 100vw;
    }

    header
    {
        height: 66px;
        min-height: 66px;
    }

    .side-menu
    {
        flex: 2;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        background-color: #555;
        max-width: 300px;
        min-width: 230px;
        overflow: auto;
    }

    .side-menu header
    {
        display: flex;
        align-items: center;
        justify-content: center;

        box-sizing: border-box;
        padding: 1.2rem;
    }

    .side-menu nav
    {
        flex: 1;
    }

    .side-menu nav button
    {
        background-color: transparent;
        border: none;
        color: #fff;
        margin: 0;
        padding: 0;
        width: 100%;
    }

    .side-menu nav > button
    {
        display: flex;
        align-items: center;

        padding: 1.2rem 1.8rem;

        transition: all 0.2s;
    }

    .side-menu nav > button.active
    {
        background-color: #1f1f1f;
    }

    .side-menu nav > button img
    {
        margin-right: 0.5em;
        max-height: 36px;
        width: 48px;
    }

    .side-menu footer
    {
        box-sizing: border-box;
        color: #fff;
        font-size: 0.9em;
        padding: 1.2rem;
        text-align: center;
    }

    .side-menu footer > div
    {
        margin-top: 1.5em;
    }

    .side-menu footer .btn-help
    {
        background-color: transparent;
        border: none;
        color: #fff;
        margin: 0;
        padding: 0;
    }

    .side-menu footer .btn-help img
    {
        height: 1.25em;
        margin-right: 0.35em;
        vertical-align: middle;
    }

    .side-menu footer .btn-help span
    {
        font-size: 0.95em;
        vertical-align: middle;
    }

    .side-menu .container-submenu
    {
        background-color: #3b3b3b;
        height: 0;
        opacity: 0;
        overflow: hidden;

        transition: all 0.2s;
    }

    .side-menu .container-submenu.active
    {
        height: auto;
        opacity: 1;
    }

    .side-menu .container-submenu button
    {
        display: flex;
        align-items: center;
        justify-content: flex-start;

        font-size: 0.85em;
        font-weight: 300;
        padding: 1.2rem 1.8rem;
    }

    .side-menu .container-submenu button.active
    {
        color: #29B1CC;
    }

    .side-menu .container-submenu button:hover
    {
        background-color: #29B1CC;
        color: #3b3b3b;
    }

    .container-group-main
    {
        flex: 8;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        background-color: #efefef;
        max-width: 100vw;
    }

    .container-group-main header
    {
        display: flex;

        background-color: #fff;
        box-shadow: 0 1px 12px 0 rgba(0, 0, 0, 0.15);
    }

    .container-group-main header > div
    {
        flex: 1;
    }

    .container-group-main header .col-left
    {
        display: inline-flex;
        align-items: center;
    }

    .btn-hamburguer-menu
    {
        background-color: #555;
        border: none;
        height: 66px;
        margin-right: 1.2em;
        min-height: 66px;
        padding: 0;
        width: 66px;
    }

    .btn-hamburguer-menu img
    {
        height: 1.5em;
    }

    .logo-ordexa
    {
        height: 1.25em;
    }

    .container-group-main header .col-right
    {
        display: inline-flex;
        align-items: center;
        justify-content: flex-end;

        padding: 0 1.2rem;
    }

    .btn-user
    {
        display: inline-flex;
        align-items: center;

        background-color: transparent;
        border: none;
        margin: 0;
        padding: 0;
    }

    .btn-user .profile-picture
    {
        display: inline-flex;
        align-items: center;
        justify-content: center;

        border-radius: 100px;
        height: 1.6em;
        overflow: hidden;
        width: 1.6em;
    }

    .btn-user .profile-picture img
    {
        min-height: 1.6em;
        width: 1.6em;
        transform: scale(1.25);
    }

    .btn-user .user-name
    {
        font-size: 0.9em;
        margin: 0 0.75em;
    }

    .btn-user > img:last-child
    {
        width: 0.85em;
    }

    .container-user-menu
    {
        background-color: #fff;
        border-radius: 4px;
        box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.15);
        display: none;
        overflow: hidden;
        position: absolute;
        white-space: nowrap;
        z-index: 100001;
    }

    .container-user-menu.active
    {
        display: block;
    }

    .container-user-menu a,
    .container-user-menu button
    {
        display: flex;
        align-items: center;

        background-color: transparent;
        border: none;
        box-sizing: border-box;
        margin: 0;
        padding: 1em 2em 1em 1em;
        text-decoration: none;
        width: 100%;
    }

    .container-user-menu a:hover,
    .container-user-menu button:hover
    {
        background-color: #cdf1f4;
    }

    .container-user-menu .container-icon
    {
        display: inline-block;
        text-align: center;
        width: 2.75em;
    }

    .container-user-menu .container-icon img
    {
        max-height: 1.5em;
        width: 1.75em;
    }

    .container-user-menu .label
    {
        color: buttontext;
        font-size: 0.95em;
        font-weight: 500;
    }

    .container-group-main .container-router-view
    {
        flex: 1;
        display: flex;

        overflow: auto;
    }

    .container-group-main .container-router-view >>> [id^="view-"]
    {
        box-sizing: border-box;
        padding: 1.2rem;
        width: 100%;
    }

    .container-mobile-menu
    {
        background-color: #444;
        border-radius: 0 0 4px 0;
        box-shadow: 3px 3px 6px 0 rgba(0, 0, 0, 0.25);
        overflow: auto;
        position: absolute;
        width: 85%;
        z-index: 10000;
    }

    .container-mobile-menu button
    {
        background-color: transparent;
        border: none;
        display: block;
        color: #fff;
        margin: 0;
        padding: 0;
        text-align: left;
        width: 100%;
    }

    .container-mobile-menu nav > button
    {
        display: flex;
        align-items: center;

        padding: 1.5rem;

        transition: all 0.2s;
    }

    .container-mobile-menu nav > button img
    {
        margin-right: 0.5em;
        max-height: 36px;
        width: 48px;
    }

    .container-mobile-menu .container-submenu
    {
        background-color: #3b3b3b;
        height: 0;
        opacity: 0;
        overflow: hidden;

        transition: all 0.2s;
    }

    .container-mobile-menu .container-submenu.active
    {
        height: auto;
        opacity: 1;
    }

    .container-mobile-menu .container-submenu button
    {
        display: flex;
        align-items: center;
        justify-content: flex-start;

        font-weight: 300;
        padding: 1.5rem;
    }

    .container-environment-id
    {
        display: inline-flex;
        align-items: center;
        justify-content: center;

        background-color: #fd0;
        border-radius: 100px;
        cursor: pointer;
        font-size: 0.675em;
        font-weight: bold;
        letter-spacing: 0.015em;
        height: 2em;
        line-height: 100%;
        margin-right: 1em;
        padding: 0 1em;
        text-align: center;
        text-transform: uppercase;
    }

    @media (max-width: 767px) {

        #layout-default
        {
            overflow: auto;
        }

    }

    @media (min-width: 768px) and (max-width: 1199px) {

    }

    @media (max-width: 1199px) {

        .btn-user .profile-picture
        {
            margin-right: 0.5em;
        }

    }

    @media (min-width: 1200px) {

    }
</style>
