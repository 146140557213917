<template>
  <div id="app">

    <router-view :class="['view', { 'no-print': printMode }]"/>

    <ComponentToast :class="{ 'no-print': printMode }"></ComponentToast>

    <ComponentPrint></ComponentPrint>

  </div>
</template>

<script>
  import ComponentPrint from "@/components/ui/ComponentPrint"
  import ComponentToast from "@/components/ui/ComponentToast"

  export default {
    name: 'App',
    components: {
      ComponentPrint,
      ComponentToast
    },//components
    data() {
      return {

        printMode: false

      }
    },
    computed: {

    }, //computed
    created() {

      const vm = this

      const noSessionRoutes = [
        '/login',
        '/signup',
        '/subscription-suspended',
      ];

      this.$eventbus.$on(
        'ComponentToast/close',
        () => {
          this.printMode = false
        }
      )

      document.addEventListener(
        'visibilitychange',
        (e) => {

          if(noSessionRoutes.indexOf(vm.$route.path) < 0) {

            if (document.visibilityState === 'visible') {
              vm.$helpers.checkFrontendSessionExpiry({route: vm.$route, router: vm.$router})
            }

          }

        },
        false
      );

      document.addEventListener(
        'click',
        (e) => {

          if(noSessionRoutes.indexOf(vm.$route.path) < 0) {

            if (document.visibilityState === 'visible') {
              vm.$helpers.checkFrontendSessionExpiry({e, route: vm.$route, router: vm.$router})
            }

          }

        },
        false
      );

      window.addEventListener(
        'httpRequestError',
        (e) => {

          try {

            const error = e.detail.error

            if(error.response.status === 500) {

              this.$eventbus.$emit(
                      'ComponentToast/add',
                      {
                        autoHide: true,
                        content: 'Falha de comunicação com o servidor',
                        icon: null,
                        showCloseButton: false,
                        type: 'error'
                      }
              )

            }

          }
          catch(err) {

            this.$store.dispatch('App/logout')

            this.$eventbus.$emit(
                'ComponentToast/add',
                {
                  autoHide: true,
                  autoHideDelay: 8000,
                  content: 'Falha de comunicação com o servidor. Por favor, faça o login novamente.',
                  icon: null,
                  showCloseButton: false,
                  type: 'error'
                }
            )

          }

        },
        false
      )

      window.addEventListener(
        'offline',
        (e) => {

          this.$eventbus.$emit(
                  'ComponentToast/add',
                  {
                    autoHide: true,
                    content: 'Você está offline. Verifique sua conexão.',
                    icon: null,
                    showCloseButton: false,
                    type: 'error'
                  }
          )

        }
      )

      this.defineEditor()

    },//created
    methods: {

      defineEditor() {

        const route = this.$route

        if(!this._.isEmpty(route.query)) {

          if(route.query.editor) {

            sessionStorage.setItem('ordexa/editor', route.query.editor)

            window.location.href = '#' +  this.$route.path

            setTimeout(
              () => {
                window.location.reload()
              },
              100
            )

          }

        }

      },

      //Events

    },//methods
    watch: {

      '$route': function(value, oldValue) {
        this.defineEditor()
      },

    },//watch
  }

</script>

<style lang="scss">
  *
  {
    font-family: 'Montserrat', sans-serif;
  }

  button
  {
    cursor: pointer;
    font-size: 1em;
    outline: none;
  }

  button:disabled
  {
    opacity: 0.4;
  }

  input,
  textarea
  {
    font-size: 1em;
    font-weight: 300;
    outline: none;
  }

  fieldset
  {
    background-color: transparent;
    border: none;
    margin: 0;
    padding: 0;
  }

  hr
  {
    border: none;
    border-bottom: 1px solid #d0d0d0;
    margin: 1em 0;
  }

  a
  {
    color: #29B1CC;
  }

  #app
  {
    display: flex;
    align-items: stretch;

    font-weight: 300;
    width: 100%;
  }

  .view
  {
    width: 100%;
  }

  .btn-default
  {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

    border: none;
    border-radius: 100px;
    color: #fff;
    font-weight: 500;
    padding: 0.75em 1.5em;

    transition: all 0.1s;
  }

  .btn-default:active:focus
  {
    transform: scale(0.975);
  }

  .btn-default img
  {
    height: 1em;
    margin-right: 0.5em;
    vertical-align: middle;
  }

  .btn-primary
  {
    background-color: #29B1CC;
  }

  .btn-secondary
  {
    background-color: #a6a6a6;
  }

  .btn-terciary
  {
    background-color: transparent;
    color: #333;
  }

  .btn-primary-red
  {
    background-color: #EB5757;
  }

  .btn-primary-green
  {
    background-color: #2aaa64;
  }

  .btn-primary-outline
  {
    background-color: transparent;
    border: 2px solid #29B1CC;
    color: #29B1CC;
  }

  .container-group-input
  {
    text-align: left;
  }

  .container-group-input label
  {
    display: block;
    margin-bottom: 0.35em;
  }

  .required-mark
  {
    color: #d00;
    margin-left: 0.35em;
  }

  .container-view-header
  {
    display: flex;
    align-items: center;
  }

  .container-view-header h1
  {
    font-size: 1.8em;
    font-weight: 300;
    line-height: 110%;
    margin: 0;
  }

  .container-view-header > div
  {
    flex: 1;
  }

  .container-view-header .col-left
  {
    margin-right: 1em;
  }

  .container-view-header .col-right
  {
    text-align: right;
  }

  .fade-enter-active,
  .fade-leave-active {
    transition-duration: 0.2s;
    transition-property: opacity;
    transition-timing-function: ease;
  }

  .fade-enter,
  .fade-leave-active {
    opacity: 0
  }

  .icon-circle-segment-animated
  {
    height: 1.2em;
    margin-right: 0.5em;
    vertical-align: middle;

    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-name: iconCircleSegmentAnimated;
  }

  .before-content-small:before
  {
    content: 'Pequena' !important
  }

  .before-content-large:before
  {
    content: 'Grande' !important
  }

  .before-content-huge:before
  {
    content: 'Enorme' !important
  }

  @keyframes iconCircleSegmentAnimated {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(359deg);
    }
  }

  @media (max-width: 767px) {

  }

  @media (max-width: 1199px) {

    *
    {
      font-weight: 400;
    }


  }

  @media (min-width: 1200px) {

  }

  @media print {

    .no-print
    {
      display: none !important;
    }

  }

  .tox-notification--warning {
    display: none !important;
  }
</style>
