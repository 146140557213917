<template>
    <section id="view-onboarding-password-change">

        <h1>Defina sua senha</h1>
        <ComponentPersonalProfilePasswordChange
            ref="componentPersonalProfilePasswordChange"
            :user="user"
            @requestingDone="componentPersonalProfilePasswordChangeRequestingDone"
            @requestingUpdate="componentPersonalProfilePasswordChangeRequestingUpdate"
            @updated="componentPersonalProfilePasswordChangeUpdated"/>
        <div class="container-buttons">
            <router-link class="btn-default btn-secondary btn-back" :disabled="requestingUpdateUser" tag="button" :to="{ name: 'OnboardingPersonalProfile' }">
                <img src="~@/assets/img/ico/ico_arrow-white.svg" alt="">
                <span v-if="$mq.match(/lg/)">VOLTAR</span>
            </router-link>
            <button class="btn-default btn-primary btn-continue" :disabled="requestingUpdateUser" type="button" @click="buttonContinueClick">

                <template v-if="requestingUpdateUser">
                    <img class="icon-circle-segment-animated" src="~@/assets/img/ico/ico_circle_segment_white.svg" alt="">
                    <span>AGUARDE</span>
                </template>
                <template v-else>
                    <span v-if="$mq.match(/lg/)">CONTINUAR</span>
                    <img src="~@/assets/img/ico/ico_arrow-white.svg" alt="">
                </template>

            </button>
        </div>

    </section>
</template>

<script>
    import MixinView from '@/mixins/MixinView.js'

    import ComponentLoading from "@/components/ui/ComponentLoading"
    import ComponentPersonalProfilePasswordChange from "@/components/ComponentPersonalProfilePasswordChange"

    export default {
        name: 'OnboardingPasswordChange',
        props: [],
        mixins: [MixinView],
        components: {
            ComponentLoading,
            ComponentPersonalProfilePasswordChange
        },//components
        data() {
            return {

                model: {},

                requestingUpdateUser: false,

            }
        },//data
        computed: {

            user() {
                return this.$store.getters['App/user']
            }

        },//computed
        methods: {

            //Events

            buttonContinueClick(e) {

                const password = this.$refs.componentPersonalProfilePasswordChange.model.password
                const passwordConfirmation = this.$refs.componentPersonalProfilePasswordChange.model.passwordConfirmation

                if(password && passwordConfirmation) {

                    if(password !== passwordConfirmation) {

                        this.$eventbus.$emit(
                            'ComponentToast/add',
                            {
                                autoHide: true,
                                content: 'O campo CONFIRME A NOVA SENHA deve ser igual ao campo NOVA SENHA',
                                icon: null,
                                showCloseButton: false,
                                type: 'error'
                            }
                        )

                        return false

                    } else {
                        this.$refs.componentPersonalProfilePasswordChange.updatePassword()
                    }

                } else {

                    this.$eventbus.$emit(
                        'ComponentToast/add',
                        {
                            autoHide: true,
                            content: 'Você deve preencher os dois campos para continuar',
                            icon: null,
                            showCloseButton: false,
                            type: 'error'
                        }
                    )

                }

            },

            componentPersonalProfilePasswordChangeRequestingDone() {
                this.requestingUpdateUser = false
            },

            componentPersonalProfilePasswordChangeRequestingUpdate() {
                this.requestingUpdateUser = true
            },

            componentPersonalProfilePasswordChangeUpdated() {
                this.$router.push({ name: 'OnboardingReady' }, () => {}, () => {})
            },

        },//methods
        watch: {},//watch
        created() {},//created
        mounted() {},//mounted
        updated() {},//updated
        destroyed() {},//destroyed
        beforeRouteEnter(to, from, next) {
            next(
                vm => {
                }
            )
        },//beforeRouteEnter
        beforeRouteUpdate(to, from, next) {
            next()
        },//beforeRouteUpdate
        beforeRouteLeave(to, from, next) {
            next()
        }//beforeRouteLeave

    }
</script>

<style scoped>
    #view-onboarding-password-change
    {
        text-align: center;
    }

    h1
    {
        font-size: 1.5em;
        font-weight: 400;
        margin-bottom: 1em;
        margin-top: 0;
    }

    .component-personal-profile-password-change
    {
        margin: 0 auto;
        width: 400px;
    }

    .component-personal-profile-password-change >>> .subtitle-change-your-password
    {
        display: none;
    }

    .container-buttons
    {
        display: flex;
        justify-content: space-between;
    }

    .container-buttons button
    {
        font-size: 0.9em;
        padding-left: 3em;
        padding-right: 3em;
    }

    .container-buttons button img
    {
        vertical-align: middle;
    }

    .container-buttons button span
    {
        vertical-align: middle;
    }

    .btn-back img
    {
        margin-right: 0.5em;
        transform: rotate(180deg);
    }

    .btn-continue img
    {
        margin-left: 0.5em;
    }

    @media (max-width: 767px) {

        .component-personal-profile-password-change
        {
            width: 100%;
        }

    }

    @media (min-width: 768px) and (max-width: 1199px) {

    }

    @media (max-width: 1199px) {

    }

    @media (min-width: 1200px) {

    }
</style>
