<template>
    <section id="view-customers" :class="[{ 'first-content': !hasItems }]">

        <template v-if="requesting">
            <ComponentLoading></ComponentLoading>
        </template>
        <template v-else>

            <div class="container-component-first-content-box" v-if="!hasItems">
                <ComponentFirstContentBox :button="{ label: 'CADASTRAR O PRIMEIRO ' + $store.getters['Companies/customerTreatmentSingular'].toUpperCase(), clickHandler: this.componentFirstContentButtonClick }" :content="`<p>Aqui você pode acessar e gerenciar todos os dados dos seus ${$store.getters['Companies/customerTreatmentPlural'].toLowerCase()} cadastrados</p>`" :icon="require('@/assets/img/ico/ico_add_client.svg')"></ComponentFirstContentBox>
            </div>

            <template v-if="hasItems">

                <div class="container-view-content">

                    <div class="container-view-header">
                        <div class="col-left">
                            <h1>{{ $store.getters['Companies/customerTreatmentPlural'] }}</h1>
                        </div>
                        <div class="col-right">
                            <router-link class="btn-default btn-primary btn-add-customer" tag="button" :title="`Novo ${$store.getters['Companies/customerTreatmentSingular'].toLowerCase()}`" to="/customers/add">
                                <img src="~@/assets/img/ico/ico_add_client_white.svg" alt="">
                                    <span v-if="$mq.match(/lg/)">Novo {{ $store.getters['Companies/customerTreatmentSingular'].toLowerCase() }}</span>
                            </router-link>
                        </div>
                    </div>

                    <ComponentDataTable
                        :data="tableCustomers"
                        name="TableCustomers"
                        @tableToInitialState="tableCustomersTableToInitialState" />

                </div>

            </template>

        </template>

        <ComponentModal class="component-modal-confirm-delete" ref="modalConfirmDelete">
            <template v-slot:title>
                <span>Excluir {{ $store.getters['Companies/customerTreatmentSingular'].toLowerCase() }}</span>
            </template>
            <template v-slot:content>
                <p>Tem certeza de que deseja excluir {{ modalConfirmDelete.name }}?</p>
                <p>Todas as informações associadas são apagadas. Notas, arquivos e dados associados a esse {{ $store.getters['Companies/customerTreatmentSingular'].toLowerCase() }} serão excluídos permanentemente.</p>
            </template>
            <template v-slot:footer>
                <button type="button" class="btn-default btn-terciary" @click="modalConfirmDeleteButtonCancel">Cancelar</button>
                <button type="button" class="btn-default btn-primary-red" @click="modalConfirmDeleteButtonDelete">Excluir</button>
            </template>
        </ComponentModal>

    </section>
</template>

<script>
    import MixinView from '@/mixins/MixinView.js'

    import ComponentDataTable from "@/components/ui/ComponentDataTable"
    import ComponentFirstContentBox from "@/components/ui/ComponentFirstContentBox"
    import ComponentInfoBox from "@/components/ui/ComponentInfoBox"
    import ComponentInputText from "@/components/ui/ComponentInputText"
    import ComponentLoading from "@/components/ui/ComponentLoading"
    import ComponentModal from "@/components/ui/ComponentModal"

    export default {
        name: 'Customers',
        props: [],
        mixins: [MixinView],
        components: {
            ComponentDataTable,
            ComponentFirstContentBox,
            ComponentInfoBox,
            ComponentInputText,
            ComponentLoading,
            ComponentModal
        },//components
        data() {
            return {

                customerFields: null,

                modalConfirmDelete: {
                    id: null
                },

                requesting: false,

                tableCustomers: {
                    actions: [
                        {
                            icon: require('@/assets/img/ico/ico_edit.svg'),
                            handler: (e, action, row) => {
                                this.buttonCustomerEditClick(e, row.id)
                            },
                            tooltip: `Editar ${this.$store.getters['Companies/customerTreatmentSingular'].toLowerCase()}`
                        },
                        {
                            icon: require('@/assets/img/ico/ico_delete_color.svg'),
                            handler: (e, action, row) => {
                                this.buttonCustomerDeleteClick(e, row.id, row.name)
                            },
                            tooltip: `Excluir ${this.$store.getters['Companies/customerTreatmentSingular'].toLowerCase()}`
                        }
                    ],
                    columns: [],
                    data: null,
                    defaultColumns: [
                        {
                            alias: 'Nome',
                            id: 'name',
                            show: true
                        },
                        {
                            alias: 'Criado em',
                            customContent: (content) => {
                                return this.$moment(content).format('DD/MM/YYYY HH:mm')
                            },
                            id: 'created_at',
                            show: true
                        }
                    ],
                    requesting: false,
                    rowClick: (e, row) => {
                        this.buttonCustomerRowClick(e, row.id)
                    },
                    rowClickTooltip: `Ver detalhes do ${this.$store.getters['Companies/customerTreatmentSingular'].toLowerCase()}`
                }

            }
        },//data
        computed: {

            hasItems() {

                let ret = false

                if(this.tableCustomers.data) {
                    ret = this.tableCustomers.data.length > 0
                }

                return ret

            },

            user() {
                return this.$store.getters['App/user']
            }

        },//computed
        methods: {

            deleteCustomer(id) {

                this.requesting = true

                this.axios
                    .delete(`api/v1/customers/${id}`)
                    .then(
                        response => {

                            this.$eventbus.$emit(
                                'ComponentToast/add',
                                {
                                    autoHide: true,
                                    content: `${this.$store.getters['Companies/customerTreatmentSingular']} excluído com sucesso`,
                                    icon: null,
                                    showCloseButton: false,
                                    type: 'success'
                                }
                            )

                            this.loadCustomers()

                        }
                    )
                    .catch(
                        error => {

                            this.$eventbus.$emit(
                                'ComponentToast/add',
                                {
                                    autoHide: true,
                                    content: `Erro ao excluir o ${this.$store.getters['Companies/customerTreatmentSingular'].toLowerCase()}`,
                                    icon: null,
                                    showCloseButton: false,
                                    type: 'error'
                                }
                            )

                        }
                    )
                    .finally(
                        () => {
                            this.requesting = false
                        }
                    )

            },

            loadCustomerFields() {

                const tableCustomers = this.tableCustomers

                return new Promise(
                    (resolve, reject) => {

                        this.requesting = true

                        this.$store.dispatch('Companies/loadCustomerFields')
                            .then(
                                response => {

                                    const customerFieldsOrder = this.$store.getters['Companies/customerFieldsOrder']
                                    const responseCustomerFields = response.responseCustomerFields

                                    this.customerFields = {}

                                    this.tableCustomers.columns = [...this.tableCustomers.defaultColumns]

                                    customerFieldsOrder.map(
                                        (item, index, array) => {

                                            const column = this._.find(responseCustomerFields.data, { id: item })

                                            // const show = index < 2 //Coloca os dois primeiros campos do formulário como colunas padrão
                                            const show = false

                                            if(column.answer_type === 'date') {

                                                this.tableCustomers.columns.push(
                                                    {
                                                        alias: column.question,
                                                        id: 'customer_field_' + column.id,
                                                        show,
                                                        customContent: (content) => {
                                                            if(content) {
                                                                return this.$moment(content).format('DD/MM/YYYY')
                                                            }
                                                        },
                                                    }
                                                )

                                            }
                                            else if(column.answer_type === 'multiple_response') {

                                                this.tableCustomers.columns.push(
                                                    {
                                                        alias: column.question,
                                                        id: 'customer_field_' + column.id,
                                                        show,
                                                        customContent: (content) => {
                                                          if (content) {
                                                            if(Array.isArray(content)) {
                                                              return content.join(', ').replace(/, $/, '').replace(/\{/, ' - ').replace(/\}/, '')
                                                            } else {
                                                              return String(content).replace(/, $/, '').replace(/\{/, ' - ').replace(/\}/, '')
                                                            }
                                                          } else {
                                                            return "";
                                                          }
                                                        },
                                                    }
                                                )

                                            } else {

                                                this.tableCustomers.columns.push(
                                                    {
                                                        alias: column.question,
                                                        id: 'customer_field_' + column.id,
                                                        show
                                                    }
                                                )

                                            }

                                            this.customerFields[column.id] = column.question

                                        }
                                    )

                                    this.requesting = false

                                    resolve(response)

                                }
                            )
                            .catch(
                                error => {

                                    this.$eventbus.$emit(
                                        'ComponentToast/add',
                                        {
                                            autoHide: true,
                                            content: 'Erro ao carregar campos do formulário',
                                            icon: null,
                                            showCloseButton: false,
                                            type: 'error'
                                        }
                                    )

                                    reject(error)

                                }
                            )
                            .finally(
                                () => {}
                            )

                        }
                    )

            },

            loadCustomers() {

                const companyId = this.user.company_id

                this.requesting = true

                this.$fetch.get('/api/v1/customers')
                    .then(
                        response => {

                            const rawData = response.data

                            let data = []

                            rawData.map(
                                (row, index, array) => {

                                    if(row.fields_values) {

                                        const fieldsValues = row.fields_values

                                        Object.keys(fieldsValues).map(
                                            (fieldKey, index, array) => {
                                                row['customer_field_' + fieldKey] = fieldsValues[fieldKey]
                                            }
                                        )

                                    }

                                    data.push(row)

                                }
                            )

                            this.tableCustomers.data = data

                        }
                    )
                    .catch(
                        error => {

                            this.$eventbus.$emit(
                                'ComponentToast/add',
                                {
                                    autoHide: true,
                                    content: 'Falha de comunicação com o servidor. Por favor, faça o login novamente.',
                                    icon: null,
                                    showCloseButton: false,
                                    type: 'error'
                                }
                            )

                            this.$router.replace({ name: 'Logout' }, () => {}, () => {})

                        }
                    )
                    .finally(
                        () => {
                            this.requesting = false
                        }
                    )

            },

            //Events

            buttonCustomerRowClick(e, id) {
                this.$router.push({ path: `/customers/details/${id}` }, ()=>{}, ()=>{})
            },

            buttonCustomerEditClick(e, id) {
                this.$router.push({ path: `/customers/edit/${id}` }, ()=>{}, ()=>{})
            },

            buttonCustomerDeleteClick(e, id, name) {

                this.modalConfirmDelete.id = id
                this.modalConfirmDelete.name = name

                this.$refs.modalConfirmDelete.show = true

            },

            componentFirstContentButtonClick(e) {

                this.$router.push({ name: 'CustomersAdd' }, () => {}, () => {})

            },

            modalConfirmDeleteButtonCancel(e) {

                this.$refs.modalConfirmDelete.show = false

                this.modalConfirmDelete.id = null
                this.modalConfirmDelete.name = null

            },

            modalConfirmDeleteButtonDelete(e) {

                this.$refs.modalConfirmDelete.show = false

                this.deleteCustomer(this.modalConfirmDelete.id)

                this.modalConfirmDelete.id = null
                this.modalConfirmDelete.name = null

            },

            tableCustomersTableToInitialState() {
                this.loadCustomers()
            }

        },//methods
        watch: {},//watch
        created() {},//created
        mounted() {

            this.loadCustomerFields()
            .then(
                (response) => {
                    this.loadCustomers()
                }
            )
            .catch(
                (error) => {}
            )

        },//mounted
        updated() {},//updated
        destroyed() {},//destroyed
        beforeRouteEnter(to, from, next) {
            next(
                vm => {
                }
            )
        },//beforeRouteEnter
        beforeRouteUpdate(to, from, next) {
            next()
        },//beforeRouteUpdate
        beforeRouteLeave(to, from, next) {
            next()
        }//beforeRouteLeave

    }
</script>

<style scoped>
    #view-customers
    {
        
    }

    #view-customers.first-content
    {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .container-view-content
    {
        margin: 0 auto;
        padding-bottom: 2em;
    }

    .container-view-header
    {
        margin-bottom: 1em;
    }

    .btn-add-customer img
    {
        height: 1.25em;
        vertical-align: middle;
    }

    .btn-add-customer span
    {
        vertical-align: middle;
    }

    .container-group-list-and-right-col
    {
        display: flex;
    }

    .container-group-list-and-right-col .col-main
    {
        flex: 1;
    }

    .container-group-list-and-right-col .col-right
    {
        max-width: 15rem;
        min-width: 15rem;
    }

    .container-component-first-content-box
    {
        display: flex;
        align-items: center;
        justify-content: center;

        width: 100%;
    }

    @media (max-width: 767px) {

        .component-modal-confirm-delete >>> .container-dialog
        {
            max-width: 80%;
        }

    }

    @media (min-width: 768px) and (max-width: 1199px) {

        .component-modal-confirm-delete >>> .container-dialog
        {
            max-width: 540px;
        }

    }

    @media (max-width: 1199px) {

        .btn-add-customer
        {
            text-align: center;
        }

        .btn-add-customer img
        {
            margin-right: 0;
        }

        .container-group-list-and-right-col
        {
            display: block;
        }

    }

    @media (min-width: 1200px) {

        .container-group-list-and-right-col .col-main
        {
            margin-right: 1em;
        }

        .btn-add-customer
        {
            width: 15rem;
        }

        .component-modal-confirm-delete >>> .container-dialog
        {
            max-width: 540px;
        }

        >>> .component-data-table th button
        {
            text-align: left;
        }

    }
</style>
