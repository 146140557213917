import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import Helpers from './helpers'
import './filters'

//vue-mq
import VueMq from 'vue-mq'
Vue.use(
  VueMq,
  {
    breakpoints: {
      sm: 768,
      md: 1200,
      lg: Infinity
    }
  }
)

//vue-lodash
import VueLodash from 'vue-lodash'
Vue.use(VueLodash, {})

//vue-moment
Vue.use(require('vue-moment'))

//vue-click-outside
import ClickOutside from 'vue-click-outside'
Vue.directive(
    'click-outside',
    ClickOutside
)

//vue-img-orientation-changer
import VueImgOrientationChanger from 'vue-img-orientation-changer'
Vue.use(VueImgOrientationChanger)

//vue-gtag
import VueGtag from 'vue-gtag'
Vue.use(
    VueGtag,
    {
        config: { id: 'UA-146730394-3' }
    },
    router
)

//vue-select
import vSelect from 'vue-select'
Vue.component('v-select', vSelect)

//@casl/vue
import { abilitiesPlugin } from '@casl/vue';
import { Ability, subject as caslSubject } from '@casl/ability';
Vue.use(abilitiesPlugin, new Ability([], {}));

//HTTP clients
import axios from '@/axios/index'
import Fetch from '@/fetch/index'

//Rollbar
import Rollbar from 'rollbar';
Vue.prototype.$rollbar = new Rollbar({
    accessToken: '4ccc2ac5aad54cedbf321f2bed36d8a7',
    captureUncaught: true,
    captureUnhandledRejections: true,
    environment: process.env.VUE_APP_ENV,
});
Vue.config.errorHandler = (err, vm, info) => {
    vm.$rollbar.error(err);
    throw err; // rethrow
};

//Prototype
Vue.prototype.$caslSubject = caslSubject
Vue.prototype.$eventbus = new Vue()
Vue.prototype.$fetch = Fetch
Vue.prototype.$helpers = Helpers

//Vue.config.productionTip = false

new Vue({
    router,
    store,
    created() {
        this.$fetch.vm = this
    },
    render: h => h(App)
}).$mount('#app')
