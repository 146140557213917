<template>
    <section id="view-note-templates" :class="[{ 'first-content': !hasItems }]">

        <ComponentViewTabs class="component-view-tabs" :tabs="viewTabs"></ComponentViewTabs>

        <template v-if="tableNoteTemplates.requesting">
            <ComponentLoading></ComponentLoading>
        </template>
        <template v-else>

            <div class="container-component-first-content-box" v-if="!tableNoteTemplates.requesting && !hasItems">
                <ComponentFirstContentBox :button="{ label: 'CADASTRAR O PRIMEIRO MODELO', clickHandler: this.componentFirstContentButtonClick }" content="<p>Aqui você pode acessar e gerenciar todos os seus modelos de anotação</p>" :icon="require('@/assets/img/ico/ico_new_model.svg')"></ComponentFirstContentBox>
            </div>

            <template v-if="!tableNoteTemplates.requesting && hasItems">

                <div class="container-view-content">

                    <div class="container-view-header">
                        <div class="col-left">
                            <h1>Modelos <span v-if="$mq.match(/lg/)">de anotação</span></h1>
                        </div>
                        <div class="col-right">
                            <router-link class="btn-default btn-primary button-add-note-template" tag="button" title="Novo modelo" to="/note-templates/add">
                                <img src="~@/assets/img/ico/ico_new_model_white.svg" alt="">
                                <span v-if="$mq.match(/lg/)">Novo modelo</span>
                            </router-link>
                        </div>
                    </div>

                    <ComponentDataTable
                        :data="tableNoteTemplates"
                        :hideButtonEditTable="true"
                        name="TableNoteTemplates" />

                </div>

            </template>

        </template>

        <ComponentModal class="component-modal-confirm-delete" ref="modalConfirmDelete">
            <template v-slot:title>
                <span>Excluir modelo</span>
            </template>
            <template v-slot:content>
                <p>Tem certeza de que deseja excluir este modelo?</p>
            </template>
            <template v-slot:footer>
                <button type="button" class="btn-default btn-terciary" @click="modalConfirmDeleteButtonCancel">Cancelar</button>
                <button type="button" class="btn-default btn-primary-red" @click="modalConfirmDeleteButtonDelete">Excluir</button>
            </template>
        </ComponentModal>

    </section>
</template>

<script>
    import MixinView from '@/mixins/MixinView.js'
    import MixinTemplates from '@/mixins/Templates/MixinTemplates'

    import ComponentDataTable from "@/components/ui/ComponentDataTable"
    import ComponentFirstContentBox from "@/components/ui/ComponentFirstContentBox"
    import ComponentInfoBox from "@/components/ui/ComponentInfoBox"
    import ComponentInputText from "@/components/ui/ComponentInputText"
    import ComponentLoading from "@/components/ui/ComponentLoading"
    import ComponentModal from "@/components/ui/ComponentModal"

    export default {
        name: 'NoteTemplates',
        props: [],
        mixins: [MixinView, MixinTemplates],
        components: {
            ComponentDataTable,
            ComponentFirstContentBox,
            ComponentInfoBox,
            ComponentInputText,
            ComponentLoading,
            ComponentModal
        },//components
        data() {
            return {

                modalConfirmDelete: {
                    id: null
                },

                tableNoteTemplates: {
                    actions: [
                        {
                            icon: require('@/assets/img/ico/ico_edit.svg'),
                            handler: (e, action, row) => {
                                this.buttonNoteTemplateEditClick(e, row.id)
                            },
                            tooltip: 'Editar modelo'
                        },
                        {
                            icon: require('@/assets/img/ico/ico_delete_color.svg'),
                            handler: (e, action, row) => {
                                this.buttonNoteTemplateDeleteClick(e, row.id)
                            },
                            tooltip: 'Excluir modelo'
                        }
                    ],
                    columns: [
                        {
                            alias: 'Nome',
                            id: 'name',
                            show: true
                        }
                    ],
                    data: null,
                    requesting: false,
                    rowClick: (e, row) => {
                        this.buttonNoteTemplateRowClick(e, row.id)
                    },
                    rowClickTooltip: 'Editar modelo'
                }

            }
        },//data
        computed: {

            hasItems() {

                let ret = false

                if(this.tableNoteTemplates.data) {
                    ret = this.tableNoteTemplates.data.length > 0
                }

                return ret

            },

            user() {
                return this.$store.getters['App/user']
            }

        },//computed
        methods: {

            deleteNote(id) {

                this.requesting = true

                this.axios
                    .delete(`/api/v1/note_templates/${id}`)
                    .then(
                        response => {

                            this.$eventbus.$emit(
                                'ComponentToast/add',
                                {
                                    autoHide: true,
                                    content: `Modelo excluído com sucesso`,
                                    icon: null,
                                    showCloseButton: false,
                                    type: 'success'
                                }
                            )

                            this.loadNoteTemplates()

                        }
                    )
                    .catch(
                        error => {

                            this.$eventbus.$emit(
                                'ComponentToast/add',
                                {
                                    autoHide: true,
                                    content: 'Erro ao excluir o modelo',
                                    icon: null,
                                    showCloseButton: false,
                                    type: 'error'
                                }
                            )

                        }
                    )
                    .finally(
                        () => {
                            this.requesting = false
                        }
                    )

            },

            loadNoteTemplates() {

                this.noteTemplates = null

                this.tableNoteTemplates.requesting = true

                this.$fetch.get(`/api/v1/note_templates`)
                    .then(
                        response => {

                            const data = response.data

                            this.tableNoteTemplates.data = data

                        }
                    )
                    .catch(
                        error => {

                            this.$eventbus.$emit(
                                'ComponentToast/add',
                                {
                                    autoHide: true,
                                    content: 'Erro ao carregar os modelos',
                                    icon: null,
                                    showCloseButton: false,
                                    type: 'error'
                                }
                            )

                        }
                    )
                    .finally(
                        () => {
                            this.tableNoteTemplates.requesting = false
                        }
                    )

            },

            //Events

            buttonNoteTemplateEditClick(e, id) {
                this.$router.push({ path: `/note-templates/edit/${id}` }, ()=>{}, ()=>{})
            },

            buttonNoteTemplateDeleteClick(e, id) {

                this.modalConfirmDelete.id = id

                this.$refs.modalConfirmDelete.show = true

            },

            buttonNoteTemplateRowClick(e, id) {
                this.$router.push({ path: `/note-templates/edit/${id}` }, ()=>{}, ()=>{})
            },

            componentFirstContentButtonClick(e) {

                this.$router.push({ name: 'NoteTemplatesAdd' }, () => {}, () => {})

            },

            modalConfirmDeleteButtonCancel(e) {

                this.$refs.modalConfirmDelete.show = false

                this.modalConfirmDelete.id = null

            },

            modalConfirmDeleteButtonDelete(e) {

                this.$refs.modalConfirmDelete.show = false

                this.deleteNote(this.modalConfirmDelete.id)

                this.modalConfirmDelete.id = null

            }

        },//methods
        watch: {},//watch
        created() {},//created
        mounted() {
            this.loadNoteTemplates()
        },//mounted
        updated() {},//updated
        destroyed() {},//destroyed
        beforeRouteEnter(to, from, next) {
            next(
                vm => {
                }
            )
        },//beforeRouteEnter
        beforeRouteUpdate(to, from, next) {
            next()
        },//beforeRouteUpdate
        beforeRouteLeave(to, from, next) {
            next()
        }//beforeRouteLeave

    }
</script>

<style scoped>
    #view-note-templates
    {

    }

    #view-note-templates.first-content
    {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: flex-start;
    }

    .component-view-tabs
    {
        margin-bottom: 1.5em;
        width: 100%;
    }

    .container-view-content
    {

    }

    .container-view-header
    {
        margin-bottom: 1em;
    }

    .button-add-note-template img
    {
        height: 1.25em;
        vertical-align: middle;
    }

    .button-add-note-template span
    {
        vertical-align: middle;
    }

    .container-group-list-and-right-col
    {
        display: flex;
    }

    .container-group-list-and-right-col .col-main
    {
        flex: 1;
    }

    .container-group-list-and-right-col .col-right
    {
        max-width: 15rem;
        min-width: 15rem;
    }

    .container-component-first-content-box
    {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;

        width: 100%;
    }

    .container-component-first-content-box >>> .container-icon img
    {
        opacity: 0.65;
    }

    @media (max-width: 767px) {

    }

    @media (min-width: 768px) and (max-width: 1199px) {

    }

    @media (max-width: 1199px) {

        .button-add-note-template
        {
            text-align: center;
        }

        .button-add-note-template img
        {
            margin-right: 0;
        }

        .container-group-list-and-right-col
        {
            display: block;
        }

    }

    @media (min-width: 1200px) {

        .container-group-list-and-right-col .col-main
        {
            margin-right: 1em;
        }

        .button-add-note-template
        {
            width: 15rem;
        }

    }
</style>
