<template>
    <div class="component-tabs">
        <button type="button" :class="[{ active: selected_ === index }]" :key="index" v-for="(item, index) in tabs" @click="buttonTabClick($event, index, item)">{{ item.label }}</button>
    </div>
</template>

<script>
    export default {
        name: 'ComponentTabs',
        props: [
            'selected',
            'tabs'
        ],
        mixins: [],
        components: {},//components
        data() {
            return {
                selected_: null
            }
        },//data
        computed: {},//computed
        methods: {

            buttonTabClick(e, index, item) {
                item.click(e)
            }

        },//methods
        watch: {

            selected(value, oldValue) {
                this.selected_ = value
            }

        },//watch
        created() {},//created
        mounted() {

            if(this.selected !== undefined && this.selected !== null) {
                this.selected_ = this.selected
            }

        },//mounted
        updated() {},//updated
        destroyed() {},//destroyed
    }
</script>

<style scoped>
    .component-tabs
    {
        border-bottom: 1px solid #999;
    }

    button
    {
        background-color: transparent;
        border: none;
        border-bottom: 3px solid transparent;
        font-size: 1.1em;
        font-weight: 300;
        margin-right: 1.5em;
        opacity: 0.5;
        padding: 0.5em 0;
    }

    .active
    {
        border-bottom-color: #29B1CC;
        opacity: 1;
    }

    @media (max-width: 767px) {

    }

    @media (min-width: 768px) {

    }

    @media (min-width: 992px) {

    }

    @media (max-width: 1199px) {

    }

    @media (min-width: 1200px) {

    }
</style>
