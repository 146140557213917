<template>
    <div class="component-note-item">
        <div class="container-header">
            <div class="col-left">
                <span class="creation-info" :title="'Anotação criada' + (item.creator_name ? ' por ' + item.creator_name : '') + ' em ' + $moment(item.created_at).format('DD/MM/YYYY HH:mm')">
                    <b>{{ item.creator_name ? item.creator_name : 'Criada em' }}</b><span>{{ $moment(item.created_at).format('DD/MM/YYYY HH:mm') }}</span>
                </span>
                <span class="lock-info" v-if="isLocked">
                    <img src="~@/assets/img/ico/ico_lock.svg" alt=""><span>{{ $mq.match(/lg/) ? 'Anotação bloqueada ' : 'Bloqueada ' }}{{ $moment(item.updated_at).format('DD/MM/YYYY HH:mm') }}</span>
                </span>
            </div>
            <div class="col-right">

                <button type="button" class="button-print" :disabled="isEditing" title="Imprimir esta anotação" @click="buttonItemNotePrintClick($event, item)">
                    <img src="~@/assets/img/ico/ico_print.svg" alt="">
                    <span v-if="$mq.match(/lg/)">Imprimir</span>
                </button>

                <button type="button" class="button-duplicate" :disabled="isEditing" title="Criar cópia a partir desta anotação" @click="buttonItemNoteDuplicateClick($event, item.body)">
                    <img src="~@/assets/img/ico/ico_duplicate.svg" alt="">
                    <span v-if="$mq.match(/lg/)">Criar cópia</span>
                </button>

                <button type="button" class="button-edit" :disabled="isEditing || isLocked" ref="buttonEdit" :title="isLocked ? 'Esta anotação foi bloqueada e não pode ser editada' : 'Editar esta anotação'" @click="buttonItemNoteEditClick($event, item.id, item.body)">
                    <img src="~@/assets/img/ico/ico_edit.svg" alt="">
                    <span v-if="$mq.match(/lg/)">Editar</span>
                </button>

                <button type="button" class="button-delete" :disabled="isEditing || isLocked" :title="isLocked ? 'Esta anotação foi bloqueada e não pode ser excluída' : 'Excluir esta anotação'" @click="buttonItemNoteDeleteClick($event, item.id)">
                    <img src="~@/assets/img/ico/ico_trash.svg" alt="">
                    <span v-if="$mq.match(/lg/)">Excluir</span>
                </button>

            </div>
        </div>

        <div class="container-note-add-update" v-if="isEditing">
            <ComponentNoteAddUpdate
                :body="item.body"
                :customerId="customerId"
                :noteId="editing.noteId"
                @cancel="componentNoteAddUpdateCancel"
                @noteSaved="componentNoteAddUpdateNoteSaved"/>
        </div>

        <div class="container-content" @click="containerContentClick" v-html="item.body" v-else></div>

        <div class="container-footer" v-if="!isEditing">
            <div class="col-left">

            </div>
            <div class="col-right">
                <button type="button" title="Bloquear esta anotação" @click="buttonLockNoteClick($event, item.id)" v-if="!isLocked">
                    <img src="~@/assets/img/ico/ico_lock.svg" alt="">
                </button>
            </div>
        </div>

        <ComponentModal class="component-modal-confirm-delete" ref="modalConfirmDelete">
            <template v-slot:title>
                <span>Excluir anotação</span>
            </template>
            <template v-slot:content>
                <p>Tem certeza de que deseja excluir esta anotação?</p>
            </template>
            <template v-slot:footer>
                <button type="button" class="btn-default btn-terciary" @click="modalConfirmDeleteButtonCancel">Cancelar</button>
                <button type="button" class="btn-default btn-primary-red" @click="modalConfirmDeleteButtonDelete">Excluir</button>
            </template>
        </ComponentModal>

        <ComponentModal class="component-modal-confirm-lock" ref="modalConfirmLock">
            <template v-slot:title>
                <span>Bloquear anotação</span>
            </template>
            <template v-slot:content>
                <p>Tem certeza de que deseja bloquear a edição e exclusão desta anotação?</p>
                <p>Ao confirmar esta ação, a anotação não poderá mais ser editada ou excluída. Poderá apenas ser visualizada e exportada.</p>
            </template>
            <template v-slot:footer>
                <button type="button" class="btn-default btn-terciary" @click="modalConfirmLockButtonCancel">Cancelar</button>
                <button type="button" class="btn-default btn-primary-red" @click="modalConfirmLockButtonLock">Bloquear</button>
            </template>
        </ComponentModal>

    </div>
</template>

<script>
    import ComponentModal from "@/components/ui/ComponentModal"
    import ComponentNoteAddUpdate from "@/components/Note/ComponentNoteAddUpdate"

    export default {
        name: 'ComponentNoteItem',
        props: [
            'company',
            'customerId',
            'item',
            'notesEditing'
        ],
        mixins: [],
        components: {
            ComponentModal,
            ComponentNoteAddUpdate
        },//components
        data() {
            return {

                editing: {
                    noteId: null
                },

                modalConfirmDelete: {
                    id: null
                },

                modalConfirmLock: {
                    id: null
                },

            }
        },//data
        computed: {

            isEditing() {
                return this.editing.noteId
            },

            isLocked() {
                return this.item.locked
            }

        },//computed
        methods: {

            deleteNote(id) {

                const customerId = this.customerId

                this.requestingCustomerNotes = true

                this.axios
                    .delete(`/api/v1/customers/${customerId}/customer_notes/${id}`)
                    .then(
                        response => {

                            this.$eventbus.$emit(
                                'ComponentToast/add',
                                {
                                    autoHide: true,
                                    content: `Anotação excluída com sucesso`,
                                    icon: null,
                                    showCloseButton: false,
                                    type: 'success'
                                }
                            )

                            this.$emit('noteDeleted')

                        }
                    )
                    .catch(
                        error => {

                            this.$eventbus.$emit(
                                'ComponentToast/add',
                                {
                                    autoHide: true,
                                    content: 'Erro ao excluir a anotação',
                                    icon: null,
                                    showCloseButton: false,
                                    type: 'error'
                                }
                            )

                        }
                    )
                    .finally(
                        () => {
                            this.requestingCustomerNotes = false
                        }
                    )

            },

            lockNote(id) {

                const customerId = this.customerId
                const noteId = id

                this.requestingCustomerNotes = true

                this.axios
                    .patch(
                        `/api/v1/customers/${customerId}/customer_notes/${noteId}`,
                        {
                            'customer_note[locked]': true
                        }
                    )
                    .then(
                        response => {

                            this.$eventbus.$emit(
                                'ComponentToast/add',
                                {
                                    autoHide: true,
                                    content: `Anotação bloqueada com sucesso`,
                                    icon: null,
                                    showCloseButton: false,
                                    type: 'success'
                                }
                            )

                            this.$emit('noteLocked')

                        }
                    )
                    .catch(
                        error => {

                            this.$eventbus.$emit(
                                'ComponentToast/add',
                                {
                                    autoHide: true,
                                    content: 'Erro ao bloquear a anotação',
                                    icon: null,
                                    showCloseButton: false,
                                    type: 'error'
                                }
                            )

                        }
                    )
                    .finally(
                        () => {
                            this.requestingCustomerNotes = false
                        }
                    )

            },

            //Events

            buttonItemNotePrintClick(e, item) {

                this.$eventbus.$emit(
                    'ComponentPrint/open',
                    {
                        baseUrl: `${process.env.VUE_APP_API_BASE_URL}/customer_notes/{noteHash}/report.pdf?page_size={pageSize}`,
                        noteHash: item.hashid,
                        id: item.id
                    }
                )

            },

            buttonItemNoteDuplicateClick(e, body) {
                this.$emit('duplicateNote', body)
            },

            buttonItemNoteEditClick(e, id, body) {
                this.editing.noteId = id
            },

            buttonItemNoteDeleteClick(e, id) {

                this.modalConfirmDelete.id = id

                this.$refs.modalConfirmDelete.show = true

            },

            buttonLockNoteClick(e, id) {

                this.modalConfirmLock.id = id

                this.$refs.modalConfirmLock.show = true

            },

            componentNoteAddUpdateCancel() {
                this.editing.noteId = null
            },

            componentNoteAddUpdateNoteSaved() {

                this.editing.noteId = null

                this.$emit('noteSaved')

            },

            containerContentClick(e) {

                if(!this.$refs.buttonEdit.classList.contains('highlight') && !this.isLocked) {

                    this.$refs.buttonEdit.classList.add('highlight')

                    setTimeout(
                        () => {
                            this.$refs.buttonEdit.classList.remove('highlight')
                        },
                        2000
                    )

                }

            },

            modalConfirmDeleteButtonCancel(e) {

                this.$refs.modalConfirmDelete.show = false

                this.modalConfirmDelete.id = null

            },

            modalConfirmDeleteButtonDelete(e) {

                this.$refs.modalConfirmDelete.show = false

                this.deleteNote(this.modalConfirmDelete.id)

                this.modalConfirmDelete.id = null

            },

            modalConfirmLockButtonCancel(e) {

                this.$refs.modalConfirmLock.show = false

                this.modalConfirmLock.id = null

            },

            modalConfirmLockButtonLock(e) {

                this.$refs.modalConfirmLock.show = false

                this.lockNote(this.modalConfirmLock.id)

                this.modalConfirmLock.id = null

            },

        },//methods
        watch: {

            isEditing(value, oldValue) {

                if(value) {
                    this.notesEditing.push(value)
                } else {
                    this.notesEditing.splice(this.notesEditing.indexOf(oldValue), 1)
                }

            }

        },//watch
        created() {},//created
        mounted() {},//mounted
        updated() {},//updated
        destroyed() {},//destroyed
    }
</script>

<style lang="scss" scoped>
    /* TinyMCE5 styles */
    .container-content {

        font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
        line-height: 1.4;
        margin: 1rem;

        ::v-deep table {
            border-collapse: collapse;
        }
        ::v-deep table th,
        ::v-deep table td {
            border: 1px solid #ccc;
            padding: 0.4rem;
        }
        ::v-deep figure {
            display: table;
            margin: 1rem auto;
        }
        ::v-deep figure figcaption {
            color: #999;
            display: block;
            margin-top: 0.25rem;
            text-align: center;
        }
        ::v-deep hr {
            border-color: #ccc;
            border-style: solid;
            border-width: 1px 0 0 0;
        }
        ::v-deep code {
            background-color: #e8e8e8;
            border-radius: 3px;
            padding: 0.1rem 0.2rem;
        }
        ::v-deep .mce-content-body:not([dir=rtl]) blockquote {
            border-left: 2px solid #ccc;
            margin-left: 1.5rem;
            padding-left: 1rem;
        }
        ::v-deep .mce-content-body[dir=rtl] blockquote {
            border-right: 2px solid #ccc;
            margin-right: 1.5rem;
            padding-right: 1rem;
        }

    }
</style>

<style scoped>
    .component-note-item
    {
        border: 1px solid #ccc;
        box-sizing: border-box;
        margin-bottom: 1.25rem;
    }

    .component-note-item:last-child
    {
        margin-bottom: 0;
    }
    
    .container-header
    {
        display: flex;
        justify-content: space-between;
        
        background-color: #f0f0f0;
        border-bottom: 1px solid #ccc;
        padding: 1rem;
    }

    .container-header > div
    {
        display: flex;
        align-items: center;
    }

    .container-header .col-left
    {
        font-size: 0.85em;
    }

    .container-header .col-left b
    {
        display: inline-block;
        margin-right: 0.5em;
        max-width: 290px;
        overflow: hidden;
        text-overflow: ellipsis;
        vertical-align: middle;
        white-space: nowrap;
    }

    .container-header .col-left .creation-info
    {

    }

    .container-header .col-left .creation-info span
    {
        vertical-align: middle;
    }

    .container-header .col-left .lock-info
    {
        margin-left: 1em;
    }

    .container-header .col-left .lock-info img
    {
        height: 1.35em;
        margin-right: 0.5em;
        vertical-align: middle;
    }

    .container-header .col-left .lock-info span
    {
        letter-spacing: -0.02em;
        opacity: 0.55;
        vertical-align: middle;
    }

    .container-header button
    {
        display: inline-flex;
        align-items: center;

        background-color: transparent;
        border: none;
        border-radius: 4px;
        font-size: 0.78em;
        font-weight: 300;
        margin: 0;
        padding: 0.5em 0.75em;
        transition: 0.15s all;
    }

    .container-header button:hover
    {
        background-color: #fff;
    }

    .container-header button img
    {
        height: 1.25em;
        margin-right: 0.5em;
    }

    .container-header .button-print img
    {
        opacity: 0.5;
    }

    .container-header .button-edit.highlight
    {
        color: #fff;

        animation-duration: 0.25s;
        animation-direction: alternate;
        animation-iteration-count: infinite;
        animation-name: buttonEditHighlight;
    }

    @keyframes buttonEditHighlight {
        from {
            background-color: transparent;
        }
        to {
            background-color: #29B1CC;
        }
    }

    .container-header .button-edit img
    {
        opacity: 0.45;
    }

    .container-footer
    {
        display: flex;

        padding: 1rem;
    }

    .container-footer button
    {
        display: inline-flex;
        align-items: center;

        background-color: transparent;
        border: none;
        border-radius: 4px;
        font-size: 0.78em;
        font-weight: 300;
        margin: 0;
        padding: 0.25rem;
        transition: 0.15s all;
    }

    .container-footer button:hover
    {
        background-color: #fff;
    }

    .container-footer button img
    {
        height: 1.5em;
    }

    .container-footer > div
    {
        flex: 1;
        display: flex;
        align-items: center;
    }

    .container-footer .col-left
    {

    }

    .container-footer .col-right
    {
        justify-content: flex-end;
    }

    .container-note-add-update
    {

    }

    .container-note-add-update >>> .container-buttons-top
    {
        border-bottom: 1px solid #ccc;
    }

    @media (max-width: 767px) {

    }

    @media (min-width: 768px) {

    }

    @media (max-width: 991px) {

        .container-header .col-left
        {
            display: block;
        }

        .container-header .col-left > span
        {
            display: block;
        }

        .container-header .col-left .lock-info
        {
            margin-left: 0;
            margin-top: 0.5em;
        }

        .container-content
        {
            overflow: auto;
            padding: 0.25rem;
        }

    }

    @media (min-width: 992px) {

        .container-content
        {
            padding: 0.25rem;
        }

    }

    @media (max-width: 1199px) {

        .container-header
        {
            display: block;
        }

        .container-header button img
        {
            margin-right: 0;
        }

        .container-header .col-right
        {
            margin-top: 0.75em;
        }

        .container-content
        {

        }

    }

    @media (min-width: 1200px) {

        .container-header
        {

        }

        .container-header button img
        {

        }

        .container-header .col-right
        {
            justify-content: flex-end;
        }

        .component-modal >>> .container-dialog
        {
            max-width: 450px;
        }

    }
</style>
